import React, { useState } from 'react';

import IBDIcon from '@totem/styles/Icon';
import { RelatedTicket } from '@totem/types/ticketing';
import { getToken } from '@totem/utilities/accountUtilities';
import { TICKETS_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

type Props = {
  ticketId: string;
  relatedTicket: RelatedTicket;
  refetch: () => void;
};

const RelatedTicketDeleteIcon = ({
  ticketId,
  relatedTicket,
  refetch,
}: Props) => {
  const [isSending, setIsSending] = useState<boolean>(false);

  const sendDeleteRelationship = (
    pTicketId: string,
    relationship: string,
    relatedId: string,
  ) => {
    if (isSending) {
      return;
    }
    setIsSending(true);

    fetch(`${TICKETS_ENDPOINT}/relationships/${pTicketId}/${relatedId}`, {
      method: 'DELETE',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    }).then((res) => {
      CheckResponseShowError(res);
      setIsSending(false);
      refetch();
    });
  };

  const handleClick = () => {
    sendDeleteRelationship(
      ticketId,
      relatedTicket.relationship,
      relatedTicket.ticket.ticket.id,
    );
  };

  return <IBDIcon name="Delete" onClick={() => handleClick()} />;
};

export default RelatedTicketDeleteIcon;
