import ContentLayout from '@totem/components/ContentLayout';
import BasePane from '@totem/components/BasePane';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Flex, Row, Switch, Tabs, TabsProps } from 'antd';
import TabTitle from '@totem/components/TabTitle';
import DeviceContainer from '@totem/components/devices/devicesContainer/DeviceContainer';
import DeviceFilters from '@totem/components/devices/devicesContainer/DeviceFilters';
import RecordCountBadge from '@totem/components/RecordCountBadge';
import DeviceExport from '@totem/components/devices/devicesContainer/DeviceExport';
import UserProfileContext from '@totem/components/UserProfileContext';
import { getUserRole } from '@totem/utilities/userUtilities';
import SoftwareContainer from '@totem/components/software/software_report/softwareContainer';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import SoftwareFilters from '@totem/components/software/software_report/softwareFilters';
import CountBadge from '@totem/components/software/software_report/countBadge';
import SoftwareTable from '@totem/components/software/software_report/softwareTable';
import TagsSection from '@totem/components/software/software_report/byTag/tagsSection';
import DeviceEmbeddedContainer from '@totem/components/devices/devicesContainer/DeviceEmbeddedContainer';
import { Vendor } from '@totem/types/vendor';
import DeviceContainerTable from '@totem/components/devices/devicesContainer/DeviceContainerTable';

const styles = {
  totals: {
    fontSize: '16px',
    marginRight: '20px',
  },
  tabContainer: {
    paddingRight: '2rem',
    paddingLeft: '2rem',
  },
  inset: {
    marginLeft: '20px',
    marginRight: '20px',
  },
};

const VendorDetailsPage = () => {
  const { id } = useParams();
  const { userProfile } = useContext(UserProfileContext);
  const [vendor, setVendor] = useState<string>(id);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [grouped, setGrouped] = useState<boolean>(false);

  const getTabItems = () => {
    const tabItems: TabsProps['items'] = [
      {
        key: 'devices',
        label: <TabTitle>Devices</TabTitle>,
        children: (
          <DeviceEmbeddedContainer onRecordTotalChanged={setTotalRecords} staticFilters={{ vendor: [vendor] }} >
            <DeviceContainerTable />
          </DeviceEmbeddedContainer>
        ),
      },
      {
        key: 'software',
        label: <TabTitle>Software</TabTitle>,
        children: (
          <SoftwareContainer
            endPoint={`${DEVICES_ENDPOINT}/software/catalog`}
            organizationId={userProfile.organization.id}
            defaultPublisher={vendor}
          >
            <div style={styles.inset}>
              <Row>
                <Col span={12}>
                </Col>
                <Col span={12}>
                  <Flex justify={'flex-end'} align={'center'}>
                    Group by Tag:
                    <Switch
                      style={{ marginRight: '16px', marginLeft: '8px' }}
                      onChange={setGrouped}
                      value={grouped}
                    />
                    <CountBadge />
                  </Flex>
                </Col>
              </Row>
              <br />
              {!grouped && <SoftwareTable />}
              {grouped && <TagsSection />}
            </div>
          </SoftwareContainer>
        ),
      },
    ];
    return tabItems;
  };

  return (
    <ContentLayout pageTitle={'Vendor: ' + vendor}>
      <BasePane>
        <PrimaryContentPane>
          <Tabs
            defaultActiveKey="devices"
            style={styles.tabContainer}
            items={getTabItems()}
          />
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default VendorDetailsPage;
