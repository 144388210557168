import React, { ReactNode, useEffect, useState } from 'react';

import CvesContext from '@totem/components/software/cves/cvesContext';
import { CVERecord } from '@totem/components/software/cves/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  ids: string[];
  version?: string;
  children?: ReactNode;
};

const CvesContainer = ({ ids, version, children }: Props) => {
  const [prevIds, setPrevIds] = useState<string[]>(ids);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<CVERecord[]>(null);
  const [rawData, setRawData] = useState<CVERecord[]>(null);
  const [selectedItem, setSelectedItem] = useState<CVERecord>(null);
  const [action, setAction] = useState<string>('');

  useEffect(() => {
    if (refreshData || ids.length != prevIds.length) {
      setRefreshData(false);
      setPrevIds(ids);
      setIsLoading(true);

      const uri = `${DEVICES_ENDPOINT}/software/cves`;

      fetch(uri, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(ids.filter((chk) => chk !== '')),
      })
        .then((res) => res.json())
        .then((result: CVERecord[]) => {
          setRawData(result);
          if (isNotNull(version)) {
            setData(
              result.filter(
                (chk) =>
                  chk.metaData.affectedVersions.findIndex(
                    (chk2) => chk2 === version,
                  ) >= 0,
              ),
            );
          } else {
            setData(result);
          }
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [ids, refreshData]);

  const handleAction = (newAction: string, cve: CVERecord) => {
    switch (newAction) {
      case 'refresh':
        setRefreshData(true);
        setAction('');
        setSelectedItem(null);
        break;
      default:
        setAction(newAction);
        setSelectedItem(cve);
    }
  };

  return (
    <CvesContext.Provider
      value={{
        loading: isLoading,
        ids,
        data,
        selectedItem,
        action,
        onAction: handleAction,
      }}
    >
      {children}
    </CvesContext.Provider>
  );
};

export default CvesContainer;
