import React, { useContext, useState } from 'react';
import EventContext from '@totem/components/events/eventsContainer/eventContainerContext';

import { Button } from 'antd';
import { EVENTS_ENDPOINT } from '@totem/utilities/endpoints';
import api from '@totem/utilities/api';
import fileUtilities from '@totem/utilities/fileUtilities';
import { Params } from '@totem/types/common';
import { addEventFilters, buildEventFilters } from '@totem/components/events/eventsContainer/utilities';
import EventFilterContext from '@totem/components/events/eventFilters/EventFilterContext';
import { EventReportParameters } from '@totem/types/event';
import dayjs from 'dayjs';

type Props = {
  eventEndPoint?: string;
};

const EventExportButton = ({eventEndPoint}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { reportInput } = useContext(EventContext);
  const { input, staticFilters } = useContext(EventFilterContext);

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: reportInput.page,
        pageSize: reportInput.pageSize,
      },
      sort: {
        field: reportInput.sortField,
        direction: +reportInput.sortDirection,
      },
      filters: buildEventFilters(input),
    };

    params.filters = addEventFilters(params.filters, staticFilters);

    return params;
  };

  const exportData = async () => {
    setLoading(true);

    const params = buildParameters();
    const payload: EventReportParameters = {
      startTime: dayjs(input.startTime).toISOString(),
      endTime: dayjs(input.endTime).toISOString(),
      params,
    };

    const eventUrl =
      typeof eventEndPoint !== 'undefined' &&
      eventEndPoint !== null
        ? eventEndPoint
        : EVENTS_ENDPOINT;

    const { data } = await api.post(`${eventUrl}/export`, payload);
    fileUtilities.downloadFile('events', data);
    setLoading(false);
  };

  return <Button onClick={exportData}>Export</Button>
};

export default EventExportButton;
