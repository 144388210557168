import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import * as R from 'ramda';

const { TextArea } = Input;
const FormItem = Form.Item;

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { Note } from '@totem/types/note';
import { Note as NewNote } from '@totem/components/media/notes/types';

interface Errors {
  title?: string;
  body?: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (title: string, body: string) => void;
  loading: boolean;
  note?: Note | NewNote;
}

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '2rem',
  },
};

const AddEditNoteModal = ({
  open,
  onClose,
  onSubmit,
  loading,
  note,
}: Props) => {
  const [title, setTitle] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [errors, setErrors] = useState<Errors>({});

  useEffect(() => {
    if (!open) {
      setTitle('');
      setBody('');
    } else {
      setTitle(note?.title || '');
      setBody(note?.body || '');
    }
  }, [note, open]);

  const isValid = (): boolean => {
    const errs: Errors = {};

    if (!title) {
      errors.title = 'Please enter a title';
    }

    if (!body) {
      errors.body = 'Please enter note details';
    }

    setErrors(errs);
    return R.isEmpty(errs);
  };

  const handleSubmit = () => {
    if (isValid()) {
      onSubmit(title, body);
    }
  };

  return (
    <Modal
      open={open}
      title={<ModalTitle>{note ? 'Edit Note' : 'Add a Note'}</ModalTitle>}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} type="primary" loading={loading}>
            Save
          </Button>
        </ModalFooter>
      }
      onCancel={onClose}
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="Title"
          colon={false}
          validateStatus={R.isNil(errors.title) ? 'success' : 'error'}
          help={R.isNil(errors.title) ? null : errors.title}
          style={styles.formItem}
        >
          <Input
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
        </FormItem>
        <FormItem
          label="Note Details"
          colon={false}
          validateStatus={R.isNil(errors.body) ? 'success' : 'error'}
          help={R.isNil(errors.body) ? null : errors.body}
          style={styles.formItem}
        >
          <TextArea
            value={body}
            onChange={(event) => setBody(event.target.value)}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default AddEditNoteModal;
