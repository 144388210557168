import React, { ReactNode, useContext, useEffect, useState } from 'react';
import SoftwareContext from '@totem/components/software/software_report/softwareContext';
import { Switch } from 'antd';

const SwitchMonitored = () => {
  const { monitoredOnly, setMonitoredOnly } = useContext(SoftwareContext);

  return (
    <>
      Monitored:
      <Switch
        style={{ marginRight: '16px', marginLeft: '8px' }}
        onChange={setMonitoredOnly}
        value={monitoredOnly}
      />
    </>
  );
};

export default SwitchMonitored;
