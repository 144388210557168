import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import Table from '@totem/components/common/table/Table';
import TableFilterDropDown from '@totem/components/TableFilterDropDown';
import TicketingContext from '@totem/components/ticketing/listContainer/ticketingContainerContext';
import UserProfileContext from '@totem/components/UserProfileContext';
import { TicketQueryResult } from '@totem/types/ticketing';
import { formatDate, isNotNull, isNull } from '@totem/utilities/common';
import { stringifyArray } from '@totem/utilities/tableFilterUtilities';
import { getFilterOptions } from '@totem/utilities/ticketing';
import {
  getUserRole,
  isMemberOf,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

import '../ticketing.css';

const TicketsListContainerTable = () => {
  const { userProfile } = useContext(UserProfileContext);
  const [typeFilterVisible, setTypeFilterVisible] = useState<boolean>(false);
  const {
    input,
    setInput,
    ticketData,
    filterOptions,
    loading,
    totalRecords,
    onTicketSelected,
    selectedTickets,
    selectTicket,
    deselectTicket,
  } = useContext(TicketingContext);

  const isTicketAdmin =
    isMemberOf(
      userProfile,
      'ticket_administrator',
      securityGroupCheckConstraintEmpty,
    ) || getUserRole(userProfile) > 2;

  const callTicketAction = (ticket: TicketQueryResult) => {
    if (typeof onTicketSelected === 'function') {
      onTicketSelected(ticket.ticket.id, ticket);
    }
  };

  const getTicketClickAction = (ticket: TicketQueryResult) => {
    if (typeof onTicketSelected === 'function') {
      return (
        <span
          onClick={() => {
            callTicketAction(ticket);
          }}
        >
          {ticket.ticket.number}
        </span>
      );
    }

    return (
      <Link to={`/dashboard/ticket/${ticket.ticket.id}`}>
        {ticket.ticket.number}
      </Link>
    );
  };

  const getControlSystemAlternate = (checkTicket: TicketQueryResult) => {
    if (
      typeof checkTicket !== 'undefined' &&
      checkTicket !== null &&
      checkTicket.ticket.attributes !== null
    ) {
      for (let idx = 0; idx < checkTicket.ticket.attributes.length; idx++) {
        const attribute = checkTicket.ticket.attributes[idx];
        if (attribute.name === 'ControlSystemType') {
          return attribute.value;
        }
      }
    }
    return '';
  };

  const getControlSystemDisplay = (checkTicket: TicketQueryResult) => {
    if (
      typeof checkTicket !== 'undefined' &&
      checkTicket !== null &&
      checkTicket.controlSystem !== null &&
      checkTicket.controlSystem.id !== null &&
      checkTicket.controlSystem.id !== EMPTY_ID
    ) {
      return <span>{checkTicket.controlSystem.name}</span>;
    } else if (
      checkTicket.controlSystem !== null &&
      checkTicket.controlSystem.name !== ''
    ) {
      return <span>{checkTicket.controlSystem.name}</span>;
    }

    return <span>{getControlSystemAlternate(checkTicket)}</span>;
  };

  const adminColumns: ColumnProps<TicketQueryResult>[] = [
    {
      title: '',
      dataIndex: 'ticket.id',
      key: 'id',
      render: (_, ticket: TicketQueryResult) => {
        const isChecked =
          selectedTickets.findIndex((chk) => chk.id === ticket.ticket.id) > -1;

        return (
          <Checkbox
            checked={isChecked}
            onClick={() => {
              if (isChecked) {
                deselectTicket(ticket.ticket.id);
              } else {
                selectTicket(ticket.ticket.id, ticket.ticket.number);
              }
            }}
          />
        );
      },
      filterMultiple: true,
      filteredValue: stringifyArray(input.buildingId),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.building
          : null,
      ),
    },
  ];

  const columns: ColumnProps<TicketQueryResult>[] = [
    ...(isTicketAdmin ? adminColumns : []),
    {
      title: 'Number',
      dataIndex: 'ticket.number',
      key: 'type',
      render: (_, ticket: TicketQueryResult) => getTicketClickAction(ticket),
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.number === compB.ticket.number
          ? 0
          : compA.ticket.number > compB.ticket.number
            ? 1
            : -1,
      filtered: isNotNull(input.type) ? input.type.length > 0 : false,
      onFilterDropdownOpenChange: () =>
        setTypeFilterVisible(!typeFilterVisible),
      filterDropdownOpen: typeFilterVisible,
      filterDropdown: (
        <TableFilterDropDown
          value={stringifyArray(input.type)}
          filterDropdownOpen={typeFilterVisible}
          padding={15}
          onCloseFilter={() => setTypeFilterVisible(false)}
          onChange={(newOptions) => setInput({ type: newOptions })}
          options={getFilterOptions(
            isNotNull(filterOptions) ? filterOptions.type : null,
          )}
        />
      ),
    },
    {
      title: 'Category',
      dataIndex: 'ticket.category',
      key: 'category',
      render: (_, ticket: TicketQueryResult) => ticket.ticket.category,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.category === compB.ticket.category
          ? 0
          : compA.ticket.category > compB.ticket.category
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.category),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.categories
          : null,
      ),
    },
    {
      title: 'Building',
      dataIndex: 'building.name',
      key: 'buildingId',
      render: (_, ticket: TicketQueryResult) => ticket.building.name,
      filterMultiple: true,
      filteredValue: stringifyArray(input.buildingId),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.building
          : null,
      ),
    },
    {
      title: 'Control System',
      dataIndex: 'controlSystem.name',
      key: 'controlSystemId',
      render: (_, ticket: TicketQueryResult) => getControlSystemDisplay(ticket),
    },
    {
      title: 'Summary',
      dataIndex: 'ticket.summary',
      key: 'summary',
      render: (_, ticket: TicketQueryResult) => ticket.ticket.summary,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.summary === compB.ticket.summary
          ? 0
          : compA.ticket.summary > compB.ticket.summary
            ? 1
            : -1,
    },
    {
      title: 'Priority',
      dataIndex: 'ticket.priority',
      key: 'priority',
      render: (_, ticket: TicketQueryResult) => ticket.ticket.priority,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.priority === compB.ticket.priority
          ? 0
          : compA.ticket.priority > compB.ticket.priority
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.priority),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.priority
          : null,
      ),
    },
    {
      title: 'Status',
      dataIndex: 'ticket.status',
      key: 'status',
      render: (_, ticket: TicketQueryResult) => ticket.ticket.status,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.status === compB.ticket.status
          ? 0
          : compA.ticket.status > compB.ticket.status
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.status),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.status
          : null,
      ),
    },
    {
      title: 'Related',
      dataIndex: 'ticket.relatedTickets',
      key: 'summary',
      render: (_, ticket: TicketQueryResult) =>
        isNull(ticket.relatedTickets) ? (
          <span />
        ) : (
          ticket.relatedTickets.map((rel) => (
            <div key={rel.ticket.ticket.id}>
              <span title={rel.relationship}>
                {rel.relationship.toUpperCase().charAt(0)}
              </span>
              :{' '}
              <Link to={`/dashboard/ticket/${rel.ticket.ticket.id}`}>
                {rel.ticket.ticket.number}
              </Link>
            </div>
          ))
        ),
    },
    {
      title: 'Created',
      dataIndex: 'ticket.createdAt',
      key: 'createdAt',
      render: (_, ticket: TicketQueryResult) =>
        formatDate(ticket.ticket.createdAt),
      sorter: (compA, compB) =>
        compA.ticket.createdAt === compB.ticket.createdAt
          ? 0
          : compA.ticket.createdAt > compB.ticket.createdAt
            ? 1
            : -1,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Updated',
      dataIndex: 'ticket.updatedAt',
      key: 'updatedAt',
      render: (_, ticket: TicketQueryResult) =>
        formatDate(ticket.ticket.updatedAt),
      sorter: (compA, compB) =>
        compA.ticket.updatedAt === compB.ticket.updatedAt
          ? 0
          : compA.ticket.updatedAt > compB.ticket.updatedAt
            ? 1
            : -1,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
    },
  ];

  const getTickets = () => {
    if (ticketData !== null) {
      if (ticketData.tickets !== null) {
        return ticketData.tickets;
      }
    }
    return [];
  };

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<TicketQueryResult>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'createdAt';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getTickets()}
      loading={loading}
      onChange={handleTableChange}
      pagination={{
        current: input.page,
        pageSize: input.pageSize,
        total: totalRecords,
        showSizeChanger: true,
      }}
      rowKey={(record) => record.ticket.id}
    />
  );
};

export default TicketsListContainerTable;
