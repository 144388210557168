import React, { useCallback, useContext, useEffect, useState } from 'react';
import { QuestionOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, notification, Tooltip } from 'antd';
import * as R from 'ramda';

import fonts from '@totem/styles/fonts';
import { getAccountPayloadSync, getToken } from '@totem/utilities/accountUtilities';
import { validateEmail } from '@totem/utilities/validation';
import { MIN_DATE_TIME_STRING, Ticket } from '@totem/types/ticketing';
import { TICKET_ENDPOINT } from '@totem/utilities/endpoints';
import { emptyId } from '@totem/utilities/userUtilities';
import UserProfileContext from '@totem/components/UserProfileContext';

const FormItem = Form.Item;

interface Errors {
  email?: string;
  name?: string;
  message?: string;
}

const styles = {
  button: {
    marginRight: '1rem',
  },
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
  title: {
    fontSize: fonts.fontXl,
    fontWeight: 700,
  },
};

const getUserEmail = () => {
  const payload = getAccountPayloadSync();
  // TODO: Remove @ts-ignore : Token
  // @ts-ignore
  return payload.email;
};

const getUserName = () => {
  const payload = getAccountPayloadSync();
  // TODO: Remove @ts-ignore : Token
  // @ts-ignore
  return payload.name;
};

const HelpDeskModal = () => {
  const { userProfile } = useContext(UserProfileContext);
  const [open, setOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(getUserEmail());
  const [phone, setPhone] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [errors, setErrors] = useState<Errors>({});
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (!open) {
      setEmail(getUserEmail());
      setPhone('');
      setMessage('');
      setName(getUserName());
      setErrors({});
    }
  }, [open]);

  const sendTicket = useCallback(
    async (request: Ticket) => {
      if (isSending) {
        return;
      }
      setIsSending(true);

      fetch(`${TICKET_ENDPOINT}`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request),
      })
        .then((response) => {
          if (response.status === 200) {
            setOpen(false);

            notification.success({
              message: 'Request Submitted',
              description: 'Successfully submitted help desk request.',
            });
          } else {
            notification.error({
              message: 'Problem Submitting Request',
              description: 'There was a problem submitting this request.',
            });
          }
        })
        .then(() => {
          setIsSending(false);
        });
    },
    [isSending],
  );

  const isValid = () => {
    const formErrors: Errors = {};

    if (R.isEmpty(email)) {
      formErrors.email = 'Please enter an email';
    } else if (!validateEmail(email)) {
      formErrors.email = 'Please enter a valid email';
    }

    if (R.isEmpty(name)) {
      formErrors.name = 'Please enter a name';
    }

    if (R.isEmpty(message)) {
      formErrors.message = 'Please enter a message';
    }

    setErrors(formErrors);

    return R.isEmpty(formErrors);
  };

  const handleSubmit = async () => {
    if (isValid()) {

      const ticket: Ticket = {
        activity: [],
        assignments: [],
        buildingId: emptyId,
        closedAt: MIN_DATE_TIME_STRING,
        controlSystemId: emptyId,
        createdAt: MIN_DATE_TIME_STRING,
        createdById: emptyId,
        createdByName: '',
        createdByType: '',
        description: message,
        deviceId: emptyId,
        keys: [],
        partiesInvolved: [
          {
            id: '',
            type: 'Requester',
            status: 'ACTIVE',
            name: name,
            email: userProfile.email,
            phone: phone,
            involvementStart: MIN_DATE_TIME_STRING,
            involvementEnd: MIN_DATE_TIME_STRING,
            createdByType: 'USER',
            createdByName: userProfile.email,
            createdById: userProfile.id,
            keys: [],
          },
        ],
        priority: 'P3',
        queueAssignments: [],
        regionId: emptyId,
        status: 'OPEN',
        subStatus: '',
        summary: message,
        type: 'Service Request',
        category: 'IB Dashboard',
        subCategory: 'Help Desk Request',
        updatedAt: MIN_DATE_TIME_STRING,
        id: emptyId,
        number: '',
        organizationId: userProfile.organization.id,
        attributes: [],
      }

      sendTicket(ticket);
    }
  };

  return (
    <>
      <Tooltip title="Help">
        <Button
          style={styles.button}
          size="large"
          shape="circle"
          icon={<QuestionOutlined />}
          onClick={() => setOpen(true)}
        />
      </Tooltip>
      <Modal
        title={<div style={styles.title}>Help Desk Request</div>}
        open={open}
        onCancel={() => setOpen(false)}
        onOk={handleSubmit}
        okText="Submit"
        confirmLoading={isSending}
      >
        <div style={{ marginBottom: '2rem' }}>
          Enter a question or comment and we’ll get back to you as soon as we
          can.
        </div>
        <Form layout="vertical" style={styles.form}>
          <FormItem
            label="Email"
            colon={false}
            style={styles.formItem}
            validateStatus={R.isNil(errors.email) ? 'success' : 'error'}
            help={R.isNil(errors.email) ? null : errors.email}
            required
          >
            <Input
              name="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              autoComplete="off"
              disabled
            />
          </FormItem>
          <FormItem
            label="Name"
            colon={false}
            style={styles.formItem}
            validateStatus={R.isNil(errors.name) ? 'success' : 'error'}
            help={R.isNil(errors.name) ? null : errors.name}
            required
          >
            <Input
              name="name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              autoComplete="off"
            />
          </FormItem>
          <FormItem label="Phone Number" colon={false} style={styles.formItem}>
            <Input
              name="phone"
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
              autoComplete="off"
            />
          </FormItem>
          <FormItem
            label="Message"
            colon={false}
            style={styles.formItem}
            validateStatus={R.isNil(errors.message) ? 'success' : 'error'}
            help={R.isNil(errors.message) ? null : errors.message}
            required
          >
            <Input.TextArea
              name="message"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              autoComplete="off"
            />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default HelpDeskModal;
