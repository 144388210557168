import React from 'react';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import { OverlayNetworkBypass } from '@totem/components/maintenanceWindows/types';
import { isNotNull } from '@totem/utilities/common';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

type Props = {
  overlayNetworkBypasses: OverlayNetworkBypass[];
  loading: boolean;
};

const OverlayBypassTable = ({ overlayNetworkBypasses, loading }: Props) => {
  const columns: ColumnProps<OverlayNetworkBypass>[] = [
    {
      title: 'Overlay Network',
      dataIndex: 'overlayNetworkName',
      key: 'overlayNetworkName',
      showSorterTooltip: true,
      render: (_, record: OverlayNetworkBypass) => (
        <span>{record.overlayNetworkName}</span>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.overlayNetworkName, compB.overlayNetworkName),
    },
    {
      title: 'Devices',
      dataIndex: 'devices',
      key: 'devices',
      showSorterTooltip: true,
      render: (_, record: OverlayNetworkBypass) =>
        record.devices.map((device) => (
          <>
            <Link to={`/dashboard/devices/${device.id}`}>{device.name}</Link>
            <br />
          </>
        )),
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={
        isNotNull(overlayNetworkBypasses) ? overlayNetworkBypasses : []
      }
      loading={isNotNull(loading) ? loading : true}
      rowKey={(record) => record.overlayNetworkName}
    />
  );
};

export default OverlayBypassTable;
