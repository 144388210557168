import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'antd';

import ActivityEditModal from '@totem/components/ticketing/details/activityEditModal';
import ActivityTable from '@totem/components/ticketing/details/activityTable';
import {
  Activity,
  ActivityConfiguration,
  TicketQueryResult,
} from '@totem/types/ticketing';
import { getToken } from '@totem/utilities/accountUtilities';
import { TICKET_ENDPOINT } from '@totem/utilities/endpoints';

import '../ticketing.css';

type Props = {
  ticket: TicketQueryResult;
  onTicketUpdated: (TicketQueryResult) => void;
};

const Activities = ({ ticket, onTicketUpdated }: Props) => {
  const [showActivityModal, setShowActivityModal] = useState<boolean>(false);
  const [eligibleActivities, setEligibleActivities] = useState<
    ActivityConfiguration[]
  >([]);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (ticket !== null && ticket.ticket !== null && ticket.ticket.id !== '') {
      fetch(
        `${TICKET_ENDPOINT}/${ticket.ticket.id}/eligibleActivities/${ticket.ticket.organizationId}`,
        {
          method: 'GET',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
        },
      )
        .then((res) => res.json())
        .then((result: ActivityConfiguration[]) => {
          setEligibleActivities(result);
        });
    }
  }, [ticket]);

  const sendActivity = useCallback(
    async (request: Activity) => {
      if (isSending) {
        return;
      }

      fetch(`${TICKET_ENDPOINT}/${ticket.ticket.id}/activity`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request),
      })
        .then((res) => res.json())
        .then((result) => {
          onTicketUpdated(result);
        })
        .then(() => {
          setIsSending(false);
        });

      setIsSending(true);
    },
    [isSending],
  );

  const handleActivityEditClose = () => {
    setShowActivityModal(false);
  };

  const handleActivitySubmit = (activity: Activity) => {
    sendActivity(activity);
    handleActivityEditClose();
  };

  return (
    ticket !== null &&
    ticket.ticket !== null &&
    ticket.ticket.activity !== null && (
      <>
        <div styleName="tab-header">
          <div>&nbsp;</div>
          <Button type="primary" onClick={() => setShowActivityModal(true)}>
            Add Activity
          </Button>
        </div>
        <ActivityTable ticket={ticket} />
        {showActivityModal && (
          <ActivityEditModal
            ticket={ticket}
            eligibleActivities={eligibleActivities}
            visible={showActivityModal}
            onSubmit={handleActivitySubmit}
            onClose={handleActivityEditClose}
          />
        )}
      </>
    )
  );
};

export default Activities;
