import React, { useContext } from 'react';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import { Link } from 'react-router-dom';
import MaintenanceWindowContext from '@totem/components/maintenanceWindows/maintenanceWindowContext';
import { DeviceReference } from '@totem/components/maintenanceWindows/types';
import { isNotNull } from '@totem/utilities/common';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

type Props = {
  devices: DeviceReference[];
};

const DevicesTable = ({ devices }: Props) => {
  const { loading } = useContext(MaintenanceWindowContext);

  const columns: ColumnProps<DeviceReference>[] = [
    {
      title: 'Device',
      dataIndex: 'device.name',
      key: 'device.name',
      showSorterTooltip: true,
      render: (_, record: DeviceReference) => (
        <Link to={`/dashboard/devices/${record.device.id}`}>
          {record.device.name}
        </Link>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.device.name, compB.device.name),
    },
    {
      title: 'Control System',
      dataIndex: 'controlSystem.name',
      key: 'controlSystem.name',
      showSorterTooltip: true,
      render: (_, record: DeviceReference) => record.controlSystem.name,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.controlSystem.name, compB.controlSystem.name),
    },
    {
      title: 'Building',
      dataIndex: 'building.name',
      key: 'building.name',
      showSorterTooltip: true,
      render: (_, record: DeviceReference) => record.building.name,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.building.name, compB.building.name),
    },
    {
      title: 'Region',
      dataIndex: 'region.name',
      key: 'region.name',
      showSorterTooltip: true,
      render: (_, record: DeviceReference) => record.region.name,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.region.name, compB.region.name),
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={isNotNull(devices) ? devices : []}
      loading={isNotNull(loading) ? loading : true}
      rowKey={(record) => record.device.id}
    />
  );
};

export default DevicesTable;
