import { createContext } from 'react';

import { SoftwarePackageResponse } from '@totem/components/software/software_package/types';
import { emptyId } from '@totem/utilities/userUtilities';

interface Context {
  loading: boolean;
  organizationId: string;
  data: SoftwarePackageResponse;
  selectedVersion: string;
  setSelectedVersion?: (version: string) => void;
  action: string;
  onAction?: (action: string) => void;
}

export default createContext<Context>({
  loading: false,
  organizationId: emptyId,
  data: null,
  selectedVersion: '',
  action: '',
  onAction: () => {},
});
