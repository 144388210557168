import React, { useContext, useState } from 'react';
import { Button } from 'antd';
import DeviceContext from '@totem/components/devices/devicesContainer/deviceContainerContext';
import { isNotNull, isNull } from '@totem/utilities/common';
import UserProfileContext from '@totem/components/UserProfileContext';
import { getUserRole } from '@totem/utilities/userUtilities';
import MaintenanceWindowDialog from '@totem/components/devices/devicesContainer/MaintenanceWindowDialog'
import { ToolOutlined } from '@ant-design/icons';

const MaintenanceWindowButton = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { loading, selectedDevices } = useContext(DeviceContext);
  const [showMaintenanceWindowDialog, setShowMaintenanceWindowDialog] = useState(false);
  const canCreateMaintenanceWindows = getUserRole(userProfile) >= 1

  return (
    <>
      <Button icon={<ToolOutlined />} disabled={!canCreateMaintenanceWindows || loading || isNull(selectedDevices) || selectedDevices.length < 1} onClick={() => setShowMaintenanceWindowDialog(true)} >
        Create Maintenance Window {isNotNull(selectedDevices) && selectedDevices.length > 0 ? ' (' + selectedDevices.length + ')' : ''}
      </Button>
      { showMaintenanceWindowDialog && (
        <MaintenanceWindowDialog mode={'edit'} onClose={() => setShowMaintenanceWindowDialog(false)} open={showMaintenanceWindowDialog}/>
      )}
    </>
  );
};

export default MaintenanceWindowButton;
