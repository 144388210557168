import React, { useContext } from 'react';
import { Col, Row } from 'antd';

import StatisticsChart from '@totem/components/assetInventory/statistics/StatisticsChart';
import DeviceContext from '@totem/components/devices/devicesContainer/deviceContainerContext';
import { DevicesConnectionInput } from '@totem/types/devices';

const AssetInventoryFlaggedItems = () => {
  const { setInput } = useContext(DeviceContext);

  return (
    <div style={{ zoom: 1.0 }}>
      <Row>
        <Col span={12}>
          <StatisticsChart
            onClick={(updated: Partial<DevicesConnectionInput>) => {
              setInput(updated);
            }}
          />
        </Col>
        <Col span={12}>&nbsp;</Col>
      </Row>
    </div>
  );
};

export default AssetInventoryFlaggedItems;
