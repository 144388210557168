import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';
import * as R from 'ramda';

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import {
  ControlSystemType,
  ControlSystemUpdateInput,
} from '@totem/types/controlSystem';
import {
  controlSystemTypeMap,
  controlSystemTypeOptions,
} from '@totem/utilities/controlSystemsUtilities';

import { ControlSystemDetails } from '@totem/components/controlSystemDetail/container/types';
import { RequestControlSystemUpdate } from '@totem/components/controlSystemDetail/types';
import { CONTROL_SYSTEMS_ENDPOINT } from '@totem/utilities/endpoints';
import { getToken } from '@totem/utilities/accountUtilities';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

const FormItem = Form.Item;
const { Option } = Select;

interface FormErrors {
  name?: string;
  systemType?: string;
  email?: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  controlSystem: ControlSystemDetails;
}

const initialState = {
  input: {
    id: '',
    name: '',
    systemType: 0,
  },
};

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
    width: '100%',
  },
  checkbox: {
    fontSize: '12px',
    padding: '1rem 0',
  },
  datePicker: {
    width: '100%',
  },
};

const mapControlSystemToInput = (
  controlSystem: ControlSystemDetails,
): ControlSystemUpdateInput => {
  return {
    id: controlSystem.controlSystem.id,
    name: controlSystem.controlSystem.name,
    systemType: controlSystem.controlSystem.systemType,
    manufacturer: controlSystem.controlSystem.manufacturer,
    model: controlSystem.controlSystem.model,
  };
};

const ControlSystemEditModal = ({ open, onClose, controlSystem }: Props) => {
  const [isSending, setIsSending] = useState(false);
  const [errors, setErrors] = useState<FormErrors>({});
  const [input, setInput] = useState<ControlSystemUpdateInput>(
    initialState.input,
  );

  useEffect(() => {
    if (!open) {
      setErrors({});
      setInput(initialState.input);
    }
  }, [open]);

  useEffect(() => {
    if (controlSystem) {
      setErrors({});
      setInput(mapControlSystemToInput(controlSystem));
    }
  }, [controlSystem]);

  const sendControlSystemUpdate = useCallback(async (request: RequestControlSystemUpdate) => {
    if (!isSending) {
      setIsSending(true);
      fetch(`${CONTROL_SYSTEMS_ENDPOINT}/${request.id}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request),
      })
        .then(res => {
          setIsSending(false);
          CheckResponseShowError(res);
          onClose();
        });
    }
  }, []);

  const isValidForm = (): boolean => {
    const formErrors: FormErrors = {};

    if (!input.name) {
      formErrors.name = 'Please enter a name';
    }

    if (R.isNil(input.systemType)) {
      formErrors.systemType = 'Please select a control system type';
    }

    setErrors(formErrors);
    return R.isEmpty(formErrors);
  };

  const handleChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [name]: value });
  };

  const handleTypeChange = (systemType: ControlSystemType) => {
    const type = controlSystemTypeMap[systemType];
    setInput({ ...input, name: type.text, systemType });
  };

  const handleSubmit = async () => {
    if (!isValidForm()) {
      return;
    }

    const payload: RequestControlSystemUpdate = {
      id: input.id,
      name: input.name,
      systemType: input.systemType,
      manufacturer: input.manufacturer,
      model: input.model,
    }

    sendControlSystemUpdate(payload);
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={<ModalTitle>Edit Control System</ModalTitle>}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" loading={isSending} onClick={handleSubmit}>
            Save
          </Button>
        </ModalFooter>
      }
      confirmLoading={isSending}
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="System Type"
          validateStatus={!errors.systemType ? 'success' : 'error'}
          help={!errors.systemType ? null : errors.systemType}
          colon={false}
          style={styles.formItem}
        >
          <Select
            showSearch
            filterOption
            onChange={handleTypeChange}
            value={input.systemType}
            optionFilterProp="children"
            disabled={isSending}
          >
            {controlSystemTypeOptions.map((option) => {
              return (
                <Option key={option.value} value={option.value}>
                  {option.text}
                </Option>
              );
            })}
          </Select>
        </FormItem>
        <FormItem
          label="System Name"
          colon={false}
          validateStatus={!errors.name ? 'success' : 'error'}
          help={!errors.name ? null : errors.name}
          style={styles.formItem}
        >
          <Input
            disabled={isSending}
            value={input.name}
            name="name"
            onChange={handleChange}
            type="search"
          />
        </FormItem>
        <FormItem label="Manufacturer" colon={false} style={styles.formItem}>
          <Input
            disabled={isSending}
            value={input.manufacturer}
            name="manufacturer"
            onChange={handleChange}
            type="search"
          />
        </FormItem>
        <FormItem label="Model" colon={false} style={styles.formItem}>
          <Input
            disabled={isSending}
            value={input.model}
            name="model"
            onChange={handleChange}
            type="search"
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default ControlSystemEditModal;
