import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CloseSquareOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Radio, Row } from 'antd';

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import TicketingContext from '@totem/components/ticketing/listContainer/ticketingContainerContext';
import { TicketRelationshipRequest } from '@totem/components/ticketing/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { TICKETS_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

const styles = {
  dialog: {
    maxHeight: '80%',
    maxWidth: '850px',
  },
  label: {
    fontSize: '12px',
    fontWeight: 600,
  },
};

type Props = {
  open: boolean;
  onClose: () => void;
};

const MergeTicketsDialog = ({ open, onClose }: Props) => {
  const { selectedTickets, deselectTicket, refetch, clearSelectedTickets } =
    useContext(TicketingContext);
  const [selectedTicket, setSelectedTicket] = useState<string>('');
  const [isSending, setIsSending] = useState<boolean>(false);

  useEffect(() => {
    if (selectedTicket === '' && selectedTickets.length > 0) {
      setSelectedTicket(selectedTickets[0].id);
    }
  }, [selectedTickets]);

  const sendMergeRequest = useCallback(async (payload: any) => {
    if (isSending) {
      return;
    }
    setIsSending(true);

    fetch(`${TICKETS_ENDPOINT}/relationships`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(payload),
    }).then((res) => {
      CheckResponseShowError(res);
      setIsSending(false);
      clearSelectedTickets();
      refetch();
      onClose();
    });
  }, []);

  const handleSubmit = () => {
    const parentObj = selectedTickets.find((chk) => chk.id === selectedTicket);
    const childObjs = selectedTickets.filter(
      (chk) => chk.id !== selectedTicket,
    );

    if (isNotNull(parentObj) && isNotNull(childObjs) && childObjs.length > 0) {
      const payload: TicketRelationshipRequest = {
        parent: {
          relationship: 'parent',
          objectType: 'ticket',
          id: parentObj.id,
          name: parentObj.name,
        },
        children: childObjs.map((obj) => {
          return {
            relationship: 'child',
            objectType: 'ticket',
            id: obj.id,
            name: obj.name,
          };
        }),
      };

      sendMergeRequest(payload);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={() => onClose()}
      title={<ModalTitle>Merge Packages</ModalTitle>}
      confirmLoading={false}
      style={styles.dialog}
      footer={
        <ModalFooter>
          <Button onClick={() => onClose()}>Close</Button>
          <Button
            onClick={handleSubmit}
            type="primary"
            disabled={selectedTicket === ''}
          >
            Submit
          </Button>
        </ModalFooter>
      }
    >
      <div style={styles.label}>
        Select the parent ticket, the remaining tickets will be children of the
        selected ticket:
      </div>
      <Radio.Group value={selectedTicket}>
        {selectedTickets.map((ticket) => (
          <Row key={ticket.id}>
            <Col span={23}>
              <Radio
                value={ticket.id}
                onClick={() => setSelectedTicket(ticket.id)}
              >
                {ticket.name}
              </Radio>
            </Col>
            <Col span={1}>
              <CloseSquareOutlined
                onClick={() => {
                  deselectTicket(ticket.id);
                }}
              />
            </Col>
          </Row>
        ))}
      </Radio.Group>
    </Modal>
  );
};

export default MergeTicketsDialog;
