import React, { useContext, useState } from 'react';
import { EditTwoTone } from '@ant-design/icons';
import { Button, Card } from 'antd';

import BuildingContext from '@totem/components/buildingDetail/container/BuildingContext';
import SideBarTitle from '@totem/components/SideBarTitle';
import colors from '@totem/styles/colors';
import addressUtilities from '@totem/utilities/addressUtilities';
import { isNotNull } from '@totem/utilities/common';

import EditBuildingModal from './EditBuildingModal';

import './sidebar.css';

const styles = {
  card: {
    width: '100%',
    marginBottom: '3.5rem',
  },
  cardHeader: {
    borderTop: `5px solid`,
    borderImage: `${colors.gradient.accent} 100% 0 0 0`,
    //borderImage: `${colors.gradient.turquoise} 100% 0 0 0`,
  },
  cardBody: {
    padding: '2.4rem',
    paddingBottom: '0',
    borderTop: `1px solid ${colors.antd.borderGray}`,
  },
};

const BuildingInfo = () => {
  const { data: building } = useContext(BuildingContext);

  const [open, setOpen] = useState<boolean>(false);

  const address = isNotNull(building)
    ? addressUtilities.getAddress(building)
    : '';

  return (
    <>
      <Card
        title={
          <SideBarTitle>
            <div>Building Detail</div>
            <Button
              shape="circle"
              icon={<EditTwoTone twoToneColor={colors.brand.blue} />}
              onClick={() => setOpen(true)}
            />
          </SideBarTitle>
        }
        style={styles.card}
        styles={{ body: styles.cardBody, header: styles.cardHeader }}
      >
        <>
          <div styleName="info-row">
            <div styleName="info-label">Building Name</div>
            <div styleName="info-content">
              {isNotNull(building) ? building.name : ''}
            </div>
          </div>
          <div styleName="info-row">
            <div styleName="info-label">Address</div>
            <div styleName="info-content">{address}</div>
          </div>
          {isNotNull(building) &&
            typeof building.type !== 'undefined' &&
            building.type !== null &&
            building.type !== '' && (
              <div styleName="info-row">
                <div styleName="info-label">Type</div>
                <div styleName="info-content">{building.type}</div>
              </div>
            )}
          {isNotNull(building) &&
            typeof building.squareFootage !== 'undefined' &&
            building.squareFootage !== null &&
            building.squareFootage !== 0 && (
              <div styleName="info-row">
                <div styleName="info-label">Square Footage</div>
                <div styleName="info-content">
                  {building.squareFootage.toLocaleString()}
                </div>
              </div>
            )}
        </>
      </Card>
      <EditBuildingModal open={open} setOpen={setOpen} />
    </>
  );
};

export default BuildingInfo;
