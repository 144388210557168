import React, { useContext, useState } from 'react';
import { Col, Flex, Row, Switch } from 'antd';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import BulkTagButton from '@totem/components/software/software_report/bulkTagButton';
import TagsSection from '@totem/components/software/software_report/byTag/tagsSection';
import CountBadge from '@totem/components/software/software_report/countBadge';
import MergeButton from '@totem/components/software/software_report/mergeButton';
import SoftwareContainer from '@totem/components/software/software_report/softwareContainer';
import SoftwareFilters from '@totem/components/software/software_report/softwareFilters';
import SoftwareTable from '@totem/components/software/software_report/softwareTable';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { emptyId, getUserRole } from '@totem/utilities/userUtilities';
import UserProfileContext from '@totem/components/UserProfileContext';
import { isIBUser } from '@totem/utilities/security';
import SwitchMonitored from '@totem/components/software/software_report/switchMonitored';

const styles = {
  inset: {
    marginLeft: '20px',
    marginRight: '20px',
  },
};

const SoftwarePage = () => {
  const { userProfile } = useContext(UserProfileContext);
  const [grouped, setGrouped] = useState<boolean>(false);
  const isIB = isIBUser(userProfile);
  const canEdit = getUserRole(userProfile) === 3
  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <SoftwareContainer
            organizationId={emptyId}
          >
            <br />
            <SoftwareFilters />
            <br />
            <br />
            <div style={styles.inset}>
              <Row>
                <Col span={12}>
                  { isIB && canEdit && (
                    <Flex justify={'flex-start'} align={'center'}>
                      <MergeButton />
                      <BulkTagButton isPrimary />
                    </Flex>
                  )}
                </Col>
                <Col span={12}>
                  <Flex justify={'flex-end'} align={'center'}>
                    <SwitchMonitored />
                    Group by Tag:
                    <Switch
                      style={{ marginRight: '16px', marginLeft: '8px' }}
                      onChange={setGrouped}
                      value={grouped}
                    />
                    <CountBadge />
                  </Flex>
                </Col>
              </Row>
              <br />
              {!grouped && <SoftwareTable />}
              {grouped && <TagsSection />}
            </div>
          </SoftwareContainer>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default SoftwarePage;
