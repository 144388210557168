import React, { useContext } from 'react';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import EventSeverityIndicator from '@totem/components/events/EventSeverityIndicator';
import MaintenanceWindowContext from '@totem/components/maintenanceWindows/maintenanceWindowContext';
import { EventReference } from '@totem/components/maintenanceWindows/types';
import { isNotNull } from '@totem/utilities/common';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

type Props = {
  events: EventReference[];
};

const EventsTable = ({ events }: Props) => {
  const { loading } = useContext(MaintenanceWindowContext);

  const formatDateTIme = (dateIn: string) => {
    if (dateIn !== null) {
      if (!isNaN(Date.parse(dateIn))) {
        const dateVal = new Date(dateIn);
        return (
          <>
            {dateVal.toLocaleDateString()}
            <br />
            {dateVal.toLocaleTimeString()}
          </>
        );
      }
    }
    return <span>-</span>;
  };

  const columns: ColumnProps<EventReference>[] = [
    {
      title: 'Severity',
      dataIndex: 'severity',
      key: 'severity',
      className: 'severity-column',
      width: '95px',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      render: (_, record: EventReference) => (
        <EventSeverityIndicator severity={record.severity} />
      ),
      sorter: (compA, compB) =>
        compA.severity === compB.severity
          ? 0
          : compA.severity > compB.severity
            ? 1
            : -1,
    },
    {
      title: 'Source',
      dataIndex: 'sourceType',
      key: 'sourceType',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.sourceType === compB.sourceType
          ? 0
          : compA.sourceType > compB.sourceType
            ? 1
            : -1,
    },
    {
      title: 'Type',
      dataIndex: 'eventType',
      key: 'eventType',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.sourceType === compB.sourceType
          ? 0
          : compA.sourceType > compB.sourceType
            ? 1
            : -1,
    },
    {
      title: 'Device',
      dataIndex: 'device.name',
      key: 'device.name',
      showSorterTooltip: true,
      render: (_, record: EventReference) => record.device.name,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.device.name, compB.device.name),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Event Time',
      dataIndex: 'eventTime',
      key: 'eventTime',
      render: (_, record: EventReference) => formatDateTIme(record.eventTime),
      sorter: (compA, compB) =>
        compA.eventTime === compB.eventType
          ? 0
          : compA.eventTime > compB.eventTime
            ? 1
            : -1,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={isNotNull(events) ? events : []}
      loading={isNotNull(loading) ? loading : true}
      rowKey={(record) => record.id}
    />
  );
};

export default EventsTable;
