import React, { useContext } from 'react';
import { Row } from 'antd';
import DeviceContext from '@totem/components/devices/deviceDetails/DeviceContext';
import HeaderItem from '@totem/components/devices/deviceDetails/header/HeaderItem';
import DeviceHealthConditional from '@totem/components/devices/health/deviceHealthConditional';
import DeviceHealthContainer from '@totem/components/devices/health/deviceHealthContainer';
import { isNotNull } from '@totem/utilities/common';
import HeaderHealth from '@totem/components/devices/deviceDetails/header/HeaderHealth';
import { hasHealth } from '@totem/components/devices/health/utilities';

const DeviceDetailHeader = () => {
  const { deviceData } = useContext(DeviceContext);

  const formatDateTIme = (dateIn: string) => {
    if (dateIn !== null) {
      if (!isNaN(Date.parse(dateIn))) {
        const dateVal = new Date(dateIn);
        return <>{dateVal.toLocaleString()}</>;
      }
    }
    return <span>-</span>;
  };

  return (
    <>
      <Row>
        <HeaderItem span={8} title={'Device Name'} value={deviceData?.device.displayName} />
        <HeaderItem span={8} title={'Control System'} value={isNotNull(deviceData?.controlSystem) ? deviceData?.controlSystem.name : ''} />
        <HeaderItem span={8} title={'Organization'} value={isNotNull(deviceData?.organization) ? deviceData?.organization.name : ''} />
      </Row>
      <Row>
        <HeaderItem span={8} title={'Type'} value={isNotNull(deviceData?.device.type) ? deviceData?.device.type : ''} />
        <HeaderItem span={8} title={'Operating System'} value={isNotNull(deviceData?.device.operatingSystem) ? deviceData?.device.operatingSystem : ''} />
        <HeaderItem span={8} title={'Region'} value={isNotNull(deviceData?.region) ? deviceData?.region.name : ''} />
      </Row>
      <Row>
        <HeaderItem span={8} title={'Vendor'} value={isNotNull(deviceData?.device.vendor) ? deviceData?.device.vendor : ''} />
        <HeaderItem span={8} title={'Last Backup'} value={isNotNull(deviceData?.device.lastBackup) ? <>{formatDateTIme(deviceData?.device.lastBackup.backedUpAt)}</> : ''} />
        <HeaderItem span={8} title={'Building'} value={isNotNull(deviceData?.building) ? deviceData?.building.name : ''} />
      </Row>
      {hasHealth(deviceData) && (
        <DeviceHealthContainer id={deviceData.device.id}>
          <DeviceHealthConditional>
            <HeaderHealth />
            <br />
            <br />
          </DeviceHealthConditional>
        </DeviceHealthContainer>
      )}
    </>
  );
};

export default DeviceDetailHeader;
