import React, { ReactNode, useContext, useEffect, useState } from 'react';
import EventContext from '@totem/components/events/details/eventContext';
import UserProfileContext from '@totem/components/UserProfileContext';
import { Event } from '@totem/types/events';
import { EVENTS_ENDPOINT } from '@totem/utilities/endpoints';
import { getToken } from '@totem/utilities/accountUtilities';

type Props = {
  eventId: string;
  children?: ReactNode;
};

const EventContainer = ({ eventId, children }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<Event>(null);
  const [action, setAction] = useState<string>('');

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      const uri = `${EVENTS_ENDPOINT}/${eventId}`;

      fetch(uri, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: Event) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  const handleAction = (newAction: string) => {
    switch (newAction) {
      case 'refresh':
        setRefreshData(true);
        setAction('');
        break;
      default:
        setAction(newAction);
    }
  };

  return (
    <EventContext.Provider
      value={{
        loading: isLoading,
        organizationId: userProfile.organization.id,
        data,
        action,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
    </EventContext.Provider>
  );
};

export default EventContainer;

