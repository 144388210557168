import React, { useCallback, useContext } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

import BuildingSummaryContext from '@totem/components/buildingSummary/buildingSummaryContext';
import colors from '@totem/styles/colors';
import { debounce } from '@totem/utilities/debounce';

import './buildingSummary.css';

const DEBOUNCE_TIME = 500;

const styles = {
  icon: {
    color: colors.neutral.gray,
  },
};
const NameSearch = () => {
  const { input, setInput } = useContext(BuildingSummaryContext);
  const handleSearch = useCallback(
    debounce(
      (name: string) => setInput({ ...input, searchName: name, page: 1 }),
      DEBOUNCE_TIME,
    ),
    [],
  );

  return (
    <div styleName="search-bar">
      <Input
        placeholder="Search Buildings"
        prefix={<SearchOutlined style={styles.icon} />}
        onChange={(event) => handleSearch(event.target.value)}
      />
    </div>
  );
};

export default NameSearch;
