import React, { ReactNode, useEffect, useState } from 'react';

import BuildingSummaryContext from '@totem/components/buildingSummary/buildingSummaryContext';
import {
  BuildingsReport,
  BuildingSummaryFilters,
  BuildingSummaryInput,
  FilterOptions,
} from '@totem/components/buildingSummary/types';
import {
  addReportFilters,
  buildReportFilters,
} from '@totem/components/buildingSummary/utilities';
import { Params } from '@totem/types/common';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_BUILDINGS_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

type Props = {
  refresh?: boolean;
  onRecordTotalChanged?: (total: number) => void;
  onDataRefreshRequested?: () => void;
  children?: ReactNode;
  deviceEndPoint?: string;
  defaultFilters?: BuildingSummaryFilters;
  staticFilters?: BuildingSummaryFilters;
};

const BuildingSummaryContainer = ({
  defaultFilters,
  staticFilters,
  children,
  onRecordTotalChanged,
  refresh,
}: Props) => {
  const [input, updateInput] = useState<BuildingSummaryInput>({
    pageSize: 10,
    page: 1,
    sortField: 'name',
    sortDirection: '1',
    searchName: '',
    regionId:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.regionId !== 'undefined' &&
      defaultFilters.regionId !== null
        ? defaultFilters.regionId
        : [],
    buildingId:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.buildingId !== 'undefined' &&
      defaultFilters.buildingId !== null
        ? defaultFilters.buildingId
        : [],
    name:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.name !== 'undefined' &&
      defaultFilters.name !== null
        ? defaultFilters.name
        : [],
    type:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.type !== 'undefined' &&
      defaultFilters.type !== null
        ? defaultFilters.type
        : [],
  });
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [reportData, setReportData] = useState<BuildingsReport>(null);
  const [filterOptons, setFilterOptions] = useState<FilterOptions>([]);

  const setInput = (updated: Partial<BuildingSummaryInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
    setRefreshData(true);
  };

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: input.page,
        pageSize: input.pageSize,
      },
      sort: {
        field: input.sortField,
        direction: +input.sortDirection,
      },
      filters: buildReportFilters(input),
    };

    params.filters = addReportFilters(params.filters, staticFilters);

    return params;
  };

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);

      setIsLoading(true);
      const params: Params = buildParameters();

      fetch(`${V2_BUILDINGS_ENDPOINT}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(params),
      })
        .then((res) => res.json())
        .then((result: BuildingsReport) => {
          setReportData(result);
          setTotalRecords(result.paging.totalRecords);

          if (
            typeof onRecordTotalChanged !== 'undefined' &&
            onRecordTotalChanged !== null
          ) {
            onRecordTotalChanged(result.paging.totalRecords);
          }
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData, refresh]);

  useEffect(() => {
    fetch(`${V2_BUILDINGS_ENDPOINT}/filters`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: FilterOptions) => {
        setFilterOptions(result);
      });
  }, []);

  const handleAction = (newAction: string) => {
    switch (newAction) {
      case 'refresh':
        setRefreshData(true);
        break;
      default:
        break;
    }
  };

  return (
    <BuildingSummaryContext.Provider
      value={{
        input,
        setInput,
        onAction: handleAction,
        loading: isLoading,
        reportData,
        filterOptions: filterOptons,
        totalRecords,
      }}
    >
      <div>{children}</div>
    </BuildingSummaryContext.Provider>
  );
};

export default BuildingSummaryContainer;
