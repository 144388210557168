import React, { ReactNode } from 'react';
import { useSnackbar } from 'react-simple-snackbar';

import colors from '@totem/styles/colors';

import NotificationContext from '@totem/components/notification/NotificationContext';

const AUTOHIDE_DURATION = 3000;

const styles = {
  error: {
    background: colors.brand.red,
    color: colors.neutral.white,
  },
};

type Props = {
  children?: ReactNode;
};

const NotificationContainer = ({ children }: Props) => {
  const [openSnackbar] = useSnackbar();
  const [openErrorSnackbar] = useSnackbar({ style: styles.error });

  const handleNotification = (message: string, isError: boolean) => {
    if (message) {
      if (isError) {
        openErrorSnackbar(message, AUTOHIDE_DURATION);
      } else {
        openSnackbar(message, AUTOHIDE_DURATION);
      }
    }
  };

  return (
    <NotificationContext.Provider value={{ notify: handleNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContainer;
