import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Select } from 'antd';

import NotificationContext from '@totem/components/notification/NotificationContext';
import UserProfileContext from '@totem/components/UserProfileContext';
import { LoginProfile } from '@totem/types/user';
import accountUtilities, { getToken } from '@totem/utilities/accountUtilities';
import { LOGIN_PROFILE_ENDPOINT } from '@totem/utilities/endpoints';

import './organizationSwitcherDropdown.css';

const { Option } = Select;

const OrganizationSwitcherDropdown = () => {
  const [loadingToken, setLoadingToken] = useState<boolean>(false);
  const navigate = useNavigate();
  const { notify } = useContext(NotificationContext);

  const { userProfile, loading, setProfile } = useContext(UserProfileContext);
  const { organizations, organization } = userProfile;

  const sendOrganizationSwitch = (
    userId: string,
    newOrganizationId: string,
  ) => {
    try {
      if (!loadingToken) {
        setLoadingToken(true);
        fetch(`${LOGIN_PROFILE_ENDPOINT}/${userId}/${newOrganizationId}`, {
          method: 'GET',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
        })
          .then((res) => {
            if (res.status >= 400) {
              console.log(res.statusText);
            }
            return res.json();
          })
          .then((result: LoginProfile) => {
            if (result.token !== '') {
              accountUtilities.setToken(result.token);
            }
            setProfile(result.profile);
            navigate('/');
          })
          .then(() => {
            setLoadingToken(false);
          });
      }
    } catch (error) {
      notify(`Could not switch to the organization`, true);
    }
  };

  const navigateToOrganization = async (id: string) => {
    sendOrganizationSwitch(userProfile.id, id);
  };

  return (
    <div styleName="organization-switcher-dropdown-container">
      {organizations && (
        <Select
          defaultValue=""
          popupMatchSelectWidth={false}
          style={{ width: 200 }}
          value={organization?.id}
          onChange={navigateToOrganization}
          loading={loading}
        >
          {organizations
            .filter((org) => org.isActive)
            .map((org) => (
              <Option key={org.id} value={org.id}>
                {org.name}
              </Option>
            ))}
        </Select>
      )}
    </div>
  );
};

export default OrganizationSwitcherDropdown;
