import { createContext } from 'react';
import { MaintenanceWindow } from '@totem/components/maintenanceWindows/types';

interface Context {
  loading: boolean;
  data: MaintenanceWindow;
  onAction?: (action: string, item: MaintenanceWindow) => void;
  action: string;
}

export default createContext<Context>({
  loading: false,
  data: null,
  action: '',
  onAction: () => {},
});
