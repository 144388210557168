import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import TenantAuthorizedRoute from '@totem/components/auth/TenantAuthorizedRoute';
import PrivacyPolicy from '@totem/components/mobile/PrivacyPolicy';
import NotificationContainer from '@totem/components/notification/NotificationContainer';
import RemoteAccessServicesRoot from '@totem/components/remoteAccessServices/RemoteAccessServicesRoot';
import TenantBase from '@totem/components/TenantBase';
import { staticAuthLoginLogo } from '@totem/selectors/preferencesSelectors';
import authUtilities from '@totem/utilities/authUtilities';

import AuthorizedRoute from './auth/AuthorizedRoute';
import ConfigDump from './configDump/ConfigDump';
import EmailUnverified from './login/EmailUnverified';
import EmailVerified from './login/EmailVerified';
import Login from './login/Login';
import NoAccess from './login/NoAccess';
import SwitchOrganization from './login/SwitchOrganization';
import Unverified from './login/Unverified';
import NotFound from './notFound/NotFound';
import Base from './Base';
import VendorBase from './VendorBase';

const Application = () => {
  let defaultPath = '/dashboard';
  if (
    window.location.hostname.startsWith('d-ras') ||
    window.location.hostname.startsWith('d-passwordreset')
  ) {
    defaultPath = '/remoteAccessServices';
  }
  if (
    window.location.hostname.startsWith('ras') ||
    window.location.hostname.startsWith('passwordreset')
  ) {
    defaultPath = '/remoteAccessServices';
  }

  return (
    <div style={{ height: '100%' }}>
      <NotificationContainer>
        <Routes>
          <Route path="/" element={<Navigate to={defaultPath} replace />} />
          <Route path="/logo.svg" element={staticAuthLoginLogo} />
          <Route
            path="/remoteAccessServices"
            element={<RemoteAccessServicesRoot />}
          />
          <Route
            path="/dashboard/*"
            element={
              <AuthorizedRoute
                component={Base}
                roleSet={authUtilities.ROLE_SETS.TEAM_MEMBER}
              />
            }
          />
          <Route
            path="/vendor-dashboard/*"
            element={
              <AuthorizedRoute
                component={VendorBase}
                roleSet={authUtilities.ROLE_SETS.VENDOR}
              />
            }
          />
          <Route
            path="/tenant-dashboard/*"
            element={
              <TenantAuthorizedRoute
                component={TenantBase}
                roleSet={authUtilities.ROLE_SETS.TENANT}
              />
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/ConfigDump" element={<ConfigDump />} />
          <Route path="/no-access" element={<NoAccess />} />
          <Route
            path="/switch-organization"
            element={
              <AuthorizedRoute
                component={SwitchOrganization}
                roleSet={authUtilities.ROLE_SETS.VENDOR}
              />
            }
          />
          <Route path="/verified-email" element={<EmailVerified />} />
          <Route path="/unverified-email" element={<EmailUnverified />} />
          <Route path="/unverified" element={<Unverified />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route element={<NotFound />} />
        </Routes>
      </NotificationContainer>
    </div>
  );
};

export default Application;
