import React, { useContext } from 'react';
import { Col, Row } from 'antd';

import ChartHorizontalBarSummary from '@totem/components/assetInventory/ChartHorizontalBarSummary';
import DeviceContext from '@totem/components/devices/devicesContainer/deviceContainerContext';

const ChartLayout = () => {
  const { setInput } = useContext(DeviceContext);

  return (
    <div style={{zoom: 1.0}}>
      <Row>
        <Col span={8}>
          <ChartHorizontalBarSummary
            field="type"
            centerLabel="Device Type (Top 10)"
            onItemClicked={(item) => {
              setInput({ type: [item.x] });
            }}
          />
        </Col>
        <Col span={8}>
          <ChartHorizontalBarSummary
            field="vendor"
            centerLabel="Vendor (Top 10)"
            onItemClicked={(item) => {
              setInput({ vendor: [item.x] });
            }}
          />
        </Col>
        <Col span={8}>
          <ChartHorizontalBarSummary
            field="operatingSystem"
            centerLabel="Operating System (Top 10)"
            onItemClicked={(item) => {
              setInput({ operatingSystem: [item.x] });
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ChartLayout;
