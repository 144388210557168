import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import { Badge, Button, Checkbox, Tag, Tooltip } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import dayjs from 'dayjs';

import Table from '@totem/components/common/table/Table';
import SoftwarePackageEditDialog from '@totem/components/software/edits/SoftwarePackageEditDialog';
import SoftwareContext from '@totem/components/software/software_report/softwareContext';
import {
  SoftwarePackage,
  SoftwareVersion,
} from '@totem/components/software/software_report/types';
import UserProfileContext from '@totem/components/UserProfileContext';
import colors from '@totem/styles/colors';
import { isNotNull } from '@totem/utilities/common';
import { isIBUser } from '@totem/utilities/security';
import { sortVersionNumbersAscending } from '@totem/utilities/tableUtilities';
import { emptyId, getUserRole } from '@totem/utilities/userUtilities';
import Vulnerabilities from '@totem/components/software/software_report/vulnerabilities';

const styles = {
  tag: {
    marginBottom: '3px',
  },
  button: {
    marginRight: '4px',
  },
};

const SoftwareTable = () => {
  const {
    data,
    loading,
    input,
    setInput,
    onAction,
    selectedPackages,
    setPackageSelected,
    organizationId,
  } = useContext(SoftwareContext);
  const { userProfile } = useContext(UserProfileContext);
  const canEdit = getUserRole(userProfile) === 3 && isIBUser(userProfile);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [selectedPackage, setSelectedPackage] = useState<SoftwarePackage>(null);

  const getData = () => {
    if (typeof data !== 'undefined' && data !== null) {
      return data;
    }
    return [];
  };

  const getEndOfSupportIndicator = (version: SoftwareVersion) => {
    if (isNotNull(version) && isNotNull(version.endOfSupport)) {
      if (version.endOfSupport.isEndOfSupport) {
        return (
          <span
            style={{ color: colors.criticality.critical }}
            title="Past End-of-Support"
          >
            &nbsp;<b>(EOS)</b>
          </span>
        );
      }
      if (
        isNotNull(version.endOfSupport.endOfSupportDate) &&
        version.endOfSupport.endOfSupportDate !== ''
      ) {
        const eosDate = dayjs(version.endOfSupport.endOfSupportDate);
        if (eosDate.year() > 2000) {
          // eslint-disable-next-line max-depth
          if (eosDate.isBefore(dayjs())) {
            return (
              <span
                style={{ color: colors.event.criticality.background.critical }}
                title="Past End-of-Support"
              >
                &nbsp;<b>(EOS)</b>
              </span>
            );
          }
          // eslint-disable-next-line max-depth
          if (eosDate.isBefore(dayjs().add(12, 'month'))) {
            return (
              <span
                style={{ color: colors.event.criticality.background.minor }}
                title="Near End-of-Support"
              >
                &nbsp;<b>(EOS)</b>
              </span>
            );
          }
        }
      }
    }

    return <span />;
  };

  const handleCheckToggle = (
    packageName: SoftwarePackage,
    isSelected: boolean,
  ) => {
    setPackageSelected(packageName, isSelected);
  };

  const handleEditClicked = (sw: SoftwarePackage) => {
    setSelectedPackage(sw);
    setShowEdit(true);
  };

  const handleCancelEdit = (refresh: boolean) => {
    setShowEdit(false);
    setSelectedPackage(null);
    if (refresh) {
      onAction('refresh', null);
    }
  };

  const getOrganizationalTags = (pkg: SoftwarePackage) => {
    const orgTags =
      organizationId != emptyId &&
      isNotNull(data) &&
      isNotNull(pkg) &&
      isNotNull(pkg.organizationTags) &&
      pkg.organizationTags.length > 0 &&
      pkg.organizationTags[0].organizationId === userProfile.organization.id
        ? pkg.organizationTags[0].tags
        : [];
    return orgTags;
  };

  const getCveCount = (record: SoftwarePackage) => {
    if (record.keys.length === 0) {
      return <span style={{color: '#BBBBBB'}}>N/A</span>
    }
    if (isNotNull(record.vulnerabilities)) {
      const filteredCVEs = record.vulnerabilities.filter((chk) => chk !== '')
      return <span>{[...new Set(filteredCVEs)].length}</span>
    }
  }

  const columns: ColumnProps<SoftwarePackage>[] = [
    {
      title: (
        <Badge
          title={'Selected Count'}
          count={isNotNull(selectedPackages) ? selectedPackages.length : 0}
          offset={[0, 2]}
        >
          <CloseCircleOutlined
            style={{ marginTop: 8, marginRight: 8 }}
            onClick={() => onAction('clear_package_selections', null)}
          />
        </Badge>
      ),
      render: (_, record: SoftwarePackage) => (
        <Checkbox
          checked={
            selectedPackages.findIndex((chk) => chk.id === record.id) >= 0
          }
          onChange={(evt) => handleCheckToggle(record, evt.target.checked)}
        />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record: SoftwarePackage) => (
        <Link
          to={`/dashboard/software/${record.id}?from=catalog:X:Software Catalog`}
        >
          {record.name}
        </Link>
      ),
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        compA.name === compB.name ? 0 : compA.name > compB.name ? 1 : -1,
    },
    {
      title: 'Publisher',
      dataIndex: 'publisher',
      key: 'publisher',
      sorter: (compA, compB) =>
        compA.publisher === compB.publisher
          ? 0
          : compA.publisher > compB.publisher
            ? 1
            : -1,
    },
    {
      title: 'Versions',
      dataIndex: 'versions',
      key: 'versions',
      render: (_, record: SoftwarePackage) => (
        <span>
          {record.versions !== null &&
            record.versions
              .sort((compA, compB) =>
                sortVersionNumbersAscending(compA.number, compB.number),
              )
              .map((version, idx) =>
                idx === 0 ? (
                  <span key={version.number}>
                    {version.number}
                    {getEndOfSupportIndicator(version)}
                  </span>
                ) : (
                  <span key={version.number}>
                    <br />
                    {version.number}
                    {getEndOfSupportIndicator(version)}
                  </span>
                ),
              )}
        </span>
      ),
    },
    ...(canEdit
      ? [
          {
            title: 'Aliases',
            dataIndex: 'aliases',
            key: 'aliases',
            render: (_, record: SoftwarePackage) => (
              <span>
                {record.aliases !== null &&
                  record.aliases.map((alias, idx) =>
                    idx === 0 ? (
                      <span key={alias.criteria + alias.match}>
                        {alias.criteria}: {alias.match}
                      </span>
                    ) : (
                      <span key={alias.criteria + alias.match}>
                        <br />
                        {alias.criteria}: {alias.match}
                      </span>
                    ),
                  )}
              </span>
            ),
          },
        ]
      : []),
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      render: (_, record: SoftwarePackage) => (
        <span>
          {isNotNull(record.tags) &&
            record.tags.map((chk) => (
              <Tag key={chk} color={'success'} style={styles.tag}>
                {chk}
              </Tag>
            ))}
          {getOrganizationalTags(record).map((chk) => (
            <Tag key={chk} color={'volcano'} style={styles.tag}>
              {chk}
            </Tag>
          ))}
        </span>
      ),
    },
    {
      title: <span title={'Software Vulnerabilities'}>CVEs</span>,
      dataIndex: 'vulnerabilities',
      key: 'vulnerabilities',
      render: (_, record: SoftwarePackage) => getCveCount(record),
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'additionalOptions',
      render: (_, software: SoftwarePackage) => (
        <div className="center-table-cell">
          {canEdit && (
            <>
              <Tooltip title="Edit" placement="top">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  disabled={!canEdit}
                  style={styles.button}
                  onClick={() => handleEditClicked(software)}
                />
              </Tooltip>
            </>
          )}
        </div>
      ),
    },
  ];

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<SoftwarePackage>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'lastOccurrence';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <>
      <Table
        showSorterTooltip
        columns={columns}
        dataSource={getData()}
        loading={loading}
        rowKey={(record) => record.name}
        onChange={handleTableChange}
        pagination={{
          current: input.page,
          pageSize: input.pageSize,
          total: typeof data !== 'undefined' && data !== null ? data.length : 0,
          showSizeChanger: true,
        }}
        expandable={{
          expandedRowRender: (record) => <Vulnerabilities id={record.id} />,
          rowExpandable: (record) => record.vulnerabilities.length > 0
        }}
      />
      {canEdit && showEdit && isNotNull(selectedPackage) && (
        <SoftwarePackageEditDialog
          visible={canEdit && showEdit && isNotNull(selectedPackage)}
          onClose={handleCancelEdit}
          packageId={selectedPackage.id}
          packageName={selectedPackage.name}
          packagePublisher={selectedPackage.publisher}
        />
      )}
    </>
  );
};

export default SoftwareTable;
