import React, { useContext } from 'react';

import RemoteAccessSettings from '@totem/components/settings/sections/account/remoteAccess/remoteAccessSettings';
import UserProfileContext from '@totem/components/UserProfileContext';
import {
  hasActiveService,
  isMemberOfAny,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

import './account.css';

const Account = () => {
  const { userProfile } = useContext(UserProfileContext);

  const showRemoteAccess = hasActiveService(userProfile, 'remote_access');
  const remoteAccessAdmin = isMemberOfAny(
    userProfile,
    ['remote_access_admin', 'remote_access_configuration_admin'],
    securityGroupCheckConstraintEmpty,
  );

  return (
    <div styleName="container">
      {remoteAccessAdmin && showRemoteAccess && <RemoteAccessSettings />}
    </div>
  );
};

export default Account;
