import React, { useContext } from 'react';
import BuildingContext from '@totem/components/buildingDetail/container/BuildingContext';
import { isNotNull } from '@totem/utilities/common';

const BuildingDetailPageTitle = () => {
  const { data } = useContext(BuildingContext);

  return <span>{isNotNull(data) ? data.name : ''}</span>
};

export default BuildingDetailPageTitle;
