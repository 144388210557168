import React, { useContext } from 'react';
import EventContext from '@totem/components/events/details/eventContext';
import EventDetailView from '@totem/components/events/detailView/EventDetailView';
import Loading from '@totem/components/Loading';
import { isNull } from '@totem/utilities/common';

const EventDetailsWrapper = () => {
  const { data, loading } = useContext(EventContext);

  if (loading || isNull(data)) {
    return <Loading />;
  }

  return <EventDetailView event={data} />;
};

export default EventDetailsWrapper;
