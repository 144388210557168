import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber, Modal, Select } from 'antd';
import * as R from 'ramda';

import BuildingContext from '@totem/components/buildingDetail/container/BuildingContext';
import { BuildingPatch } from '@totem/components/buildingDetail/sidebar/types';
import AddressEntry from '@totem/components/common/addressEntry/AddressEntry';
import UserProfileContext from '@totem/components/UserProfileContext';
import { Address, AddressFormErrors } from '@totem/types/address';
import { BuildingTypes } from '@totem/types/building';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { BUILDINGS_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

const FormItem = Form.Item;
const Option = Select.Option;

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

export interface Props {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

const FORM_FIELDS_MAP: { [key: string]: string } = {
  addressOne: 'Address',
  city: 'City',
  state: 'State',
  country: 'Country',
  zipCode: 'Zip Code',
};

const EditBuildingModal = ({ open, setOpen }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const { data: building } = useContext(BuildingContext);

  const [buildingTypes, setBuildingTypes] = useState<BuildingTypes>({
    id: '62a8d9d5134444b27707dda8',
    type: 'BuildingTypes',
    data: [
      '',
      'Commercial Office',
      'Data Center',
      'Educational',
      'Life Sciences',
      'Multi-Family High Rise',
      'Multi-Family Podium',
      'Retail',
      'Warehouse',
      'Other',
    ],
  });
  const [errors, setErrors] = useState<AddressFormErrors>({});
  const [isSending, setIsSending] = useState(false);
  const [name, setName] = useState<string>(
    isNotNull(building) ? building.name : '',
  );
  const [type, setType] = useState<string>(
    isNotNull(building) ? building.type : '',
  );
  const [squareFootage, setSquareFootage] = useState<number>(
    isNotNull(building) ? building.squareFootage : 0,
  );
  const [address, setAddress] = useState<Address>(
    isNotNull(building)
      ? {
          addressOne: building.addressOne,
          addressTwo: building.addressTwo,
          city: building.city,
          country: building.country,
          location: { lat: building.location.lat, lng: building.location.lng },
          state: building.state,
          zipCode: building.zipCode,
        }
      : {
          addressOne: '',
          addressTwo: '',
          city: '',
          country: '',
          location: { lat: 0, lng: 0 },
          state: '',
          zipCode: '',
        },
  );

  useEffect(() => {
    if (isNotNull(building)) {
      setName(building.name);
      setType(building.type);
      setSquareFootage(building.squareFootage);
    }
  }, [building]);

  useEffect(() => {
    fetch(`${BUILDINGS_ENDPOINT}/types`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: BuildingTypes) => {
        setBuildingTypes(result);
      });
  }, []);

  const sendBuildingUpdate = useCallback(
    async (id: string, payload: BuildingPatch) => {
      if (!isSending) {
        setIsSending(true);
        fetch(`${BUILDINGS_ENDPOINT}/${id}`, {
          method: 'PATCH',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
          body: JSON.stringify(payload),
        }).then((res) => {
          setIsSending(false);
          CheckResponseShowError(res);
          setOpen(false);
        });
      }
    },
    [],
  );

  const validate = () => {
    if (R.isEmpty(name) || R.isNil(name)) {
      errors.name = 'Name is required.';
    }

    const addressErrors = Object.keys(FORM_FIELDS_MAP).reduce(
      (acc: AddressFormErrors, key: string) => {
        const label = FORM_FIELDS_MAP[key];
        const value = address[key];

        if (R.isEmpty(value) || R.isNil(value)) {
          return {
            ...acc,
            [key]: `${label} is required.`,
          };
        }

        return acc;
      },
      {},
    );

    setErrors({ ...errors, ...addressErrors });
  };

  const onCancel = () => {
    setOpen(false);
    setName(isNotNull(building) ? building.name : '');
    setAddress({
      addressOne: isNotNull(building) ? building.addressOne : '',
      addressTwo: isNotNull(building) ? building.addressTwo : '',
      city: isNotNull(building) ? building.city : '',
      country: isNotNull(building) ? building.country : '',
      location: {
        lat: isNotNull(building) ? building.location.lat : 0,
        lng: isNotNull(building) ? building.location.lng : 0,
      },
      state: isNotNull(building) ? building.state : '',
      zipCode: isNotNull(building) ? building.zipCode : '',
    });
    setErrors({});
  };

  const onSave = () => {
    validate();

    if (!R.isEmpty(errors)) {
      return;
    }

    const payload: BuildingPatch = {
      name,
      type,
      //squareFootage: squareFootage,
      ...address,
      updatedBy: userProfile.id,
    };

    if (isNotNull(building)) {
      sendBuildingUpdate(building.id, payload);
    }
  };

  const footer = (
    <>
      <Button onClick={onCancel}>Cancel</Button>
      <Button type="primary" onClick={onSave} loading={isSending}>
        Update
      </Button>
    </>
  );

  return (
    <Modal
      title="Update Building"
      open={open}
      onCancel={onCancel}
      footer={footer}
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="Building Name"
          colon={false}
          validateStatus={R.isNil(errors.name) ? 'success' : 'error'}
          help={R.isNil(errors.name) ? null : errors.name}
          style={styles.formItem}
        >
          <Input
            value={name}
            name="name"
            onChange={(event) => setName(event.target.value)}
          />
        </FormItem>
        <FormItem
          label="Building Type"
          colon={false}
          validateStatus="success"
          style={styles.formItem}
        >
          <Select
            value={type}
            onChange={(newValue: string) => setType(newValue)}
          >
            {buildingTypes.data.map((bt) => (
              <Option key={bt} value={bt}>
                {bt}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          label="Square Footage"
          colon={false}
          validateStatus="success"
          style={styles.formItem}
        >
          <InputNumber
            value={squareFootage}
            onChange={(newValue: number) => setSquareFootage(newValue)}
            min={0}
          />
        </FormItem>
      </Form>
      <AddressEntry
        value={address}
        onChange={setAddress}
        disabled={isSending}
        errors={errors}
      />
    </Modal>
  );
};

export default EditBuildingModal;
