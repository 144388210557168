import {
  BrandingPreference,
} from '@totem/types/organization';

import ibLogoLg from '../images/ib-logo.svg';
import ibLogoSm from '../images/intellinet_icon.svg';

const nav_logo_Small = ibLogoSm;
const nav_logo_Large = ibLogoLg;

export const staticBranding = BrandingPreference.INTELLIGENT_BUILDINGS;
export const staticLogoSmall = nav_logo_Small;
export const staticLogoLarge = nav_logo_Large;
export const staticLoginLogo = ibLogoSm;
export const staticAuthLoginLogo = ibLogoSm;
