import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CloseSquareOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Input, Modal, Radio, Row } from 'antd';

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import SoftwareContext from '@totem/components/software/software_report/softwareContext';
import { SoftwarePackage } from '@totem/components/software/software_report/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';
import UserProfileContext from '@totem/components/UserProfileContext';
import { isIBUser } from '@totem/utilities/security';
import { getUserRole } from '@totem/utilities/userUtilities';

const styles = {
  dialog: {
    maxHeight: '80%',
    maxWidth: '850px',
  },
  label: {
    fontSize: '12px',
    fontWeight: 600,
  },
};

const MergeDialog = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { selectedPackages, setPackageSelected, action, onAction } =
    useContext(SoftwareContext);
  const [mergedName, setMergedName] = useState<string>(
    typeof selectedPackages !== 'undefined' &&
      selectedPackages !== null &&
      selectedPackages.length > 0
      ? selectedPackages[0].id
      : '',
  );
  const [primaryPackage, setPrimaryPackage] = useState<SoftwarePackage>(
    typeof selectedPackages !== 'undefined' &&
      selectedPackages !== null &&
      selectedPackages.length > 0
      ? selectedPackages[0]
      : null,
  );
  const [createAlias, setCreateAlias] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const canAdmin = isIBUser(userProfile) && getUserRole(userProfile) > 2

  useEffect(() => {
    setMergedName(primaryPackage.name);
  }, [primaryPackage]);

  const sendMergeRequest = useCallback(async (payload: any) => {
    if (isSending) {
      return;
    }
    setIsSending(true);

    fetch(`${DEVICES_ENDPOINT}/software/catalog/merge`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(payload),
    }).then((res) => {
      CheckResponseShowError(res);
      setIsSending(false);
      onAction('clear_package_selections', null);
      onAction('refresh', null);
    });
  }, []);

  const handleSubmit = () => {
    if (mergedName !== '') {
      const payload = {
        primaryPackageID: primaryPackage.id,
        primaryPackageName: primaryPackage.name,
        aliasName: mergedName,
        mergePackages: selectedPackages.map((pkg) => pkg.id),
        createAlias,
      };
      sendMergeRequest(payload);
    } else {
      // Invalid Merged Name
    }
  };

  return (
    <Modal
      open={action === 'merge'}
      onCancel={() => onAction('', null)}
      title={<ModalTitle>Merge Packages</ModalTitle>}
      confirmLoading={false}
      style={styles.dialog}
      footer={
        <ModalFooter>
          <Button onClick={() => onAction('', null)}>Close</Button>
          <Button onClick={handleSubmit} type="primary" disabled={!canAdmin || isSending}>
            Submit
          </Button>
        </ModalFooter>
      }
    >
      <div style={styles.label}>Name the merged package:</div>
      <Input
        value={mergedName}
        onChange={({ target: { value } }) => setMergedName(value)}
      />

      <br />
      <br />

      <div style={styles.label}>
        Create alias so all new packages found that start with "{mergedName}"
        will be merged with this package?:
      </div>
      <Checkbox
        value={createAlias}
        onClick={() => setCreateAlias(!createAlias)}
      >
        Create Alias
      </Checkbox>

      <br />
      <br />

      <div style={styles.label}>
        Select the record that the rest will be merged into:
      </div>
      <Radio.Group value={primaryPackage}>
        {selectedPackages.map((pkg) => (
          <Row>
            <Col span={23}>
              <Radio value={pkg} onClick={() => setPrimaryPackage(pkg)}>
                {pkg.name}
              </Radio>
            </Col>
            <Col span={1}>
              <CloseSquareOutlined
                onClick={() => {
                  setPackageSelected(pkg, false);
                }}
              />
            </Col>
          </Row>
        ))}
      </Radio.Group>
    </Modal>
  );
};

export default MergeDialog;
