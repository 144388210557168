import React, { useContext } from 'react';
import { MergeCellsOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import SoftwareContext from '@totem/components/software/software_report/softwareContext';
import UserProfileContext from '@totem/components/UserProfileContext';
import { isIBUser } from '@totem/utilities/security';
import { getUserRole } from '@totem/utilities/userUtilities';

const styles = {
  button: {
    marginRight: '20px',
  },
};

export interface Props {
  isPrimary?: boolean;
}
const MergeButton = ({ isPrimary }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const { selectedPackages, action, onAction } = useContext(SoftwareContext);
  const canAdmin = isIBUser(userProfile) && getUserRole(userProfile) > 2;

  return (
    <Button
      style={styles.button}
      icon={<MergeCellsOutlined />}
      type={
        typeof isPrimary !== 'undefined' && isPrimary ? 'primary' : 'default'
      }
      disabled={
        !canAdmin ||
        action === 'merge' ||
        typeof selectedPackages === 'undefined' ||
        selectedPackages === null ||
        selectedPackages.length < 2
      }
      onClick={() => onAction('merge', null)}
    >
      Merge
    </Button>
  );
};

export default MergeButton;
