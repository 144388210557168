import React, { ReactNode, useEffect, useState } from 'react';

import {
  getDefaultEndDate,
  getDefaultStartDate,
} from '@totem/components/remoteAccess/sessions/RemoteAccessSessionContext';
import {
  RemoteAccessRole,
  RemoteAccessSessions,
  RemoteAccessUserDetailAction,
  RemoteAccessUserDetailsInput,
  RemoteAccessUserRoles,
  RemoteAccessUserSessionsRequest,
} from '@totem/components/remoteAccess/types';
import UserDetailsActionHandler from '@totem/components/remoteAccess/userDetails/ActionHandler';
import RemoteAccessUserDetailsContext from '@totem/components/remoteAccess/userDetails/RemoteAccessUserDetailsContext';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import {
  REMOTE_ACCESS_ROLES_ENDPOINT,
  REMOTE_ACCESS_USERS_ENDPOINT,
} from '@totem/utilities/endpoints';

import '../remoteAccess.css';

type Props = {
  userId: string;
  children?: ReactNode;
  refresh;
  onRecordTotalChanged;
  onDataRefreshRequested;
  actions?: RemoteAccessUserDetailAction[];
};

const RemoteAccessUserDetailsContainer = ({
  userId,
  children,
  onRecordTotalChanged,
  actions,
}: Props) => {
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [userEmail, setUserEmail] = useState<string>('');
  const [roleData, setRoleData] = useState<RemoteAccessUserRoles>(null);
  const [sessionData, setSessionData] = useState<RemoteAccessSessions>(null);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isSending, setIsSending] = useState(false);
  const [input, updateInput] = useState<RemoteAccessUserDetailsInput>({
    from: '',
    rolePageSize: 10,
    rolePage: 1,
    roleSortField: 'name',
    roleSortDirection: '1',
    sessionPageSize: 10,
    sessionPage: 1,
    sessionSortField: 'startTime',
    sessionSortDirection: '1',
    loading: false,
    startTime: getDefaultStartDate().toISOString(),
    endTime: getDefaultEndDate().toISOString(),
  });
  const [selectedRole, setSelectedRole] = useState<RemoteAccessRole>(null);
  const [selectedAction, setSelectedAction] = useState<string>('');

  const setInput = (updated: Partial<RemoteAccessUserDetailsInput>) => {
    updateInput({ ...input, ...updated });
    setRefreshData(true);
  };

  useEffect(() => {
    if (
      typeof onRecordTotalChanged !== 'undefined' &&
      onRecordTotalChanged !== null
    ) {
      onRecordTotalChanged(totalRecords);
    }
  }, [onRecordTotalChanged, totalRecords]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      if (isSending) {
        return;
      }
      const endpoint = `${REMOTE_ACCESS_ROLES_ENDPOINT}/${userId}`;

      fetch(`${endpoint}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: RemoteAccessUserRoles) => {
          setRoleData(result);
          if (isNotNull(result) && isNotNull(result.roles)) {
            setTotalRecords(result.roles.length);
            if (result.user !== null) {
              setUserEmail(result.user.email);
            }
          }
        })
        .then(() => {
          setIsSending(false);
        });

      setIsSending(true);
    }
  }, [refreshData, input]);

  useEffect(() => {
    if (userEmail !== '') {
      const payload: RemoteAccessUserSessionsRequest = {
        userEmail,
        startTime: input.startTime,
        endTime: input.endTime,
      };

      const endpoint = `${REMOTE_ACCESS_USERS_ENDPOINT}/userSessions`;

      fetch(`${endpoint}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((result: RemoteAccessSessions) => {
          setSessionData(result);
        });
    }
  }, [userEmail]);

  const handleAction = (action: string, role: RemoteAccessRole) => {
    let actionHandled = false;
    if (typeof actions !== 'undefined' && actions !== null) {
      for (let idx = 0; idx < actions.length; idx++) {
        if (actions[idx].name === action) {
          // eslint-disable-next-line max-depth
          if (actions[idx].replaceDefault === true) {
            actionHandled = true;
            actions[idx].action(action, role);
          }
        }
      }
    }
    if (!actionHandled) {
      setSelectedAction(action);
      setSelectedRole(role);
    }
  };

  const handleActionComplete = (doRefresh: boolean) => {
    setSelectedAction('');
    setSelectedRole(null);
    if (doRefresh) {
      setRefreshData(true);
    }
  };

  return (
    <RemoteAccessUserDetailsContext.Provider
      value={{
        userId,
        userEmail,
        input,
        setInput,
        roleData,
        sessionData,
        onAction: handleAction,
        onActionComplete: handleActionComplete,
      }}
    >
      <div>{children}</div>
      <UserDetailsActionHandler
        action={selectedAction}
        selectedRole={selectedRole}
      />
    </RemoteAccessUserDetailsContext.Provider>
  );
};

export default RemoteAccessUserDetailsContainer;
