import React, { useContext } from 'react';
import { Col, Flex, Row } from 'antd';
import colors from '@totem/styles/colors';
import DeviceHealthContext from '@totem/components/devices/health/deviceHealthContext';
import EventContext from '@totem/components/events/eventsContainer/eventContainerContext';
import { isNotNull, isNull } from '@totem/utilities/common';

const COLOR_RED: string = colors.event.criticality.background.critical;
const COLOR_ORANGE: string = colors.event.criticality.background.major;
const COLOR_YELLOW: string = colors.event.criticality.background.warning;
const COLOR_GREEN: string = colors.event.criticality.background.ok;

const styles = {
  titleCell: {
    backgroundColor: '#F2F2F2',
    fontSize: '16px',
    fontWeight: 600,
    paddingTop: '2rem',
    paddingBottom: '1rem',
    borderLeftColor: '#FFFFFF',
    borderLeftStyle: 'solid' as 'solid',
    borderLeftWidth: 4,
    borderRightColor: '#FFFFFF',
    borderRightStyle: 'solid' as 'solid',
    borderRightWidth: 4,
  },
  cell: {
    fontSize: '16px',
    backgroundColor: '#F2F2F2',
    borderTopColor: '#F2F2F2',
    borderTopStyle: 'solid' as 'solid',
    borderTopWidth: 4,
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  eventCell: {
    fontSize: '16px',
    backgroundColor: '#F2F2F2',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    borderLeftColor: '#FFFFFF',
    borderLeftStyle: 'solid' as 'solid',
    borderLeftWidth: 4,
    borderRightColor: '#FFFFFF',
    borderRightStyle: 'solid' as 'solid',
    borderRightWidth: 4,
    borderTopColor: '#FFFFFF',
    borderTopStyle: 'solid' as 'solid',
    borderTopWidth: 4,
  },
  leftBorder: {
    borderLeftColor: '#FFFFFF',
    borderLeftStyle: 'solid' as 'solid',
    borderLeftWidth: 4,
  },
  rightBorder: {
    borderRightColor: '#FFFFFF',
    borderRightStyle: 'solid' as 'solid',
    borderRightWidth: 4,
  }
};

const HeaderHealth = () => {
  const { data } = useContext(DeviceHealthContext);
  const { eventData } = useContext(EventContext);

  const criticalEvents = isNull(eventData)
    ? []
    : eventData.events.filter(
      (evt) => evt.severity === 5 && evt.status !== 'CLEARED',
    );
  const majorEvents = isNull(eventData)
    ? []
    : eventData.events.filter(
      (evt) => evt.severity === 4 && evt.status !== 'CLEARED',
    );
  const minorEvents = isNull(eventData)
    ? []
    : eventData.events.filter(
      (evt) => evt.severity === 3 && evt.status !== 'CLEARED',
    );
  const warningEvents = isNull(eventData)
    ? []
    : eventData.events.filter(
      (evt) => evt.severity === 2 && evt.status !== 'CLEARED',
    );

  return (
    <>
      {isNotNull(data) && (
        <>
          <Row>
            <Col span={4} style={styles.titleCell}>
              <Flex justify={'center'} align={'center'}>
                Status
              </Flex>
            </Col>
            <Col span={2} style={styles.titleCell}>
              <Flex justify={'center'} align={'center'}>
                Install Issues
              </Flex>
            </Col>
            <Col span={2} style={styles.titleCell}>
              <Flex justify={'center'} align={'center'}>
                Pending Reboot
              </Flex>
            </Col>
            <Col span={4} style={styles.titleCell}>
              <Flex justify={'center'} align={'center'}>
                OS Patches
              </Flex>
            </Col>
            <Col span={4} style={styles.titleCell}>
              <Flex justify={'center'} align={'center'}>
                Software Patches
              </Flex>
            </Col>
            <Col span={8} style={styles.titleCell}>
              <Flex justify={'center'} align={'center'}>
                Events
              </Flex>
            </Col>
          </Row>
          <Row>
            <Col span={4} style={{...styles.cell, ...styles.rightBorder}}>
              <Flex vertical justify={'end'} align={'center'}>
                <div style={{ color: data.offline ? COLOR_RED : COLOR_GREEN }}>
                  <b>{data.offline ? 'Offline' : 'Online'}</b>
                </div>
              </Flex>
            </Col>
            <Col span={2} style={{...styles.cell, ...styles.leftBorder, ...styles.rightBorder}}>
              <Flex vertical justify={'start'} align={'center'}>
                <div
                  style={{
                    color:
                      data.installationIssuesCount > 0
                        ? COLOR_RED
                        : COLOR_GREEN,
                  }}
                >
                  <b>{String(data.installationIssuesCount)}</b>
                </div>
              </Flex>
            </Col>
            <Col span={2} style={{...styles.cell, ...styles.leftBorder, ...styles.rightBorder}}>
              <Flex vertical justify={'start'} align={'center'}>
                <div
                  style={{
                    color:
                      data.pendingRebootReason === '' ? COLOR_GREEN : COLOR_RED,
                  }}
                >
                  <b>{data.pendingRebootReason !== '' ? 'Yes' : 'No'}</b>
                </div>
              </Flex>
            </Col>
            <Col span={2} style={{...styles.cell, ...styles.leftBorder}}>
              <Flex vertical justify={'start'} align={'center'}>
                <div>Pending</div>
                <div
                  style={{
                    color:
                      data.pendingOSPatchesCount > 0
                        ? COLOR_YELLOW
                        : COLOR_GREEN,
                  }}
                >
                  <b>{data.pendingOSPatchesCount}</b>
                </div>
              </Flex>
            </Col>
            <Col span={2} style={{...styles.cell, ...styles.rightBorder}}>
              <Flex vertical justify={'start'} align={'center'}>
                <div>Failed</div>
                <div
                  style={{
                    color:
                      data.failedOSPatchesCount > 0 ? COLOR_RED : COLOR_GREEN,
                  }}
                >
                  <b>{data.failedOSPatchesCount}</b>
                </div>
              </Flex>
            </Col>
            <Col span={2} style={{...styles.cell, ...styles.leftBorder}}>
              <Flex vertical justify={'start'} align={'center'}>
                <div>Pending</div>
                <div
                  style={{
                    color:
                      data.pendingSoftwarePatchesCount > 0
                        ? COLOR_YELLOW
                        : COLOR_GREEN,
                  }}
                >
                  <b>{data.pendingSoftwarePatchesCount}</b>
                </div>
              </Flex>
            </Col>
            <Col span={2} style={{...styles.cell, ...styles.rightBorder}}>
              <Flex vertical justify={'start'} align={'center'}>
                <div>Failed</div>
                <div
                  style={{
                    color:
                      data.failedSoftwarePatchesCount > 0
                        ? COLOR_RED
                        : COLOR_GREEN,
                  }}
                >
                  <b>{data.failedSoftwarePatchesCount}</b>
                </div>
              </Flex>
            </Col>
            <Col span={2} style={styles.eventCell}>
              <Flex vertical justify={'start'} align={'center'}>
                <div
                  style={{
                    color: criticalEvents.length === 0 ? COLOR_GREEN : '#FFFFFF',
                    backgroundColor: criticalEvents.length === 0 ? '#F2F2F2' : COLOR_RED,
                  }}
                >
                  Critical
                </div>
                <div
                  style={{
                    color: criticalEvents.length === 0 ? COLOR_GREEN : '#FFFFFF',
                    backgroundColor: criticalEvents.length === 0 ? '#F2F2F2' : COLOR_RED,
                  }}
                >
                  <b>{criticalEvents.length}</b>
                </div>
              </Flex>
            </Col>
            <Col span={2} style={styles.eventCell}>
              <Flex vertical justify={'start'} align={'center'}>
                <div
                  style={{
                    color: majorEvents.length === 0 ? COLOR_GREEN : '#FFFFFF',
                    backgroundColor: majorEvents.length === 0 ? '#F2F2F2' : COLOR_ORANGE,
                  }}
                >
                  Major
                </div>
                <div
                  style={{
                    color: majorEvents.length === 0 ? COLOR_GREEN : '#FFFFFF',
                    backgroundColor: majorEvents.length === 0 ? '#F2F2F2' : COLOR_ORANGE,
                  }}
                >
                  <b>{majorEvents.length}</b>
                </div>
              </Flex>
            </Col>
            <Col span={2} style={styles.eventCell}>
              <Flex vertical justify={'start'} align={'center'}>
                <div
                  style={{
                    color: minorEvents.length === 0 ? COLOR_GREEN : '#FFFFFF',
                    backgroundColor: minorEvents.length === 0 ? '#F2F2F2' : colors.event.criticality.background.minor,
                  }}
                >
                  Minor
                </div>
                <div
                  style={{
                    color: minorEvents.length === 0 ? COLOR_GREEN : '#FFFFFF',
                    backgroundColor: minorEvents.length === 0 ? '#F2F2F2' : colors.event.criticality.background.minor,
                  }}
                >
                  <b>{minorEvents.length}</b>
                </div>
              </Flex>
            </Col>
            <Col span={2} style={styles.eventCell}>
              <Flex vertical justify={'start'} align={'center'}>
                <div
                  style={{
                    color: warningEvents.length === 0 ? COLOR_GREEN : '#FFFFFF',
                    backgroundColor: warningEvents.length === 0 ? '#F2F2F2' : colors.event.criticality.background.warning,
                  }}
                >
                  Warning
                </div>
                <div
                  style={{
                    color: warningEvents.length === 0 ? COLOR_GREEN : '#FFFFFF',
                    backgroundColor: warningEvents.length === 0 ? '#F2F2F2' : colors.event.criticality.background.warning,
                  }}
                >
                  <b>{warningEvents.length}</b>
                </div>
              </Flex>
            </Col>
          </Row>
        </>
      )}
    </>
  );

};

export default HeaderHealth;
