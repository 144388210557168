import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card } from 'antd';

import DetailSpinner from '@totem/components/common/DetailSpinner';
import CriticalityFindings from '@totem/components/common/findings/CriticalityFindings';
import GroupFindings from '@totem/components/common/findings/GroupFindings';
import LevelOfEffortFindings from '@totem/components/common/findings/LevelOfEffortFindings';
import Grade from '@totem/components/common/Grade';
import PolicyAuditFindingsExport from '@totem/components/common/policyAuditFindingsExport/PolicyAuditFindingsExport';
import colors from '@totem/styles/colors';
import {
  Criticality,
} from '@totem/types/criticality';
import { FindingsConnectionInput } from '@totem/types/finding';
import { LevelOfEffort } from '@totem/types/levelOfEffort';
import authUtilities, { isVendorUser } from '@totem/utilities/authUtilities';
import ContentLayout from '../ContentLayout';

import FindingsTable from './FindingsTable';
import PercentageBar from './PercentageBar';

import './policyAuditDetail.css';
import { PolicyAuditDetail } from '@totem/components/surveyV2/policyAuditDetails/types';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull, isNull } from '@totem/utilities/common';
import UserProfileContext from '@totem/components/UserProfileContext';

export interface Filters {
  criticality: Criticality[];
  group: string[];
  levelOfEffort: LevelOfEffort[];
}

type ScoreType = 'group' | 'loe';

const styles = {
  card: {
    flex: 1,
    margin: '0rem 1.6rem 1rem 1.6rem',
  },
  cardHeader: {
    borderTop: `5px solid`,
    borderBottom: `1px solid ${colors.antd.borderGray}`,
    borderImage: `${colors.gradient.blue} 100% 0 0 0`,
  },
  cardBody: {
    borderTop: `1px solid ${colors.antd.borderGray}`,
  },
  grade: {
    display: 'inline-flex',
  },
};

const initialFilters: Filters = {
  criticality: [],
  group: [],
  levelOfEffort: [],
};

export const getFindingsConnectionInput = (
  id: string,
  { criticality, group, levelOfEffort }: Filters,
): FindingsConnectionInput => {
  return {
    limit: null,
    offset: null,
    policyAuditId: [id],
    criticality,
    group,
    levelOfEffort,
  };
};

export const renderScore = (
  title: string,
  score: number,
) => {
  return (
    <div styleName="percentage-container">
      <div styleName={'group-score'}>
        {title}
      </div>
      <PercentageBar score={score} />
      <Grade score={score} size="small" />
    </div>
  );
};

const Report = () => {
  const { userProfile } = useContext(UserProfileContext);
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState<PolicyAuditDetail>(null);
  const [loading, setLoading] = useState(true);

  const [isMinimalModeledScoreView, setMinimalModeledScoreView] =
    useState<boolean>(false);

  const [filters, setFilters] = useState<Filters>(initialFilters);
  const table = useRef();

  const score = isNotNull(data) && data.policyAudit.maxScore > 0 ? (data.policyAudit.totalScore / data.policyAudit.maxScore) * 100 : 0

  useEffect(() => {
    setLoading(true);

    fetch(`${V2_SURVEY_ENDPOINT}/policyAuditDetails/${id}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: PolicyAuditDetail) => {
        setData(result);
      })
      .then(() => {
        setLoading(false);
      });
  }, []);

  const renderCategoryScore = (category: string) => {
    const findings = data.policyAudit.findings.filter((chk) => chk.category === category);
    const totalScore = findings.reduce((total, finding) => total + finding.questionScore, 0)
    const maxScore = findings.reduce((total, finding) => total + finding.questionMaxScore, 0)
    const calculated = maxScore > 0 ? (totalScore / maxScore) * 100 : 0
    return renderScore(category, calculated);
  }

  const renderLevelOfEffortScore = (levelOfEffort: string) => {
    let loe = 0;
    switch (levelOfEffort) {
      case 'Minimal':
        loe = 1
        break;
      case 'Moderate':
        loe = 2
        break;
      case 'Maximum':
        loe = 3
        break;
    }

    const findings = data.policyAudit.findings.filter((chk) => chk.levelOfEffort === loe);
    const totalScore = findings.reduce((total, finding) => total + finding.questionScore, 0)
    const maxScore = findings.reduce((total, finding) => total + finding.questionMaxScore, 0)
    const calculated = maxScore > 0 ? (totalScore / maxScore) * 100 : 0
    console.log(levelOfEffort + "(" + loe + "): " + findings.length + " (" + totalScore + " / " + maxScore + ")");
    return renderScore(levelOfEffort, calculated);
  }

  const handleFiltersChange = (updatedFilters: Filters) => {
    setMinimalModeledScoreView(false);
    setFilters(updatedFilters);
  };

  if (loading) {
    return (
      <div styleName="spinner-container">
        <DetailSpinner />
      </div>
    );
  }

  const handleUpdateAudit = () => {
    const endpoint = authUtilities.isVendorUser()
      ? '/vendor-dashboard'
      : '/dashboard';

    if (data.policyAudit.source === 'policyAudit') {
      navigate(`${endpoint}/policyaudits/${data.policyAudit.id}`);
    } else {
      navigate(`${endpoint}/surveyinstances/${data.policyAudit.id}`);
    }
  };

  // embedded systems will have no control system host findings
  const hasControlSystemHostFindings = isNotNull(data) ? data.policyAudit.findings.some(
    ({ category }) => category === 'Control System Host',
  ) : false;

  return (
    <ContentLayout
      pageTitle={`${isNotNull(data) ? data.controlSystem.name : 'Policy Audit'} Report`}
      pageSubtitle={[
        {
          label: userProfile.organization.name,
        },
        {
          label: isNotNull(data) && isNotNull(data.region) ? data.region.name : '',
        },
        {
          label: isNotNull(data) && isNotNull(data.building) ? data.building.name : '',
          link: isVendorUser() || isNull(data) || isNull(data.building)
            ? ''
            : `/dashboard/buildings/${data.building.id}`,
        },
      ]}
      contentRight={
        <Button type="primary" onClick={handleUpdateAudit}>
          Update Audit Responses
        </Button>
      }
      breadcrumbs={(breadcrumbs) => [
        ...breadcrumbs,
        {
          label: data?.controlSystem?.name,
          link: isVendorUser()
            ? ''
            : `/dashboard/controlsystems/${data?.controlSystem.id}`,
        },
        {
          label: 'Audit Report',
        },
      ]}
    >
      <>
        <div styleName="base-pane">
          <div styleName="card-container">
            <Card
              bordered
              styles={{body: styles.cardBody, header: styles.cardHeader}}
              title={
                <div styleName="card-header-container">
                  <div styleName="card-header-title">Score Summary</div>
                  <div styleName="grade-container">
                    <div styleName="overall-grade">{`overall - ${score.toFixed(0)}%`}</div>
                    <Grade score={score} />
                  </div>
                </div>
              }
              style={styles.card}
            >
              {renderCategoryScore('System Users')}
              {renderCategoryScore('System Configuration')}
              {renderCategoryScore('Network')}
              {renderCategoryScore('Continuity')}
              {hasControlSystemHostFindings &&
                renderCategoryScore('Control System Host')}
            </Card>
            <Card
              bordered
              headStyle={styles.cardHeader}
              title={
                <div styleName="card-header-title">
                  Score By Level Of Effort
                </div>
              }
              style={styles.card}
            >
              {renderLevelOfEffortScore('Minimal')}
              {renderLevelOfEffortScore('Moderate')}
              {renderLevelOfEffortScore('Maximum')}
            </Card>
          </div>
          <Card
            bordered
            styles={{body: styles.cardBody, header: styles.cardHeader}}
            title={
              <div styleName="card-header-title">
                {isMinimalModeledScoreView
                  ? 'Audit Findings'
                  : 'Audit Findings Overview'}
              </div>
            }
          >
            {!isMinimalModeledScoreView && (
              <>
                <div styleName="findings-container">
                  <div styleName="finding">
                    <GroupFindings findings={data?.policyAudit?.findings} />
                  </div>
                  <div styleName="finding">
                    <CriticalityFindings findings={data?.policyAudit?.findings} />
                  </div>
                  <div styleName="finding">
                    <LevelOfEffortFindings findings={data?.policyAudit?.findings} />
                  </div>
                </div>
                <div styleName="findings-title-container">Audit Findings</div>
              </>
            )}
            <div styleName="findings-table-container" ref={table}>
              <div styleName="table-buttons-container">
                <Button
                  onClick={() => {
                    setMinimalModeledScoreView(false);
                    setFilters(initialFilters);
                  }}
                >
                  Reset Filters
                </Button>
                <PolicyAuditFindingsExport
                  input={getFindingsConnectionInput(id, filters)}
                />
              </div>
              <FindingsTable
                findings={data?.policyAudit?.findings}
                filters={filters}
                onFilterChange={handleFiltersChange}
              />
            </div>
          </Card>
        </div>
      </>
    </ContentLayout>
  );
};

export default Report;
