import React from 'react';

import Count from '@totem/components/common/Count';
import colors from '@totem/styles/colors';
import { Criticality } from '@totem/types/criticality';
import { Finding } from '@totem/components/surveyV2/policyAuditDetails/types';
import { LevelOfEffort } from '@totem/types/levelOfEffort';
import { getExactPercentage } from '@totem/utilities/numberUtilities';

import './findings.css';

const INCLUDED_LEVELS_OF_EFFORT = [
  LevelOfEffort.Minimal,
  LevelOfEffort.Moderate,
  LevelOfEffort.Maximum,
];

const NON_COMPLIANT_CRITICALITIES = [
  Criticality.Critical,
  Criticality.High,
  Criticality.Medium,
  Criticality.Low,
];

const getIncludedFindings = (findings: Finding[]) =>
  findings.filter(
    ({ criticality, levelOfEffort }) =>
      NON_COMPLIANT_CRITICALITIES.includes(criticality) &&
      INCLUDED_LEVELS_OF_EFFORT.includes(levelOfEffort),
  );

const getFindingsByLevelOfEffort = (
  findings: Finding[],
  loe: LevelOfEffort,
): Finding[] => {
  return findings.filter(finding => finding.levelOfEffort === loe);
};

interface Props {
  findings: Finding[];
}

const LevelOfEffortFindings: React.FC<Props> = ({ findings }: Props) => {
  const includedFindings = getIncludedFindings(findings);

  return (
    <div styleName="findings-container">
      <div styleName="finding-header-container">
        <div styleName="finding-header">Findings By Level Of Effort</div>
      </div>
      <div>
        {INCLUDED_LEVELS_OF_EFFORT.map(loe => {
          const name = LevelOfEffort[loe];

          const findingsWithLoe = getFindingsByLevelOfEffort(
            includedFindings,
            loe,
          );

          const percentage = getExactPercentage(
            includedFindings.length,
            findingsWithLoe.length,
          );

          return (
            <div styleName="findings" key={name}>
              <div styleName="finding-label level-of-effort">{name}</div>
              <div styleName="finding-bar-container">
                <div styleName="finding-bar">
                  <div
                    style={{
                      width: `${percentage}%`,
                      background: colors.neutral.gray,
                      height: '100%',
                    }}
                  />
                </div>
              </div>
              <Count count={findingsWithLoe.length} size="small" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LevelOfEffortFindings;
