import React, { useContext, useState } from 'react';
import { Tabs, TabsProps } from 'antd';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import DeviceContainer from '@totem/components/devices/devicesContainer/DeviceContainer';
import DeviceContainerTable from '@totem/components/devices/devicesContainer/DeviceContainerTable';
import DeviceExport from '@totem/components/devices/devicesContainer/DeviceExport';
import DeviceFilters from '@totem/components/devices/devicesContainer/DeviceFilters';
import DeviceSearch from '@totem/components/devices/devicesContainer/DeviceSearch';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import RecordCountBadge from '@totem/components/RecordCountBadge';
import TabTitle from '@totem/components/TabTitle';
import UserProfileContext from '@totem/components/UserProfileContext';
import { getUserRole } from '@totem/utilities/userUtilities';
import MaintenanceWindowButton from '@totem/components/devices/devicesContainer/MaintenanceWindowButton';

const styles = {
  totals: {
    fontSize: '16px',
    marginRight: '20px',
  },
  tabContainer: {
    paddingRight: '2rem',
    paddingLeft: '2rem',
  },
};

const DevicesPage = () => {
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const { userProfile } = useContext(UserProfileContext);
  const isSysAdmin = getUserRole(userProfile) === 3;
  const canCreateMaintenanceWindows = getUserRole(userProfile) >= 1;

  const getTabItems = () => {
    const tabItems: TabsProps['items'] = [
      {
        key: 'details',
        label: <TabTitle>Details</TabTitle>,
        children: (
          <>
            <DeviceSearch />
            <br />
            <DeviceContainerTable showMultiselect={canCreateMaintenanceWindows} />
          </>
        ),
      },
    ];
    return tabItems;
  };

  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <DeviceContainer onRecordTotalChanged={setTotalRecords}>
            <DeviceFilters />
            <Tabs
              defaultActiveKey="Report"
              tabBarExtraContent={
                <span>
                  <RecordCountBadge count={totalRecords} />
                  {isSysAdmin && <>&nbsp;&nbsp;&nbsp;<MaintenanceWindowButton /></>}
                  {isSysAdmin && <>&nbsp;&nbsp;&nbsp;<DeviceExport /></>}
                </span>
              }
              style={styles.tabContainer}
              items={getTabItems()}
            />
          </DeviceContainer>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default DevicesPage;
