import React from 'react';
import { useParams } from 'react-router-dom';
import ControlSystemContainer from '@totem/components/controlSystemDetail/container/controlSystemContainer';

import './controlSystemDetail.css';
import ControlSystemDetailContent from '@totem/components/controlSystemDetail/ControlSystemDetailContent';

const ControlSystemDetail = () => {
  const { id } = useParams();

  return (
    <ControlSystemContainer id={id} onDataRefreshRequested={() => {}}>
      <ControlSystemDetailContent />
    </ControlSystemContainer>
  );
};

export default ControlSystemDetail;
