import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, notification, Select } from 'antd';
import * as R from 'ramda';

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { User } from '@totem/components/users/container/types';
import { UserUpdateInput } from '@totem/types/user';
import {
  getToken,
  USER_ROLES,
} from '@totem/utilities/accountUtilities';
import authUtilities from '@totem/utilities/authUtilities';
import { validateEmail } from '@totem/utilities/validation';
import { RequestUserCreate } from '@totem/components/users/types';
import UserProfileContext from '@totem/components/UserProfileContext';
import { USERS_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

const FormItem = Form.Item;
const { Option } = Select;

interface FormErrors {
  email?: string;
  firstName?: string;
  lastName?: string;
}

export interface Props {
  user: User;
  open: boolean;
  onClose: () => void;
}

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

export const validateForm = (input: UserUpdateInput): FormErrors => {
  const formErrors: FormErrors = {};

  if (R.isEmpty(input.email)) {
    formErrors.email = 'Please enter an email';
  }

  if (input.email && !validateEmail(input.email)) {
    formErrors.email = 'Please enter a valid email';
  }

  if (R.isEmpty(input.firstName)) {
    formErrors.firstName = 'Please enter a first name';
  }

  if (R.isEmpty(input.lastName)) {
    formErrors.lastName = 'Please enter a last name';
  }

  return formErrors;
};

export const mapUserToInput = (user: User): UserUpdateInput => ({
  id: user.id,
  role: user.organization.role,
  email: user.email,
  firstName: user.firstName,
  lastName: user.lastName,
});

const UserUpdateModal = ({ user, open, onClose }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const [sending, setSending] = useState(false);
  const [input, setInput] = useState<UserUpdateInput>(mapUserToInput(user));
  const [errors, setErrors] = useState<FormErrors>({});

  useEffect(() => {
    if (!open) {
      setInput(mapUserToInput(user));
      setErrors({});
    }
  }, [open, user]);

  const handleChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [name]: value });
  };

  const sendUserUpdate = useCallback(async (request: RequestUserCreate) => {
    if (!sending) {
      setSending(true);
      fetch(`${USERS_ENDPOINT}/create`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request),
      })
        .then(res => {
          setSending(false);
          CheckResponseShowError(res);
          onClose();
        });
    }
  }, [])

  const handleSubmit = async () => {
    const formErrors = validateForm(input);

    if (R.isEmpty(formErrors)) {
      const payload: RequestUserCreate = {
        organizationId: userProfile.organization.id,
        firstName: input.firstName,
        lastName: input.lastName,
        email: input.email,
        role: input.role,
        sendNotifications: false,
      };

      sendUserUpdate(payload);
    } else {
      setErrors(formErrors);
    }
  };

  const userHasLoggedIn = Boolean(user.lastLoginDate);
  // TODO: Remove @ts-ignore : Token
  // @ts-ignore
  const isOwnUser = user.id === userProfile.id;

  const isAdmin = authUtilities.minimumRequiredRole(
    authUtilities.ROLE_SETS.ORGANIZATION_ADMINISTRATOR,
  );
  const isSysAdmin = authUtilities.minimumRequiredRole(
    authUtilities.ROLE_SETS.SYSTEMS_ADMINISTRATOR,
  );

  const handleRoleSelected = (role: number) => {
    if (role > 2 && isAdmin && !isSysAdmin) {
      notification.error({
        message: 'Permissions Error!',
        description:
          'Administrators cannot grant the System Administrator role!',
        duration: 300,
      });
    } else {
      setInput({ ...input, role });
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={<ModalTitle>Update User</ModalTitle>}
      confirmLoading={sending}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} type="primary" loading={sending}>
            Update
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem label="Role" colon={false} style={styles.formItem}>
          <Select
            onChange={(role) => handleRoleSelected(role)}
            value={input.role}
            disabled={!isAdmin || isOwnUser}
          >
            {USER_ROLES.filter((role) => {
              // TODO: Remove @ts-ignore : Token
              // @ts-ignore
              return role.value <= userProfile.organization.role;
            }).map((role) => (
              <Option key={role.value} value={role.value}>
                {role.label}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          label="Email"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.email) ? 'success' : 'error'}
          help={R.isNil(errors.email) ? null : errors.email}
        >
          <Input
            disabled={!isAdmin || userHasLoggedIn}
            name="email"
            value={input.email}
            onChange={handleChange}
            type="search"
          />
        </FormItem>
        <FormItem
          label="First Name"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.firstName) ? 'success' : 'error'}
          help={R.isNil(errors.firstName) ? null : errors.firstName}
        >
          <Input
            name="firstName"
            value={input.firstName}
            onChange={handleChange}
            disabled={!isOwnUser && userHasLoggedIn}
            type="search"
          />
        </FormItem>
        <FormItem
          label="Last Name"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.lastName) ? 'success' : 'error'}
          help={R.isNil(errors.lastName) ? null : errors.lastName}
        >
          <Input
            name="lastName"
            value={input.lastName}
            onChange={handleChange}
            disabled={!isOwnUser && userHasLoggedIn}
            type="search"
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default UserUpdateModal;
