import React from 'react';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import Table from '@totem/components/common/table/Table';
import RelatedTicketDeleteIcon from '@totem/components/ticketing/details/relatedTicketDeleteIcon';
import { RelatedTicket, TicketQueryResult } from '@totem/types/ticketing';
import { formatDate, isNotNull, isNull } from '@totem/utilities/common';

type Props = {
  ticket: TicketQueryResult;
  refetch: () => void;
};

const RelatedTicketsTable = ({ ticket, refetch }: Props) => {
  const tickets =
    isNotNull(ticket) && isNotNull(ticket.relatedTickets)
      ? ticket.relatedTickets
      : [];

  const getControlSystemAlternate = (checkTicket: TicketQueryResult) => {
    if (
      typeof checkTicket !== 'undefined' &&
      checkTicket !== null &&
      checkTicket.ticket.attributes !== null
    ) {
      for (let idx = 0; idx < checkTicket.ticket.attributes.length; idx++) {
        const attribute = checkTicket.ticket.attributes[idx];
        if (attribute.name === 'ControlSystemType') {
          return attribute.value;
        }
      }
    }
    return '';
  };

  const getControlSystemDisplay = (checkTicket: TicketQueryResult) => {
    if (
      typeof checkTicket !== 'undefined' &&
      checkTicket !== null &&
      checkTicket.controlSystem !== null &&
      checkTicket.controlSystem.id !== null &&
      checkTicket.controlSystem.id !== EMPTY_ID
    ) {
      return <span>{checkTicket.controlSystem.name}</span>;
    } else if (
      checkTicket.controlSystem !== null &&
      checkTicket.controlSystem.name !== ''
    ) {
      return <span>{checkTicket.controlSystem.name}</span>;
    }

    return <span>{getControlSystemAlternate(checkTicket)}</span>;
  };

  const columns: ColumnProps<RelatedTicket>[] = [
    {
      title: 'Relationship',
      dataIndex: 'relationship',
      key: 'relationship',
      render: (_, record: RelatedTicket) => record.relationship,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.relationship === compB.relationship
          ? 0
          : compA.relationship > compB.relationship
            ? 1
            : -1,
    },
    {
      title: 'Number',
      dataIndex: 'ticket.number',
      key: 'type',
      render: (_, record: RelatedTicket) => (
        <Link to={`/dashboard/ticket/${record.ticket.ticket.id}`}>
          {record.ticket.ticket.number}
        </Link>
      ),
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.ticket.number === compB.ticket.ticket.number
          ? 0
          : compA.ticket.ticket.number > compB.ticket.ticket.number
            ? 1
            : -1,
    },
    {
      title: 'Category',
      dataIndex: 'ticket.category',
      key: 'category',
      render: (_, record: RelatedTicket) => record.ticket.ticket.category,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.ticket.category === compB.ticket.ticket.category
          ? 0
          : compA.ticket.ticket.category > compB.ticket.ticket.category
            ? 1
            : -1,
    },
    {
      title: 'Building',
      dataIndex: 'building.name',
      key: 'buildingId',
      render: (_, record: RelatedTicket) => record.ticket.building.name,
    },
    {
      title: 'Control System',
      dataIndex: 'controlSystem.name',
      key: 'controlSystemId',
      render: (_, record: RelatedTicket) =>
        getControlSystemDisplay(record.ticket),
    },
    {
      title: 'Summary',
      dataIndex: 'ticket.summary',
      key: 'summary',
      render: (_, record: RelatedTicket) => record.ticket.ticket.summary,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.ticket.summary === compB.ticket.ticket.summary
          ? 0
          : compA.ticket.ticket.summary > compB.ticket.ticket.summary
            ? 1
            : -1,
    },
    {
      title: 'Priority',
      dataIndex: 'ticket.priority',
      key: 'priority',
      render: (_, record: RelatedTicket) => record.ticket.ticket.priority,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.ticket.priority === compB.ticket.ticket.priority
          ? 0
          : compA.ticket.ticket.priority > compB.ticket.ticket.priority
            ? 1
            : -1,
    },
    {
      title: 'Status',
      dataIndex: 'ticket.status',
      key: 'status',
      render: (_, record: RelatedTicket) => record.ticket.ticket.status,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.ticket.status === compB.ticket.ticket.status
          ? 0
          : compA.ticket.ticket.status > compB.ticket.ticket.status
            ? 1
            : -1,
    },
    {
      title: 'Created',
      dataIndex: 'ticket.createdAt',
      key: 'createdAt',
      render: (_, record: RelatedTicket) =>
        formatDate(record.ticket.ticket.createdAt),
      sorter: (compA, compB) =>
        compA.ticket.ticket.createdAt === compB.ticket.ticket.createdAt
          ? 0
          : compA.ticket.ticket.createdAt > compB.ticket.ticket.createdAt
            ? 1
            : -1,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: '',
      dataIndex: 'ticket.id',
      key: 'actions',
      render: (_, record: RelatedTicket) => (
        <RelatedTicketDeleteIcon
          ticketId={ticket.ticket.id}
          relatedTicket={record}
          refetch={refetch}
        />
      ),
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={tickets}
      loading={isNull(ticket)}
      rowKey={(record) => record.ticket.ticket.id}
    />
  );
};

export default RelatedTicketsTable;
