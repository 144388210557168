import React, { useMemo } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnProps, TablePaginationConfig } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import Grade from '@totem/components/common/Grade';
import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import {
  AddEditRegion,
  RegionsConnectionInput,
} from '@totem/types/region';
import authUtilities from '@totem/utilities/authUtilities';

import './regions.css';
import { RegionSummaryItem } from '@totem/components/regions/v2/types';
import { isNotNull } from '@totem/utilities/common';

export interface Props {
  regions: RegionSummaryItem[];
  pagination: TablePaginationConfig;
  loading: boolean;
  input: RegionsConnectionInput;
  setInput: (input: RegionsConnectionInput) => void;
  onEditRegionClick: (region: AddEditRegion) => void;
  onDeleteRegionClick: (region: AddEditRegion) => void;
  onChange: (
    pagination: TablePaginationConfig,
    sort: SorterResult<AddEditRegion>,
  ) => void;
}

const RegionsTable = ({
  regions,
  pagination,
  loading,
  input,
  onEditRegionClick,
  onDeleteRegionClick,
  onChange,
}: Props) => {
  // eslint-disable-next-line no-shadow
  const handleChange = (pagination, filter, sorter) => {
    onChange(pagination, sorter);
  };

  const columns: ColumnProps<RegionSummaryItem>[] = [
    {
      title: 'Region Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      sorter: true,
      // @ts-ignore
      sortOrder: input.sortBy === 'name' && input.sortOrder,
    },
    {
      title: 'Buildings',
      key: 'buildings',
      width: 150,
      render: (record: RegionSummaryItem) => String(record.buildings),
    },
    {
      title: 'Control Systems',
      key: 'controlSystems',
      width: 100,
      render: (record: RegionSummaryItem) => String(record.controlSystems),
    },
    {
      title: 'Score',
      key: 'policyAudits',
      width: 100,
      align: 'center',
      render: (record: RegionSummaryItem) => {
        return (
          isNotNull(record.policyAudits) && record.policyAudits.count > 0 && record.policyAudits.maxScore > 0 && (
            <Grade
              score={(record.policyAudits.totalScore / record.policyAudits.maxScore) * 100}
              size="small"
              style={{ margin: '0 auto' }}
            />
          )
        );
      },
    },
    {
      title: 'Actions',
      width: 50,
      key: 'additionalOptions',
      align: 'center',
      render: (record: RegionSummaryItem) => {
        const disabled = !authUtilities.minimumRequiredRole(
          authUtilities.ROLE_SETS.TEAM_MEMBER,
        );

        return (
          <div className="center-table-cell">
            <Tooltip title="Edit" placement="top">
              <Button
                styleName="edit-button"
                disabled={disabled}
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => onEditRegionClick(record)}
              />
            </Tooltip>
            <Tooltip title="Delete" placement="top">
              <Button
                disabled={disabled}
                type="default"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => onDeleteRegionClick(record)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return (
    <Table
      rowKey="id"
      columns={columns}
      loading={loading}
      dataSource={isNotNull(regions) ? regions : []}
      pagination={pagination}
      onChange={handleChange}
    />
  );
};

export default RegionsTable;
