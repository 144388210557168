import React, { useContext, useState } from 'react';
import { Button } from 'antd';

import MergeTicketsDialog from '@totem/components/ticketing/listContainer/MergeTicketsDialog';
import TicketingContext from '@totem/components/ticketing/listContainer/ticketingContainerContext';
import UserProfileContext from '@totem/components/UserProfileContext';
import { isNotNull, isNull } from '@totem/utilities/common';
import {
  getUserRole,
  isMemberOf,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

const MergeTicketsButton = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { selectedTickets } = useContext(TicketingContext);
  const [showDialog, setShowDialog] = useState(false);
  const isTicketAdmin =
    isMemberOf(
      userProfile,
      'ticket_administrator',
      securityGroupCheckConstraintEmpty,
    ) || getUserRole(userProfile) > 2;

  const title =
    isNotNull(selectedTickets) && selectedTickets.length > 0
      ? `Merge (${selectedTickets.length})`
      : 'Merge';

  return (
    <>
      <Button
        disabled={
          showDialog ||
          !isTicketAdmin ||
          isNull(selectedTickets) ||
          selectedTickets.length === 0
        }
        onClick={() => {
          setShowDialog(true);
        }}
      >
        {title}
      </Button>
      <MergeTicketsDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
      />
    </>
  );
};

export default MergeTicketsButton;
