import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SolutionOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import RemoteAccessRoleAddModal from '@totem/components/common/remoteAccess/panel/RemoteAccessRoleAddModal';
import RemoteAccessRoleTable from '@totem/components/common/remoteAccess/panel/RemoteAccessRoleTable';
import ComponentHeader from '@totem/components/ComponentHeader';
import { RemoteAccessOrgRole } from '@totem/components/remoteAccess/types';
import UserProfileContext from '@totem/components/UserProfileContext';
import colors from '@totem/styles/colors';
import { RemoteAccessAuthTenantRole } from '@totem/types/remoteAccess';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { REMOTE_ACCESS_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';
import {
  emptyId,
  isMemberOf,
  normalizeId,
} from '@totem/utilities/userUtilities';

import './remoteAccessRoles.css';

export interface Props {
  regionId: string;
  buildingId: string;
  controlSystemId?: string;
  buildingName: string;
  controlSystemName?: string;
}

const styles = {
  card: {
    width: '100%',
  },
  cardHeader: {
    borderTop: `5px solid`,
    borderImage: `${colors.gradient.turquoise} 100% 0 0 0`,
  },
  cardBody: {
    padding: '2.4rem',
    paddingBottom: '0',
    borderTop: `1px solid ${colors.antd.borderGray}`,
  },
  icon: {
    fontSize: '4rem',
    color: colors.neutral.silver,
  },
  errorIcon: {
    color: colors.utility.error,
  },
};

const RemoteAccessRoles = (props: Props) => {
  const {
    regionId,
    buildingId,
    controlSystemId,
    buildingName,
    controlSystemName,
  } = props;
  const { userProfile } = useContext(UserProfileContext);

  const [showCreateRoleModal, setShowCreateRoleModal] =
    useState<boolean>(false);
  const [orgRoles, setOrgRoles] = useState<RemoteAccessOrgRole[]>([]);

  useEffect(() => {
    fetch(`${REMOTE_ACCESS_ENDPOINT}/organization/roles`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: RemoteAccessOrgRole[]) => {
        setOrgRoles(result);
      });
  }, [props]);

  const sendCreateOrganizationRole = useCallback(async (payload: any) => {
    fetch(`${REMOTE_ACCESS_ENDPOINT}/organization/roles`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(payload),
    }).then((res) => {
      const success = CheckResponseShowError(res);
      if (success) {
        setShowCreateRoleModal(false);
      }
    });
  }, []);

  const remoteAccessConfigAdmin = isMemberOf(
    userProfile,
    'remote_access_configuration_admin',
    {
      regionId,
      buildingId,
      controlSystemId: normalizeId(controlSystemId),
    },
  );

  const getObjectName = () => {
    if (normalizeId(controlSystemId) === emptyId) {
      return buildingName;
    }
    return controlSystemName;
  };

  const getQualifiedRoles = () => {
    if (isNotNull(orgRoles)) {
      if (normalizeId(controlSystemId) === emptyId) {
        return orgRoles.filter((role) => role.buildingId === buildingId);
      }
      return orgRoles.filter(
        (role) =>
          role.buildingId === buildingId &&
          role.controlSystemId === controlSystemId,
      );
    }

    return [];
  };

  const qualifiedRoles = getQualifiedRoles();

  const handleClose = () => {
    setShowCreateRoleModal(false);
  };

  const handleCreate = (role: RemoteAccessAuthTenantRole) => {
    const payload = {
      roleName: role.name,
      roleDescription: role.description,
      regionId,
      buildingId,
      controlSystemId: normalizeId(controlSystemId),
    };

    sendCreateOrganizationRole(payload);
  };

  return (
    <>
      <ComponentHeader
        title={'Remote Access Roles'}
        options={
          remoteAccessConfigAdmin && (
            <Button type="primary" onClick={() => setShowCreateRoleModal(true)}>
              Add Role
            </Button>
          )
        }
      />
      <div styleName="table-container">
        {qualifiedRoles.length === 0 ? (
          <div styleName="no-system-container">
            <SolutionOutlined style={styles.icon} />
            <div styleName="no-system-subtext">
              No remote access roles are currently assigned to this building.
            </div>
          </div>
        ) : (
          <RemoteAccessRoleTable
            roles={qualifiedRoles}
            regionId={regionId}
            buildingId={buildingId}
            buildingName={buildingName}
            controlSystemId={controlSystemId}
            controlSystemName={controlSystemName}
          />
        )}
      </div>
      <RemoteAccessRoleAddModal
        title={`New remote access role for ${getObjectName()}`}
        visible={showCreateRoleModal}
        loading={false}
        onClose={handleClose}
        onSubmit={handleCreate}
      />
    </>
  );
};

export default RemoteAccessRoles;
