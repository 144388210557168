import React, { useContext } from 'react';
import { ColumnProps } from 'antd/lib/table';
import DeviceContext from '@totem/components/devices/deviceDetails/DeviceContext';
import Table from '@totem/components/common/table/Table';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import { formatDateTimeDisplay } from '@totem/utilities/timeUtilities';
import { sortStringAscending } from '@totem/utilities/tableUtilities';
import { getStatusFromDetails } from '@totem/components/maintenanceWindows/utilities';
import { isNotNull } from '@totem/utilities/common';
import { MaintenanceWindowDetails } from '@totem/components/maintenanceWindows/types';

const MaintenanceWindows = () => {
  const { deviceData, loading } = useContext(DeviceContext);

  const getData = () => {
    if (isNotNull(deviceData) && isNotNull(deviceData.device) && isNotNull(deviceData.device.maintenanceWindows)) {
      return deviceData.device.maintenanceWindows;
    }
    return [];
  };

  const columns: ColumnProps<MaintenanceWindowDetails>[] = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (_, record: MaintenanceWindowDetails) =>
        <Link to={`/dashboard/maintenanceWindows/${record.id}?from=maintenance-windows`}>
          <EyeOutlined />
        </Link>,
    },
    {
      title: 'Start Time',
      dataIndex: 'scheduledStartTime',
      key: 'scheduledStartTime',
      showSorterTooltip: true,
      render: (_, record: MaintenanceWindowDetails) =>
        formatDateTimeDisplay(record.scheduledStartTime),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.scheduledStartTime,
          compB.scheduledStartTime,
        ),
    },
    {
      title: 'End Time',
      dataIndex: 'scheduledEndTime',
      key: 'scheduledEndTime',
      showSorterTooltip: true,
      render: (_, record: MaintenanceWindowDetails) =>
        formatDateTimeDisplay(record.scheduledEndTime),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.scheduledEndTime,
          compB.scheduledEndTime,
        ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      showSorterTooltip: true,
      render: (_, record: MaintenanceWindowDetails) => getStatusFromDetails(record),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(getStatusFromDetails(compA), getStatusFromDetails(compB)),
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      showSorterTooltip: true,
      render: (_, record: MaintenanceWindowDetails) => record.reason,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.reason, compB.reason),
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      pagination={{
        showSizeChanger: true,
      }}
      rowKey={(record) => record.id}
    />
  );
};

export default MaintenanceWindows;
