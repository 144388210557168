import React, { useCallback, useContext, useState } from 'react';
import { DislikeOutlined, LikeOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import { Approval } from '@totem/components/maintenanceWindows/types';
import UserProfileContext from '@totem/components/UserProfileContext';
import colors from '@totem/styles/colors';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

const styles = {
  button: {
    marginRight: '4px',
  },
};

const formatDateTIme = (dateIn: string) => {
  if (dateIn !== null) {
    if (!isNaN(Date.parse(dateIn))) {
      const dateVal = new Date(dateIn);
      return (
        <>
          {dateVal.toLocaleDateString()}
          <br />
          {dateVal.toLocaleTimeString()}
        </>
      );
    }
  }
  return <span>-</span>;
};

type Props = {
  maintenanceWindowId: string;
  approvals: Approval[];
  loading: boolean;
  refetch: () => void;
};

const ApprovalsTable = ({
  maintenanceWindowId,
  approvals,
  refetch,
  loading,
}: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const [isSending, setIsSending] = useState(false);

  const sendUpdateMaintenanceWindowApproval = useCallback(
    async (maintWinId: string, approvalType: string) => {
      if (!isSending) {
        setIsSending(true);
        fetch(
          `${DEVICES_ENDPOINT}/maintenanceWindows/${maintWinId}/${approvalType}`,
          {
            method: 'POST',
            headers: new Headers({
              Authorization: `Bearer ${getToken()}`,
            }),
          },
        ).then((res) => {
          setIsSending(false);
          const success = CheckResponseShowError(res);
          if (success) {
            refetch();
          }
        });
      }
    },
    [],
  );

  const handleApproval = (id: string, type: string) => {
    sendUpdateMaintenanceWindowApproval(id, type);
  };

  const columns: ColumnProps<Approval>[] = [
    {
      title: 'Approver',
      dataIndex: 'approver',
      key: 'approver',
      showSorterTooltip: true,
      render: (_, record: Approval) => record.approver,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.approver, compB.approver),
    },
    {
      title: 'Requested At',
      dataIndex: 'requestedAt',
      key: 'requestedAt',
      showSorterTooltip: true,
      render: (_, record: Approval) => formatDateTIme(record.requestedAt),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.requestedAt, compB.requestedAt),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      showSorterTooltip: true,
      render: (_, record: Approval) =>
        record.approved && record.status === 'Pending'
          ? 'Not Required'
          : record.status,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) => sortStringAscending(compA.status, compB.status),
    },
    {
      title: 'Approved',
      dataIndex: 'approvedAt',
      key: 'approvedAt',
      showSorterTooltip: true,
      render: (_, record: Approval) =>
        record.approved ? formatDateTIme(record.requestedAt) : '-',
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.approvedAt, compB.approvedAt),
    },
    {
      title: 'Actions',
      key: 'actions',
      showSorterTooltip: true,
      render: (_, record: Approval) => {
        const isApprover = userProfile.email.toLowerCase() === record.approver;

        if (isApprover) {
          // eslint-disable-next-line default-case
          switch (record.status) {
            case 'Pending':
              return (
                <div className="center-table-cell">
                  <Tooltip title={'Approve'} placement="top">
                    <Button
                      shape={'circle'}
                      icon={
                        <LikeOutlined
                          style={{ color: colors.utility.positive }}
                        />
                      }
                      style={styles.button}
                      onClick={() =>
                        handleApproval(maintenanceWindowId, 'approve')
                      }
                    />
                  </Tooltip>
                  <Tooltip title={'Reject'} placement="top">
                    <Button
                      shape={'circle'}
                      icon={
                        <DislikeOutlined
                          style={{ color: colors.utility.negative }}
                        />
                      }
                      style={styles.button}
                      onClick={() =>
                        handleApproval(maintenanceWindowId, 'reject')
                      }
                    />
                  </Tooltip>
                </div>
              );
            case 'Approved':
              return (
                <div className="center-table-cell">
                  <Tooltip title={'Reject'} placement="top">
                    <Button
                      shape={'circle'}
                      icon={
                        <DislikeOutlined
                          style={{ color: colors.utility.negative }}
                        />
                      }
                      style={styles.button}
                      onClick={() =>
                        handleApproval(maintenanceWindowId, 'reject')
                      }
                    />
                  </Tooltip>
                </div>
              );
            case 'Rejected':
              return (
                <div className="center-table-cell">
                  <Tooltip title={'Approve'} placement="top">
                    <Button
                      shape={'circle'}
                      icon={
                        <LikeOutlined
                          style={{ color: colors.utility.positive }}
                        />
                      }
                      style={styles.button}
                      onClick={() =>
                        handleApproval(maintenanceWindowId, 'approve')
                      }
                    />
                  </Tooltip>
                </div>
              );
          }
        }

        return <span />;
      },
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={isNotNull(approvals) ? approvals : []}
      loading={isNotNull(loading) ? loading : true}
      rowKey={(record) => record.approver}
    />
  );
};

export default ApprovalsTable;
