import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card } from 'antd';
import SideBarTitle from '@totem/components/SideBarTitle';
import { PlusOutlined, SnippetsOutlined } from '@ant-design/icons';
import colors from '@totem/styles/colors';
import DetailSpinner from '@totem/components/common/DetailSpinner';
import AddEditNoteModal from '@totem/components/media/notes/AddEditNoteModal';
import { CreateRequest, GetRequest, Note, UpdateRequest } from '@totem/components/media/notes/types';
import { isNotNull, isNull } from '@totem/utilities/common';
import { MEDIA_ENDPOINT, RULES_ENDPOINT } from '@totem/utilities/endpoints';
import { getToken } from '@totem/utilities/accountUtilities';
import { CheckResponse } from '@totem/utilities/responseUtilities';
import NoteItem from '@totem/components/media/notes/NoteItem';

import './notes.css'

const styles = {
  card: {
    width: '100%',
  },
  cardHeader: {
    borderTop: `5px solid`,
    borderImage: `${colors.gradient.accent} 100% 0 0 0`,
    //borderImage: `${colors.gradient.turquoise} 100% 0 0 0`,
  },
  cardBody: {
    padding: '2.4rem',
    paddingBottom: '0',
    borderTop: `1px solid ${colors.antd.borderGray}`,
  },
  icon: {
    fontSize: '4rem',
    color: colors.neutral.silver,
  },
  errorIcon: {
    color: colors.utility.error,
  },
};

export interface Props {
  subjectId: string;
  title: string;
  noContentMessage: string;
  onCreate?: () => void;
  onDelete?: (id: string) => void;
}

const SideBarNotes = ( { subjectId, title, noContentMessage, onCreate, onDelete}: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [sending, setSending] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [dialogAction, setDialogAction] = useState<string>(''); // create, edit
  const [data, setData] = useState<Note[]>([]);
  const [selectedItem, setSelectedItem] = useState<Note>(null);

  useEffect(() => {
    if (refresh && isNotNull(subjectId) &&  subjectId !== '') {
      setRefresh(false);

      const paylaod: GetRequest = {
        subjectIds: [subjectId],
        limit: 10000,
        offset: 0,
      }

      fetch(`${MEDIA_ENDPOINT}/notes`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(paylaod),
      })
        .then((res) => CheckResponse(res))
        .then((res) => res.json())
        .then((result: Note[]) => {
          setData(result);
        })
        .then(() => {
          setLoading(false);
        });
    }
  }, [subjectId, refresh]);

  const sendCreateNote = useCallback(async (request: CreateRequest) => {
    if (!sending) {
      setSending(true);

      fetch(`${MEDIA_ENDPOINT}/notes`, {
        method: 'PUT',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request),
      })
        .then((res) => CheckResponse(res))
        .then(() => {
          setSending(false);
          setRefresh(true);
        });
    }
  }, [])

  const sendEditNote = useCallback(async (request: UpdateRequest) => {
    if (!sending) {
      setSending(true);

      fetch(`${MEDIA_ENDPOINT}/notes`, {
        method: 'PATCH',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request),
      })
        .then((res) => CheckResponse(res))
        .then(() => {
          setSending(false);
          setRefresh(true);
        });
    }
  }, [])

  const sendDeleteNote = useCallback(async (id: string) => {
    if (!sending) {
      setSending(true);

      fetch(`${MEDIA_ENDPOINT}/notes/${id}`, {
        method: 'DELETE',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => CheckResponse(res))
        .then(() => {
          setSending(false);
          setRefresh(true);
        });
    }
  }, [])

  const handleNewNote = () => {
    setSelectedItem(null);
    setDialogAction('create');
    setShowEditDialog(true);
  };

  const handleEditNote = (actionNote: Note) => {
    setSelectedItem(actionNote);
    setDialogAction('edit');
    setShowEditDialog(true);
  };

  const handleDeleteNote = (actionNote: Note) => {
    sendDeleteNote(actionNote.id);
  }

  const handleDialogSubmit = (title: string, body: string) => {
    switch (dialogAction) {
      case 'create':
        const createPayload: CreateRequest = {
          subjectId: subjectId,
          title: title,
          body: body,
        }

        sendCreateNote(createPayload);
        break;
        case 'edit':
          const updatePaylaod: UpdateRequest = {
            id: selectedItem.id,
            title: title,
            body: body,
          }

          sendEditNote(updatePaylaod);
          break;
    }
    setSelectedItem(null);
    setDialogAction('');
    setShowEditDialog(false);
  }

  return (
    <>
      <Card
        title={
          <SideBarTitle>
            <div>{title}</div>
            <Button
              shape="circle"
              icon={<PlusOutlined style={{ color: colors.brand.blue }} />}
              onClick={() => handleNewNote()}
            />
          </SideBarTitle>
        }
        style={styles.card}
        styles={{body: {
            ...styles.cardBody,
            ...(isNotNull(data) && {
              padding: '2.4rem 0rem',
            }),
          }, header: styles.cardHeader}}
      >
        {loading ? (
          <div styleName="spinner-container">
            <DetailSpinner />
          </div>
        ) : isNull(data) || data.length === 0 ? (
          <div styleName="no-system-container">
            <SnippetsOutlined style={styles.icon} />
            <div styleName="no-system-subtext">{noContentMessage}</div>
            <Button type="primary" onClick={() => handleNewNote()}>
              Add Note
            </Button>
          </div>
        ) : (
          <div styleName="container">
            {
              // eslint-disable-next-line no-shadow
              data.map((note) => (
                <NoteItem
                  key={note.id}
                  note={note}
                  onEditClick={handleEditNote}
                  onDeleteClick={handleDeleteNote}
                />
              ))
            }
          </div>
        )}
      </Card>
      <AddEditNoteModal
        open={showEditDialog}
        onClose={() => setShowEditDialog(false)}
        loading={loading || sending}
        onSubmit={handleDialogSubmit}
        note={selectedItem}
      />
    </>
  );
};

export default SideBarNotes;
