import { createContext } from 'react';

import { PolicyAudit } from '@totem/types/policyAudit';
import { MediaState } from '@totem/types/store';

interface Context {
  loading: boolean;
  data: PolicyAudit;
  mediaData: MediaState;
  refetch: () => void;
}

export default createContext<Context>({
  loading: false,
  data: null,
  mediaData: null,
  refetch: () => {},
});
