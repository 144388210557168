import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AutoComplete, Button, Flex, message, Modal, Select, Tag } from 'antd';

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import PackageContext from '@totem/components/software/software_package/packageContext';
import { TagUpdateRequest } from '@totem/components/software/software_package/types';
import HeaderLabel from '@totem/components/ticketing/details/headerLabel';
import UserProfileContext from '@totem/components/UserProfileContext';
import IBDIcon from '@totem/styles/Icon';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { isIBUser } from '@totem/utilities/security';
import { emptyId } from '@totem/utilities/userUtilities';

const styles = {
  tag: {
    marginBottom: '3px',
  },
  icon: {
    marginLeft: '5px',
  },
  input: {
    width: '100%',
  },
};

const SoftwareTags = () => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isTagsLoading, setIsTagsLoading] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [knownTags, setKnownTags] = useState<string[]>([]);
  const [currentTag, setCurrentTag] = useState<string>('');
  const [selectedOrganization, setSelectedOrganization] =
    useState<string>(emptyId);
  const { data, onAction, organizationId } = useContext(PackageContext);
  const { userProfile } = useContext(UserProfileContext);
  const canAdmin = userProfile.organization.role === 3 && isIBUser(userProfile);

  const orgTags =
    isNotNull(data) &&
    isNotNull(data.package) &&
    isNotNull(data.package.organizationTags) &&
    data.package.organizationTags.length > 0 &&
    data.package.organizationTags[0].organizationId ===
      userProfile.organization.id
      ? data.package.organizationTags[0].tags
      : [];

  useEffect(() => {
    if (!isTagsLoading) {
      setIsTagsLoading(true);
      const uri = `${DEVICES_ENDPOINT}/software/tags`;
      fetch(uri, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: string[]) => {
          setKnownTags(result);
        })
        .then(() => {
          setIsTagsLoading(false);
        });
    }
  }, []);
  const handleClose = () => {
    setCurrentTag('');
    setShowEdit(false);
  };

  const sendUpdate = useCallback(
    async (request: TagUpdateRequest) => {
      if (isSending) {
        return;
      }

      fetch(`${DEVICES_ENDPOINT}/software/tags`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request),
      })
        .then((response) => {
          if (response.status !== 200) {
            message.error('Tag update failed!');
          } else {
            onAction('refresh');
            handleClose();
          }
        })
        .then(() => {
          setIsSending(false);
        });

      setIsSending(true);
    },
    [isSending],
  );

  const handleUpdateTag = (
    tagOrganizationId: string,
    action: string,
    tag: string,
  ) => {
    const request: TagUpdateRequest = {
      action,
      organizationId: tagOrganizationId,
      packageId: data.package.id,
      tag,
    };

    sendUpdate(request);
  };

  const handleSubmit = () => {
    if (currentTag !== null && currentTag !== '') {
      handleUpdateTag(selectedOrganization, 'Add', currentTag);
    }
  };

  const handleChange = (value: string) => {
    setSelectedOrganization(value);
  };

  return (
    <HeaderLabel
      title={
        <Flex justify={'space-between'} align={'center'}>
          <div>Tags</div>
          <div>
            {canAdmin && (
              <IBDIcon
                name="Add"
                title="Add Tag"
                onClick={() => setShowEdit(true)}
              />
            )}
          </div>
        </Flex>
      }
    >
      {isNotNull(data) &&
      isNotNull(data.package) &&
      isNotNull(data.package.tags)
        ? data.package.tags.map((chk) => (
            <Tag key={chk} color={'success'} style={styles.tag}>
              {chk}
              {canAdmin && (
                <IBDIcon
                  name="Delete"
                  title="Remove Tag"
                  style={styles.icon}
                  onClick={() => handleUpdateTag(emptyId, 'Remove', chk)}
                />
              )}
            </Tag>
          ))
        : ''}
      {isNotNull(data) &&
      isNotNull(data.package) &&
      isNotNull(data.package.organizationTags)
        ? orgTags.map((chk) => (
            <Tag key={chk} color={'volcano'} style={styles.tag}>
              {chk}
              {canAdmin && (
                <IBDIcon
                  name="Delete"
                  title="Remove Tag"
                  style={styles.icon}
                  onClick={() => handleUpdateTag(userProfile.organization.id ,'Remove', chk)}
                />
              )}
            </Tag>
          ))
        : ''}
      {showEdit && (
        <Modal
          open={showEdit}
          onCancel={() => handleClose()}
          title={<ModalTitle>Add Tag</ModalTitle>}
          confirmLoading={false}
          footer={
            <ModalFooter>
              <Button onClick={() => handleClose()}>Close</Button>
              <Button onClick={handleSubmit} type="primary">
                Submit
              </Button>
            </ModalFooter>
          }
        >
          <Select
            onChange={handleChange}
            style={styles.input}
            value={selectedOrganization}
          >
            <Select.Option value={emptyId}>Global</Select.Option>
            {organizationId === userProfile.organization.id && (
              <Select.Option value={userProfile.organization.id}>
                {userProfile.organization.name}
              </Select.Option>
            )}
          </Select>
          <AutoComplete
            placeholder="Tag"
            options={
              isNotNull(knownTags)
                ? knownTags.map((chk) => {
                    return { value: chk };
                  })
                : []
            }
            value={currentTag}
            onChange={(newValue) => setCurrentTag(newValue)}
            onSelect={(newValue) => setCurrentTag(newValue)}
            style={styles.input}
          />
        </Modal>
      )}
    </HeaderLabel>
  );
};

export default SoftwareTags;
