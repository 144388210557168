import React, { ReactNode, useEffect, useState } from 'react';
import MaintenanceWindowDetailsContext from '@totem/components/maintenanceWindows/detail/maintenanceWindowDetailContext';
import { MaintenanceWindow } from '@totem/components/maintenanceWindows/types';
import { Params } from '@totem/types/common';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { getToken } from '@totem/utilities/accountUtilities';
import MaintenanceWindowEditDialog from '@totem/components/maintenanceWindows/maintenanceWindowEditDialog';

type Props = {
  id: string;
  children?: ReactNode;
};

const MaintenanceWindowDetailsContainer = ({ id, children }: Props) => {
  const [data, setData] = useState<MaintenanceWindow>(null);
  const [loading, setLoading] = useState(true);
  const [action, setAction] = useState<string>('');
  const [refreshData, setRefreshData] = useState<boolean>(true);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setLoading(true);

      fetch(`${DEVICES_ENDPOINT}/maintenanceWindows/${id}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: MaintenanceWindow) => {
          setData(result);
        })
        .then(() => {
          setLoading(false);
        });
    }
  }, [refreshData]);

  const handleAction = (
    newAction: string,
    newActionItem: MaintenanceWindow,
  ) => {
    switch (newAction) {
      case 'refresh':
        setRefreshData(true);
        break;
      case 'clear':
        setAction('');
        break;
      default:
        setAction(newAction);
        break;
    }
  };

  return (
    <MaintenanceWindowDetailsContext.Provider
      value={{
        loading,
        data,
        action,
        onAction: handleAction,
      }}
    >
      {children}
      {action === 'edit' && (
        <MaintenanceWindowEditDialog
          open={action === 'edit'}
          onClose={() => handleAction('close', null)}
          maintenanceWindow={data}
        />
      )}
    </MaintenanceWindowDetailsContext.Provider>
  );
};

export default MaintenanceWindowDetailsContainer;
