import React from 'react';
import ContentLayout from '@totem/components/ContentLayout';
import BasePane from '@totem/components/BasePane';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import MaintenanceWindowsContainer from '@totem/components/maintenanceWindows/maintenanceWindowsContainer';
import MaintenanceWindowsTable from '@totem/components/maintenanceWindows/maintenanceWindowsTable';

const MaintenanceWindowsPage = () => {
  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <MaintenanceWindowsContainer>
            <MaintenanceWindowsTable />
          </MaintenanceWindowsContainer>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default MaintenanceWindowsPage;
