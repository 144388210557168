import React from 'react';
import SoftwareContainer from '@totem/components/software/software_package/packageContainer';
import PackageVulnerabilities from '@totem/components/software/software_package/packageVulnerabilities';

type Props = {
  id: string;
}

const Vulnerabilities = ({ id }: Props) => {
  return (
    <SoftwareContainer packageName={id}>
      <PackageVulnerabilities />
    </SoftwareContainer>
  );
};

export default Vulnerabilities;
