import React, { useContext } from 'react';

import RemoteAccessUserDetailsContext from '@totem/components/remoteAccess/userDetails/RemoteAccessUserDetailsContext';
import { isNotNull } from '@totem/utilities/common';

const RemoteAccessUserTitle = () => {
  const { roleData } = useContext(RemoteAccessUserDetailsContext);

  return (
    <div>
      {isNotNull(roleData) && isNotNull(roleData.user) && isNotNull(roleData.user.email)
        ? roleData.user.email
        : ''}
    </div>
  );
};

export default RemoteAccessUserTitle;
