import { Filter } from '@totem/types/common';
import {
  TicketingConnectionInput,
  TicketingFilters,
} from '@totem/types/ticketing';
import { isNotNull } from '@totem/utilities/common';

export const buildFilters = (input: TicketingConnectionInput) => {
  const paramFilters: Filter[] = [];

  if (input.ids !== null && input.ids.length > 0) {
    paramFilters.push({
      field: '_id',
      values: input.ids,
    });
  }
  if (input.regionId !== null && input.regionId.length > 0) {
    paramFilters.push({
      field: 'regionId',
      values: input.regionId,
    });
  }
  if (input.buildingId !== null && input.buildingId.length > 0) {
    paramFilters.push({
      field: 'buildingId',
      values: input.buildingId,
    });
  }
  if (input.controlSystemId !== null && input.controlSystemId.length > 0) {
    paramFilters.push({
      field: 'controlSystemId',
      values: input.controlSystemId,
    });
  }
  if (input.deviceId !== null && input.deviceId.length > 0) {
    paramFilters.push({
      field: 'deviceId',
      values: input.deviceId,
    });
  }
  if (isNotNull(input.queueId) && input.queueId.length > 0) {
    paramFilters.push({
      field: 'queueId',
      values: input.queueId,
    });
  }
  if (input.type !== null && input.type.length > 0) {
    paramFilters.push({
      field: 'type',
      values: input.type,
    });
  }
  if (input.status !== null && input.status.length > 0) {
    paramFilters.push({
      field: 'status',
      values: input.status,
    });
  }
  if (input.category !== null && input.category.length > 0) {
    paramFilters.push({
      field: 'category',
      values: input.category,
    });
  }
  if (input.priority !== null && input.priority.length > 0) {
    paramFilters.push({
      field: 'priority',
      values: input.priority,
    });
  }
  if (input.reference !== null && input.reference.length > 0) {
    paramFilters.push({
      field: 'keys',
      values: input.reference,
    });
  }

  return paramFilters;
};

export const addFilters = (
  currentFilters: Filter[],
  input: TicketingFilters,
) => {
  if (typeof input !== 'undefined' && input !== null) {
    if (
      typeof input.ids !== 'undefined' &&
      input.ids !== null &&
      input.ids.length > 0
    ) {
      currentFilters.push({
        field: '_id',
        values: input.ids,
      });
    }
    if (
      typeof input.regionId !== 'undefined' &&
      input.regionId !== null &&
      input.regionId.length > 0
    ) {
      currentFilters.push({
        field: 'regionId',
        values: input.regionId,
      });
    }
    if (
      typeof input.buildingId !== 'undefined' &&
      input.buildingId !== null &&
      input.buildingId.length > 0
    ) {
      currentFilters.push({
        field: 'buildingId',
        values: input.buildingId,
      });
    }
    if (
      typeof input.controlSystemId !== 'undefined' &&
      input.controlSystemId !== null &&
      input.controlSystemId.length > 0
    ) {
      currentFilters.push({
        field: 'controlSystemId',
        values: input.controlSystemId,
      });
    }
    if (
      typeof input.deviceId !== 'undefined' &&
      input.deviceId !== null &&
      input.deviceId.length > 0
    ) {
      currentFilters.push({
        field: 'deviceId',
        values: input.deviceId,
      });
    }
    if (isNotNull(input.queueId) && input.queueId.length > 0) {
      currentFilters.push({
        field: 'queueId',
        values: input.queueId,
      });
    }
    if (
      typeof input.type !== 'undefined' &&
      input.type !== null &&
      input.type.length > 0
    ) {
      currentFilters.push({
        field: 'type',
        values: input.type,
      });
    }
    if (
      typeof input.status !== 'undefined' &&
      input.status !== null &&
      input.status.length > 0
    ) {
      currentFilters.push({
        field: 'status',
        values: input.status,
      });
    }
    if (
      typeof input.category !== 'undefined' &&
      input.category !== null &&
      input.category.length > 0
    ) {
      currentFilters.push({
        field: 'category',
        values: input.category,
      });
    }
    if (
      typeof input.priority !== 'undefined' &&
      input.priority !== null &&
      input.priority.length > 0
    ) {
      currentFilters.push({
        field: 'priority',
        values: input.priority,
      });
    }
    if (
      typeof input.ticketNumber !== 'undefined' &&
      input.ticketNumber !== null &&
      input.ticketNumber !== ''
    ) {
      currentFilters.push({
        field: 'number',
        operator: 'contains',
        values: [input.ticketNumber],
      });
    }
    if (
      typeof input.reference !== 'undefined' &&
      input.reference !== null &&
      input.reference.length > 0
    ) {
      currentFilters.push({
        field: 'keys',
        values: input.reference,
      });
    }
  }

  return currentFilters;
};
