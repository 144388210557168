import React, { useContext } from 'react';
import { Col, Row } from 'antd';

import EventFilterContext from '@totem/components/events/eventFilters/EventFilterContext';
import EventGraphContext from '@totem/components/events/graphs/EventGraphContext';
import PieChart from '@totem/components/events/graphs/PieChart';
import StackedTimeBarChart from '@totem/components/events/graphs/StackedTimeBarChart';
import colors from '@totem/styles/colors';

import '../events.css';

const EventGraphsLayout = () => {
  const { graphData } = useContext(EventGraphContext);
  const { input } = useContext(EventFilterContext);

  const getSeverityChartOptions = (): ApexCharts.ApexOptions => {
    const severityColors = [];
    if (typeof graphData !== 'undefined' && graphData !== null) {
      for (let idx = 0; idx < graphData.eventSeverity.length; idx++) {
        switch (graphData.eventSeverity[idx].x) {
          case '0':
          case 'OK':
            severityColors.push(colors.event.criticality.background.ok); // 'OK';
            break;
          case '1':
          case 'Information':
            severityColors.push(colors.event.criticality.background.info); // 'Information';
            break;
          case '2':
          case 'Warning':
            severityColors.push(colors.event.criticality.background.warning); //  'Warning';
            break;
          case '3':
          case 'Minor':
            severityColors.push(colors.event.criticality.background.minor); // 'Minor';
            break;
          case '4':
          case 'Major':
            severityColors.push(colors.event.criticality.background.major); //  'Major';
            break;
          case '5':
          case 'Critical':
            severityColors.push(colors.event.criticality.background.critical); // 'Critical';
            break;
          default:
            severityColors.push(colors.event.criticality.background.unknown);
        }
      }
    }
    return {
      colors: severityColors,
      title: { text: 'Event Severity Summary ', align: 'center', offsetY: -5 },
    };
  };
    return (
    <>
      {typeof graphData !== 'undefined' && graphData !== null && (
        <div style={{ zoom: 1.0 }}>
          <Row>
            <Col span={8}>
              <PieChart
                series={graphData.eventSeverity}
                chartOptions={getSeverityChartOptions()}
              />
            </Col>
            <Col span={8}>
              <PieChart
                series={graphData.topBuildings}
                chartOptions={{
                  title: {
                    text: 'Building Summary (Top 10 by Event Count) ',
                    align: 'center',
                    offsetY: -5,
                  },
                }}
              />
            </Col>
            <Col span={8}>
              <PieChart
                series={graphData.eventTypes}
                chartOptions={{
                  title: {
                    text: 'Event Types Summary ',
                    align: 'center',
                    offsetY: -5,
                  },
                }}
              />
            </Col>
          </Row>
          <br />
          <br />
          <StackedTimeBarChart
            startTime={input.startTime}
            endTime={input.endTime}
            series={graphData.eventTypeSeries}
            chartOptions={{
              title: { text: 'Event Types Trend', align: 'center' },
            }}
          />
        </div>
      )}
    </>
    );
    };

    export default EventGraphsLayout;
