import { createContext } from 'react';

import { emptyId } from '@totem/utilities/userUtilities';
import { Event } from '@totem/types/events';

interface Context {
  loading: boolean;
  organizationId: string;
  data: Event;
  action: string;
  onAction?: (action: string) => void;
}

export default createContext<Context>({
  loading: false,
  organizationId: emptyId,
  data: null,
  action: '',
  onAction: () => {},
});
