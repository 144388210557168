import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Modal } from 'antd';

import CardBody from '@totem/components/CardBody';
import CardHeader from '@totem/components/CardHeader';
import ModalTitle from '@totem/components/ModalTitle';
import UserProfileContext from '@totem/components/UserProfileContext';
import SecurityGroupAddModal from '@totem/components/users/container/actions/securityGroups/SecurityGroupAddModal';
import SecurityGroupsTable from '@totem/components/users/container/actions/securityGroups/SecurityGroupsTable';
import { User } from '@totem/components/users/container/types';
import colors from '@totem/styles/colors';
import { OrganizationHierarchy } from '@totem/types/organization';
import { EligibleSecurityGroup, SecurityGroup } from '@totem/types/user';
import { getToken } from '@totem/utilities/accountUtilities';
import authUtilities from '@totem/utilities/authUtilities';
import {
  USER_RESTRICTIONS_ENDPOINT,
  USERS_ELIGIBLE_GROUPS_ENDPOINT,
  USERS_ENDPOINT,
} from '@totem/utilities/endpoints';
import {
  isMemberOfAny,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

import '../../../users.css';

const styles = {
  input: {
    width: '26rem',
    margin: '2rem 0',
  },
  inputIcon: {
    color: colors.opacity.black0_2,
    fontSize: '15px',
    height: '15px',
    width: '15px',
  },
};

export interface Props {
  user: User;
  open: boolean;
  onClose: () => void;
}

export interface AddRequest {
  userId: string;
  securityGroup: SecurityGroup;
}

const SecurityGroupsModal = ({ user, open, onClose }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const [orgHierarchy, setOrgHierarchy] = useState<OrganizationHierarchy>(null);
  const [eligibleGroups, setEligibleGroups] = useState<EligibleSecurityGroup[]>(
    [],
  );
  const [userSecurityGroups, setUserSecurityGroups] = useState<SecurityGroup[]>(
    [],
  );
  const [userSecurityGroupsFiltered, setUserSecurityGroupsFiltered] = useState<
    SecurityGroup[]
  >([]);

  const [isGroupsLoading, setIsGroupsLoading] = useState<boolean>(true);
  const [isUserSecurityGroupsLoading, setIsUserSecurityGroupsLoading] =
    useState<boolean>(true);
  const [isHierarchyLoading, setIsHierarchyLoading] = useState<boolean>(true);
  const [showGroupAddModal, setShowGroupAddModal] = useState<boolean>(false);
  const [isSending, setIsSending] = useState(false);
  const [groupFilter, setGroupFilter] = useState('');

  const setFilteredGroups = (groups: SecurityGroup[], filter: string) => {
    if (groupFilter !== '') {
      const filteredResults = groups.filter(
        (grp) =>
          grp.name.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) >= 0,
      );

      setUserSecurityGroupsFiltered(filteredResults);
    } else {
      setUserSecurityGroupsFiltered(groups);
    }
  };

  useEffect(() => {
    const hasAssignablePermission = (grp: EligibleSecurityGroup) => {
      if (grp.assignerRole <= userProfile.organization.role) {
        if (
          isMemberOfAny(
            userProfile,
            grp.assignerSecurityGroups,
            securityGroupCheckConstraintEmpty,
          )
        ) {
          return true;
        }
      }

      return false;
    };

    fetch(USERS_ELIGIBLE_GROUPS_ENDPOINT, {
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        setIsGroupsLoading(false);

        const filteredGroups: EligibleSecurityGroup[] = [];

        for (let idx = 0; idx < result.length; idx++) {
          const checkGroup: EligibleSecurityGroup = {
            id: result[idx].id,
            name: result[idx].name,
            description: result[idx].description,
            displayName: result[idx].displayName,
            serviceId: result[idx].serviceId,
            assignableLevels: result[idx].assignableLevels,
            assignerRole: result[idx].assignerRole,
            assignerSecurityGroups: result[idx].assignerSecurityGroups,
          };

          if (hasAssignablePermission(checkGroup)) {
            filteredGroups.push(checkGroup);
          }
        }

        setEligibleGroups(filteredGroups);
      })
      .then(() => setIsGroupsLoading(false));
  }, [userProfile]);

  useEffect(() => {
    fetch(`${USER_RESTRICTIONS_ENDPOINT}/`, {
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        setIsHierarchyLoading(false);
        setOrgHierarchy(result.hierarchy);
      })
      .then(() => setIsGroupsLoading(false));
  }, []);

  useEffect(() => {
    fetch(`${USERS_ENDPOINT}/${user.id}/securityGroups`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        setIsUserSecurityGroupsLoading(false);
        setUserSecurityGroups(result);
        setFilteredGroups(result, groupFilter);
      })
      .then(() => setIsGroupsLoading(false));
  }, [user]);

  const sendAddSecurityGroupRequest = useCallback(
    async (request: AddRequest) => {
      if (isSending) {
        return;
      }

      setIsSending(true);

      fetch(`${USERS_ENDPOINT}/${user.id}/securityGroups`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request.securityGroup),
      })
        .then((res) => res.json())
        .then((result) => {
          setIsUserSecurityGroupsLoading(false);
          setUserSecurityGroups(result);
          setFilteredGroups(result, groupFilter);
        })
        .then(() => {
          setIsGroupsLoading(false);
          setIsSending(false);
        });
    },
    [isSending, user.id],
  );

  const sendRemoveSecurityGroupRequest = useCallback(
    async (request: AddRequest) => {
      if (isSending) {
        return;
      }

      setIsSending(true);

      fetch(`${USERS_ENDPOINT}/${user.id}/securityGroups`, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request.securityGroup),
      })
        .then((res) => res.json())
        .then((result) => {
          setIsUserSecurityGroupsLoading(false);
          setUserSecurityGroups(result);
          setFilteredGroups(result, groupFilter);
        })
        .then(() => {
          setIsGroupsLoading(false);
          setIsSending(false);
        });
    },
    [isSending, user.id],
  );

  const title = `User: ${user.email}`;

  const handleAddGroupDialogClose = () => {
    setShowGroupAddModal(false);
  };

  const handleAddGroupDialogSubmit = (
    securityGroup: EligibleSecurityGroup,
    regionId: string,
    buildingId: string,
    controlSystemId: string,
  ) => {
    sendAddSecurityGroupRequest({
      userId: user.id,
      securityGroup: {
        id: securityGroup.id,
        name: securityGroup.name,
        description: securityGroup.description,
        regionId,
        buildingId,
        controlSystemId,
      },
    });

    handleAddGroupDialogClose();
  };

  const userAdminEnabled = authUtilities.minimumRequiredRole(
    authUtilities.ROLE_SETS.ORGANIZATION_ADMINISTRATOR,
  );

  const handleRemoveGroup = (group: SecurityGroup) => {
    sendRemoveSecurityGroupRequest({
      userId: user.id,
      securityGroup: group,
    });
  };

  const handleSearch = (filter: string) => {
    setGroupFilter(filter);
    setFilteredGroups(userSecurityGroups, filter);
  };

  const footerContent = (
    <Button key="close" type="primary" onClick={onClose}>
      Close
    </Button>
  );

  return (
    <Modal
      open={open}
      centered
      width="80%"
      title={<ModalTitle>{title}</ModalTitle>}
      onOk={onClose}
      onCancel={onClose}
      footer={[footerContent]}
    >
      <CardHeader>
        <div>Security Groups</div>
        {userAdminEnabled && (
          <Button
            onClick={() => setShowGroupAddModal(true)}
            disabled={!userAdminEnabled}
            type="primary"
          >
            Add Security Group
          </Button>
        )}
      </CardHeader>
      <CardBody>
        <div styleName="export-button-container">
          <Input
            onChange={(event) => handleSearch(event.target.value)}
            size="large"
            placeholder="Search Security Groups"
            prefix={<SearchOutlined style={styles.inputIcon} />}
            style={styles.input}
            defaultValue={groupFilter}
          />
        </div>
        {showGroupAddModal && (
          <SecurityGroupAddModal
            user={user}
            orgHierarchy={orgHierarchy}
            eligibleGroups={eligibleGroups}
            visible={showGroupAddModal}
            onClose={() => setShowGroupAddModal(false)}
            onSubmit={handleAddGroupDialogSubmit}
          />
        )}
        <SecurityGroupsTable
          loading={
            isGroupsLoading || isHierarchyLoading || isUserSecurityGroupsLoading
          }
          orgHierarchy={orgHierarchy}
          eligibleGroups={eligibleGroups}
          securityGroups={userSecurityGroupsFiltered}
          onRemoveGroup={handleRemoveGroup}
        />
      </CardBody>
    </Modal>
  );
};

export default SecurityGroupsModal;
