import {
  BuildingSummaryFilters,
  BuildingSummaryInput, FilterOptions,
} from '@totem/components/buildingSummary/types';
import { Filter } from '@totem/types/common';

export const buildReportFilters = (input: BuildingSummaryInput) => {
  const paramFilters: Filter[] = [];

  if (
    typeof input.regionId !== 'undefined' &&
    input.regionId !== null &&
    input.regionId.length > 0
  ) {
    paramFilters.push({
      field: 'regionId',
      values: input.regionId,
    });
  }
  if (
    typeof input.buildingId !== 'undefined' &&
    input.buildingId !== null &&
    input.buildingId.length > 0
  ) {
    paramFilters.push({
      field: '_id',
      values: input.buildingId,
    });
  }
  if (
    typeof input.name !== 'undefined' &&
    input.name !== null &&
    input.name.length > 0
  ) {
    paramFilters.push({
      field: 'name',
      values: input.name,
    });
  }
  if (
    typeof input.type !== 'undefined' &&
    input.type !== null &&
    input.type.length > 0
  ) {
    paramFilters.push({
      field: 'type',
      values: input.type,
    });
  }
  if (
    typeof input.city !== 'undefined' &&
    input.city !== null &&
    input.city.length > 0
  ) {
    paramFilters.push({
      field: 'city',
      values: input.city,
    });
  }
  if (
    typeof input.state !== 'undefined' &&
    input.state !== null &&
    input.state.length > 0
  ) {
    paramFilters.push({
      field: 'state',
      values: input.state,
    });
  }
  if (
    typeof input.searchName !== 'undefined' &&
    input.searchName !== null &&
    input.searchName.trim() !== ''
  ) {
    paramFilters.push({
      field: 'name',
      operator: 'cscontains',
      values: [input.searchName],
    });
  }

  return paramFilters;
};

export const addReportFilters = (
  currentFilters: Filter[],
  input: BuildingSummaryFilters,
) => {
  if (typeof input !== 'undefined') {
    if (
      typeof input.regionId !== 'undefined' &&
      input.regionId !== null &&
      input.regionId.length > 0
    ) {
      currentFilters.push({
        field: 'regionId',
        values: input.regionId,
      });
    }
    if (
      typeof input.buildingId !== 'undefined' &&
      input.buildingId !== null &&
      input.buildingId.length > 0
    ) {
      currentFilters.push({
        field: '_id',
        values: input.buildingId,
      });
    }
    if (
      typeof input.name !== 'undefined' &&
      input.name !== null &&
      input.name.length > 0
    ) {
      currentFilters.push({
        field: 'name',
        values: input.name,
      });
    }
    if (
      typeof input.type !== 'undefined' &&
      input.type !== null &&
      input.type.length > 0
    ) {
      currentFilters.push({
        field: 'type',
        values: input.type,
      });
    }
  }

  return currentFilters;
};

export const getFilterOptions = (field: string, filterOptions: FilterOptions) => {
  for (const filter of filterOptions) {
    if (filter.field === field) {
      return filter.options.map((option) => {
        return {
          text: option.name,
          value: option.value,
        }
      })
    }
  }
  return [];
}
