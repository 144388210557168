import React, { useState } from 'react';
import { Input } from 'antd';

import { Question } from '@totem/types/policyAudit';

interface Props {
  question: Question;
  onChange: (key: string, value: string) => void;
  disabled: boolean;
}

const TextQuestion: React.FC<Props> = ({
  question,
  onChange,
  disabled,
}: Props) => {
  const { key, answer } = question;

  const initialValue = answer?.value ?? '';

  const [value, setValue] = useState<string>(initialValue);

  const [valueOnFocus, setValueOnFocus] = useState<string>(initialValue);

  const handleBlur = () => {
    if (valueOnFocus !== value) {
      onChange(key, value);
    }
  };

  return (
    <Input
      value={value}
      onChange={(event) => setValue(event.target.value)}
      onFocus={() => setValueOnFocus(value)}
      onBlur={handleBlur}
      disabled
    />
  );
};

export default TextQuestion;
