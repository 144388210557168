import React, { useCallback, useEffect, useState } from 'react';
import { CloseCircleFilled, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Modal, notification } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import BasePane from '@totem/components/BasePane';
import ComponentHeader from '@totem/components/ComponentHeader';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import colors from '@totem/styles/colors';
import {
  AddEditRegion,
  RegionAction,
} from '@totem/types/region';
import authUtilities from '@totem/utilities/authUtilities';
import { debounce } from '@totem/utilities/debounce';

import ContentLayout from '../ContentLayout';

import AddEditRegionModal from './AddEditRegionModal';
import RegionsTable from './RegionsTable';

import './regions.css';
import { RegionSummaryItem, RequestCreateRegion, RequestRegionUpdate } from '@totem/components/regions/v2/types';
import { V2_REGIONS_ENDPOINT } from '@totem/utilities/endpoints';
import { getToken } from '@totem/utilities/accountUtilities';
import { Params } from '@totem/types/common';
import { isNotNull } from '@totem/utilities/common';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

const styles = {
  icon: {
    color: colors.neutral.gray,
  },
  errorIcon: {
    color: colors.utility.error,
  },
  deleteButton: {
    background: colors.utility.error,
    color: colors.neutral.white,
  },
};

const TABLE_LIMIT = 10;
const DEBOUNCE_TIME = 300;

const Regions = () => {
  const [data, setData] = useState<RegionSummaryItem[]>([]);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [input, setInput] = useQueryParams({
    offset: withDefault(NumberParam, 0),
    limit: withDefault(NumberParam, TABLE_LIMIT),
    name: StringParam,
    sortOrder: StringParam,
    sortBy: withDefault(StringParam, "name"),
  });

  const [regionName, setRegionName] = useState<AddEditRegion>({
    name: '',
  });
  const [regionAction, setRegionAction] = useState<RegionAction>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      setLoading(true);

      const payload: Params = {
        paging: {
          page: input.offset / TABLE_LIMIT,
          pageSize: TABLE_LIMIT,
        },
        sort: {
          field: isNotNull(input.sortBy) && input.sortBy !== '' ? input.sortBy : 'name',
          direction: isNotNull(input.sortOrder) && input.sortOrder === 'descend' ? -1 : 1,
        },
        filters: []
      }

      if (isNotNull(input.name) && input.name !== '') {
        payload.filters.push(
          {
            field: 'name',
            operator: 'cscontains',
            values: [input.name],
          }
        );
      }

      fetch(`${V2_REGIONS_ENDPOINT}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then(res => res.json())
        .then((result: RegionSummaryItem[]) => {
          setData(result);
        })
        .then(() => {
          setLoading(false);
        });
    }
  }, [refresh]);

  const sendCreateRegion = useCallback(async (request: RequestCreateRegion) => {
    if (!sending) {
      setSending(true);

      fetch(`${V2_REGIONS_ENDPOINT}`, {
        method: 'PUT',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request),
      })
        .then(res => {
          setSending(false);
          setIsModalOpen(false);
          CheckResponseShowError(res);
          setRefresh(true);
        });
    }
  }, []);

  const sendUpdateRegion = useCallback(async (request: RequestRegionUpdate) => {
    if (!sending) {
      setSending(true);

      fetch(`${V2_REGIONS_ENDPOINT}/${request.regionID}`, {
        method: 'PATCH',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request),
      })
        .then(res => {
          setSending(false);
          setIsModalOpen(false);
          CheckResponseShowError(res);
          setRefresh(true);
        });
    }
  }, []);

  const sendDeleteRegion = useCallback(async (id: string) => {
    if (!sending) {
      setSending(true);

      fetch(`${V2_REGIONS_ENDPOINT}/${id}`, {
        method: 'DELETE',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then(res => {
          setSending(false);
          CheckResponseShowError(res);
          setRefresh(true);
        });;
    }
  }, []);

  const handlePagination = (
    { current, pageSize: limit }: TablePaginationConfig,
    { field, order }: SorterResult<AddEditRegion>,
  ) => {
    const offset = (current - 1) * limit;
    const sortBy = !order ? null : field;
    const sortOrder = !order ? null : order;
    // @ts-ignore
    setInput({ ...input, limit, offset, sortBy, sortOrder });
  };

  const handleSearch = useCallback(
    debounce(
      (name: string) => setInput({ ...input, name, offset: 0 }),
      DEBOUNCE_TIME,
    ),
    [],
  );

  const handleAddRegionClick = () => {
    setRegionAction(RegionAction.ADD);
    setRegionName({ name: '' });
    setIsModalOpen(true);
  };

  const handleEditRegionClick = (region: RegionSummaryItem) => {
    setRegionAction(RegionAction.EDIT);
    setRegionName(region);
    setIsModalOpen(true);
  };

  const handleDeleteRegionClick = async (region: RegionSummaryItem) => {
    Modal.confirm({
      content: `Are you sure you want to delete the region ${region.name.trim()}?`,
      title: 'Delete this region?',
      cancelText: 'No',
      okText: 'Yes, Delete',
      okType: 'danger',
      okButtonProps: { style: styles.deleteButton },
      icon: <CloseCircleFilled style={styles.errorIcon} />,
      onOk: () => sendDeleteRegion(region.id),
    });
  };

  const handleSubmit = async (region: AddEditRegion) => {
    if (regionAction === RegionAction.ADD) {
      const payload: RequestCreateRegion = {
        name: region.name,
      }
      sendCreateRegion(payload);
    } else {
      const payload: RequestRegionUpdate = {
        regionID: region.id,
        name: region.name,
      }
      sendUpdateRegion(payload);
    }
  };

  const regionCreationEnabled = !authUtilities.minimumRequiredRole(
    authUtilities.ROLE_SETS.TEAM_MEMBER,
  );

  const regionLabel = 'Region';

  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <ComponentHeader
            title={'Region Management'}
            options={
              <Button
                key="1"
                type="primary"
                disabled={regionCreationEnabled}
                onClick={handleAddRegionClick}
              >
                Add Region
              </Button>
            }
          />
          <div styleName="table-container">
            <div styleName="search-count-row">
              <div styleName="search-bar">
                <Input
                  placeholder="Search Region"
                  onChange={(event) => handleSearch(event.target.value)}
                  prefix={<SearchOutlined style={styles.icon} />}
                  defaultValue={input.name}
                />
              </div>
              <div styleName="table-count">
                <span>{`${Math.min(
                  (isNotNull(data) ? data.length : 0) - input.offset,
                  input.limit,
                )} out of ${isNotNull(data) ? data.length : 0} regions`}</span>
              </div>
            </div>
            <div>
              <RegionsTable
                regions={data}
                pagination={{ total: isNotNull(data) ? data.length : 0}}
                input={input}
                setInput={setInput}
                loading={loading || sending}
                onEditRegionClick={handleEditRegionClick}
                onDeleteRegionClick={handleDeleteRegionClick}
                onChange={handlePagination}
              />
            </div>
          </div>
        </PrimaryContentPane>
        <AddEditRegionModal
          onSubmit={handleSubmit}
          regionLabel={regionLabel}
          onCancel={() => setIsModalOpen(false)}
          loading={loading}
          region={regionName}
          action={regionAction}
          visible={isModalOpen}
        />
      </BasePane>
    </ContentLayout>
  );
};

export default Regions;
