import React, { useContext } from 'react';

import Contacts from '@totem/components/buildingDetail/container/Contacts';
import CommonSidebar from '@totem/components/common/sidebar/Sidebar';
import UserProfileContext from '@totem/components/UserProfileContext';

import BuildingContext from '@totem/components/buildingDetail/container/BuildingContext';

import BuildingInfo from './BuildingInfo';

import './sidebar.css';
import { isNotNull } from '@totem/utilities/common';
import SideBarNotes from '@totem/components/media/notes/SideBarNotes';

const Sidebar = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { data: building } = useContext(BuildingContext);

  const {
    buildingContact: showBuildingContact,
    buildingNote: showBuildingNote,
    // remoteAccess: showRemoteAccess,
  } = userProfile.organization?.preferences?.features;

  return (
    <CommonSidebar tabLabel="Details">
      <div styleName="sidebar-content">
        <BuildingInfo />
        {showBuildingContact && <Contacts />}
        {showBuildingNote && isNotNull(building) && (
          <SideBarNotes
            title="Building Notes"
            noContentMessage="Attach a note to your building to keep track if site specific information."
            subjectId={building.id}
          />
        )}
      </div>
    </CommonSidebar>
  );
};

export default Sidebar;
