import { FilterOptions } from '@totem/components/common/filterAutoComplete/FilterAutoCompleteTypes';
import {
  Attribute,
  ForeignKey,
  Note,
  ObjectReference,
  PagingResults,
} from '@totem/types/common';

export const MIN_DATE_TIME_STRING: string = '0001-01-01T00:00:00.000+00:00';

export interface TicketingFilterOptions {
  type: FilterOptions;
  priority: FilterOptions;
  status: FilterOptions;
  subStatus: FilterOptions;
  categories: FilterOptions;
  building: FilterOptions;
  organization: FilterOptions;
  device: FilterOptions;
}

export type TicketingConnectionInput = {
  from?: string;
  pageSize?: number;
  page?: number;
  sortField?: string;
  sortDirection?: string;
  ids?: string[];
  regionId?: string[];
  buildingId?: string[];
  controlSystemId?: string[];
  deviceId?: string[];
  queueId?: string[];
  type?: string[];
  status?: string[];
  category?: string[];
  priority?: string[];
  reference?: string[];
};

export type TicketingFilters = {
  ids?: string[];
  regionId?: string[];
  buildingId?: string[];
  controlSystemId?: string[];
  deviceId?: string[];
  queueId?: string[];
  type?: string[];
  status?: string[];
  category?: string[];
  priority?: string[];
  reference?: string[];
  ticketNumber?: string;
};

export interface TicketQueryResults {
  tickets: TicketQueryResult[];
  paging: PagingResults;
}

export interface TicketQueryResult {
  ticket: Ticket;
  organization: ObjectReference;
  region: ObjectReference;
  building: ObjectReference;
  controlSystem: ObjectReference;
  device: ObjectReference;
  relatedTickets: RelatedTicket[];
}

export interface RelatedTicket {
  relationship: string;
  ticket: TicketQueryResult;
}

export interface Ticket {
  id: string;
  number: string;
  organizationId: string;
  regionId: string;
  buildingId: string;
  controlSystemId: string;
  deviceId: string;
  type: string;
  category: string;
  subCategory: string;
  status: string;
  subStatus: string;
  priority: string;
  summary: string;
  description: string;
  activity: Activity[];
  partiesInvolved: PartyInvolved[];
  queueAssignments: QueueAssignment[];
  assignments: Assignment[];
  createdByType: string;
  createdByName: string;
  createdById: string;
  createdAt: string;
  updatedAt: string;
  closedAt: string;
  keys: ForeignKey[];
  attributes: TicketAttribute[];
}

export interface TicketAttribute {
  source: string;
  name: string;
  dataType: string;
  value: string;
}

export interface Activity {
  id: string;
  type: ActivityType;
  notes: Note;
  attributes: Attribute[];
  createdByType: string;
  createdByName: string;
  createdById: string;
  createdAt: string;
  isPrivate: boolean;
  keys: ForeignKey[];
}

export interface ActivityType {
  id: string;
  code: string;
  display: string;
}

export interface PartyInvolved {
  id: string;
  type: string;
  status: string;
  name: string;
  email: string;
  phone: string;
  involvementStart: string;
  involvementEnd: string;
  createdByType: string;
  createdByName: string;
  createdById: string;
  keys: ForeignKey[];
}

export interface QueueAssignment {
  id: string;
  queueId: string;
  status: string;
  name: string;
  startTime: string;
  endTime: string;
  createdByType: string;
  createdByName: string;
  createdById: string;
  keys: ForeignKey[];
}

export interface Assignment {
  id: string;
  status: string;
  name: string;
  email: string;
  startTime: string;
  endTime: string;
  createdByType: string;
  createdByName: string;
  createdById: string;
  keys: ForeignKey[];
}

export interface ActivityConfiguration {
  id: string;
  code: string;
  display: string;
  createRequirements: ActivityCreateRequirements;
  viewRequirements: ActivityViewRequirements;
  extendedFieldGroups: ActivityExtendedFieldGroup[];
}

export interface ActivityCreateRequirements {
  roles: number[];
  securityGroups: string[];
  statuses: string[];
  subStatuses: string[];
}

export interface ActivityViewRequirements {
  roles: number[];
  securityGroups: string[];
}

export interface ActivityExtendedFieldGroup {
  name: string;
  display: string;
  extendedFields: ActivityExtendedField[];
}

export interface ActivityExtendedField {
  name: string;
  label: string;
  dataType: string;
  isRequired: boolean;
  display: boolean;
  options: ActivityExtendedFieldOption[];
}

export interface ActivityExtendedFieldOption {
  display: string;
  value: string;
}

export interface ExtendedFieldValue {
  key: string;
  name: string;
  label: string;
  value: string;
  isValid: boolean;
  display: boolean;
  id: string;
}

export interface TicketCategory {
  name: string;
  children?: TicketCategory[];
}

export interface TicketAgingValue {
  label: string;
  value: number;
}
