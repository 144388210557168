import React from 'react';

import { Question } from '@totem/types/policyAudit';

import './policyAuditDetail.css';

interface Props {
  question: Question;
  onChange: (key: string, value: string) => void;
  disabled: boolean;
}

const getStyleName = (selected: boolean, disabled: boolean): string => {
  let styleName = 'enum-option';

  if (selected) {
    styleName += ' selected';
  }

  if (disabled) {
    styleName += ' disabled';
  } else {
    styleName += ' enabled';
  }

  return styleName;
};

const ScoringQuestion: React.FC<Props> = ({
  question,
  onChange,
  disabled,
}: Props) => {
  const { answer, options } = question;

  return (
    <>
      {options.map((option) => {
        const selected = answer && answer.value === option.value;

        return (
          <button
            styleName={getStyleName(selected, true)}
            key={option.value}
            onClick={() => {}}
          >
            {option.value}
          </button>
        );
      })}
    </>
  );
};

export default ScoringQuestion;
