import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';

import DeleteControlSystemModal from '@totem/components/common/deleteControlSystemModal/DeleteControlSystemModal';

import ControlSystemContext from '@totem/components/controlSystemDetail/container/ControlSystemContext';

import ControlSystemEditModal from './ControlSystemEditModal';
import { isNotNull } from '@totem/utilities/common';

const ControlSystemActionMenu = () => {
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const { data, isLoading, requestRefresh } = useContext(ControlSystemContext);

  const onDelete = () => {
    if (isNotNull(data) && isNotNull(data.building))
    navigate(`/dashboard/buildings/${data.building.id}`);
  };

  const menuItems: MenuProps['items'] = [
    {
      key: 'edit_system',
      label: 'Edit System',
      title: 'Edit System',
      icon: <EditOutlined />,
    },
    {
      key: 'remove_system',
      label: 'Remove System',
      title: 'Remove System',
      icon: <DeleteOutlined />,
    },
  ];

  const onMenuClicked = ({ key }) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case 'edit_system':
        setEditModalOpen(true);
        break;
      case 'remove_system':
        setDeleteModalOpen(true);
        break;
    }
  };

  return (
    <div>
      <Dropdown
        menu={{ items: menuItems, onClick: onMenuClicked }}
        trigger={['click']}
      >
        <Button shape="circle" icon={<EllipsisOutlined />} />
      </Dropdown>
      <DeleteControlSystemModal
        controlSystem={data}
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        onComplete={onDelete}
        loading={isLoading}
      />
      <ControlSystemEditModal
        controlSystem={data}
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          requestRefresh();
        }}
      />
    </div>
  );
};

export default ControlSystemActionMenu;
