import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import * as R from 'ramda';

import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import { Criticality } from '@totem/types/criticality';
import { FindingGroup } from '@totem/types/finding';
import { LevelOfEffort } from '@totem/types/levelOfEffort';
import { getCriticalityBadge } from '@totem/utilities/criticalityUtilities';
import { parseIntOrReturnValue } from '@totem/utilities/numberUtilities';
import { stringifyArray } from '@totem/utilities/tableFilterUtilities';
import { Finding } from '@totem/components/surveyV2/policyAuditDetails/types';

import { Filters } from './Report';

import './policyAuditDetail.css';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

interface Props {
  findings: Finding[];
  filters: Filters;
  onFilterChange: (filters: Filters) => void;
}

const INCLUDED_CRITICALITIES = [
  Criticality.Info,
  Criticality.Critical,
  Criticality.High,
  Criticality.Medium,
  Criticality.Low,
  Criticality.Compliant,
];

const getGroupFilters = (findings: Finding[]) =>
  findings
    .reduce(
      (acc: string[], { category }): string[] =>
        acc.includes(category) ? acc : [...acc, category],
      [],
    )
    .map(group => ({
      text: group,
      value: group,
    }));

const getLoeFilters = (findings: Finding[]) =>
  findings
    .reduce(
      (acc: LevelOfEffort[], { levelOfEffort }: Finding) =>
        acc.includes(levelOfEffort) ? acc : [...acc, levelOfEffort],
      [],
    )
    .map((loe) => ({
      text: LevelOfEffort[loe],
      value: loe,
    }));

const getFindings = (findings: Finding[], filters: Filters): Finding[] => {
  const { criticality, group, levelOfEffort } = filters;

  return findings.filter((finding) => {
    if (criticality.length && !criticality.includes(finding.criticality)) {
      return false;
    }

    if (group.length && !group.includes(finding.category)) {
      return false;
    }

    if (
      levelOfEffort.length &&
      !levelOfEffort.includes(finding.levelOfEffort)
    ) {
      return false;
    }

    return true;
  });
};

const FindingsTable: React.FC<Props> = ({
  findings,
  filters,
  onFilterChange,
}: Props) => {
  const dispatchNotification = () => {
    notification.success({
      message: 'Audit finding copied!',
    });
  };

  const columns: ColumnProps<any>[] = [
    {
      title: 'Finding Description',
      dataIndex: 'findingDescription',
      render: (description) => description || 'N/A',
    },
    {
      title: 'Criticality',
      dataIndex: 'criticality',
      width: '17rem',
      render: getCriticalityBadge,
      filters: INCLUDED_CRITICALITIES.map((criticality) => ({
        text: getCriticalityBadge(criticality),
        value: criticality,
      })),
      filterMultiple: true,
      filteredValue: stringifyArray(filters.criticality),
    },
    {
      title: 'Finding Group',
      dataIndex: 'group',
      width: '17rem',
      render: (group: FindingGroup) => FindingGroup[group],
      sorter: (first: Finding, second: Finding) =>
        sortStringAscending(first.category, second.category),
      filters: getGroupFilters(findings),
      filterMultiple: true,
      filteredValue: stringifyArray(filters.group),
    },
    {
      title: 'Effort Level',
      dataIndex: 'levelOfEffort',
      width: '17rem',
      render: (loe: LevelOfEffort) => LevelOfEffort[loe],
      sorter: (first: Finding, second: Finding) =>
        LevelOfEffort[first.levelOfEffort] < LevelOfEffort[second.levelOfEffort]
          ? -1
          : 1,
      filters: getLoeFilters(findings),
      filterMultiple: true,
      filteredValue: stringifyArray(filters.levelOfEffort),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '5rem',
      render: (_, finding: Finding) => (
        <div
          className="center-table-cell"
          onClick={(event) => event.stopPropagation()}
        >
          <CopyToClipboard
            text={[finding.findingDescription, finding.policy, finding.resolution].join('\n')}
            onCopy={dispatchNotification}
          >
            <Tooltip title="Copy Finding" placement="top">
              <Button type="primary" shape="circle" icon={<CopyOutlined />} />
            </Tooltip>
          </CopyToClipboard>
        </div>
      ),
    },
  ];

  const handleChange = (pagination: any, updatedFilters: any) => {
    const parsedFilters = R.map((value: string[]) =>
      R.isNil(value) ? [] : R.map(parseIntOrReturnValue, value),
    )(updatedFilters);

    // @ts-ignore
    onFilterChange(parsedFilters);
  };

  const data = getFindings(findings, filters);

  return (
    <Table
      rowKey="question"
      columns={columns}
      dataSource={data}
      onChange={handleChange}
      pagination={false}
    />
  );
};

export default FindingsTable;
