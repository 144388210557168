import { jwtDecode } from 'jwt-decode';
import Cookies from 'universal-cookie';

import colors from '@totem/styles/colors';

const getCookies = () => new Cookies();

export const USER_ROLES = [
  {
    label: 'Tenant',
    value: -2,
  },
  {
    label: 'Vendor',
    value: -1,
  },
  {
    label: 'Collaborator',
    value: 0,
  },
  {
    label: 'Team Member',
    value: 1,
  },
  {
    label: 'Admin',
    value: 2,
  },
  {
    label: 'Systems Administrator',
    value: 3,
  },
];

export const getRoleString = (roleId) => {
  switch (roleId) {
    case -2:
      return 'Tenant';
    case -1:
      return 'Vendor';
    case 0:
      return 'Collaborator';
    case 1:
      return 'Team Member';
    case 2:
      return 'Admin';
    case 3:
      return 'Systems Administrator';
    default:
      return 'Collaborator';
  }
};

export const getRoleStyle = (roleId) => {
  switch (roleId) {
    case 0:
      return {
        fontWeight: 700,
        color: colors.neutral.dark,
      };
    case 1:
      return {
        fontWeight: 700,
        color: colors.brand.navy,
      };
    case 2:
      return {
        fontWeight: 700,
        color: colors.brand.green,
      };
    case 3:
      return {
        fontWeight: 700,
        color: colors.brand.red,
      };
    default:
      return {
        fontWeight: 700,
        color: colors.neutral.dark,
      };
  }
};

export function getToken() {
  const accessToken = getCookies().get('access_token');
  return accessToken;
}

export const getAccountPayloadSync = () => {
  return jwtDecode(getToken());
};

const setToken = (token) => {
  getCookies().set('access_token', token, { path: '/' });
};

const clearToken = () => {
  const cookies = getCookies();
  const allCookies = cookies.getAll();
  Object.keys(allCookies).forEach((cookie) => cookies.remove(cookie));
};

export function logout() {
  const cookies = getCookies();
  const allCookies = cookies.getAll();
  Object.keys(allCookies).forEach((cookie) => cookies.remove(cookie));
}

const accountUtilities = {
  getRoleString,
  getRoleStyle,
  getAccountPayloadSync,
  getToken,
  logout,
  setToken,
  clearToken,
  USER_ROLES,
};

export default accountUtilities;
