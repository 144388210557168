import ContentLayout from '@totem/components/ContentLayout';
import BasePane from '@totem/components/BasePane';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import CardBody from '@totem/components/CardBody';
import React from 'react';
import EventContainer from '@totem/components/events/details/eventContainer';
import { useParams } from 'react-router-dom';
import EventDetailsWrapper from '@totem/components/events/details/eventDetailsWrapper';

const EventDetailsPage = () => {
  const { id } = useParams();

  return (
    <ContentLayout>
      <EventContainer eventId={id}>
        <BasePane>
          <PrimaryContentPane>
            <PrimaryContentPane>
              <CardBody>
                <EventDetailsWrapper />
              </CardBody>
            </PrimaryContentPane>
          </PrimaryContentPane>
        </BasePane>
      </EventContainer>
    </ContentLayout>
  );
};

export default EventDetailsPage;
