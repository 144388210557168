import React from 'react';
import { AndroidOutlined, AppleOutlined } from '@ant-design/icons';
import { Col, Flex, QRCode, Row } from 'antd';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import PageSectionBody from '@totem/components/PageSectionBody';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';

const MobileApplications = () => {
  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <PageSectionBody>
            <p>
              Download our latest mobile app by clicking or scanning the
              appropriate QR code below:
            </p>
            <Row>
              <Col span={12}>
                <Flex justify={'center'} align={'center'}>
                  <h1>
                    <a
                      href={
                        "'https://apps.apple.com/us/app/intellinet/id6737702944'"
                      }
                    >
                      <AppleOutlined /> Apple App Store
                    </a>
                  </h1>
                </Flex>
              </Col>
              <Col span={12}>
                <Flex justify={'center'} align={'center'}>
                  <h1>
                    <a
                      href={
                        'https://play.google.com/store/apps/details?id=com.intelligentbuildings.intellinet'
                      }
                    >
                      <AndroidOutlined /> Google Play Store
                    </a>
                  </h1>
                </Flex>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <a
                  href={'https://apps.apple.com/us/app/intellinet/id6737702944'}
                >
                  <Flex justify={'center'} align={'center'}>
                    <QRCode
                      size={256}
                      style={{
                        height: 'auto',
                        maxWidth: '50%',
                        width: '300px',
                      }}
                      value={
                        'https://apps.apple.com/us/app/intellinet/id6737702944'
                      }
                    />
                  </Flex>
                </a>
              </Col>
              <Col span={12}>
                <a
                  href={
                    'https://play.google.com/store/apps/details?id=com.intelligentbuildings.intellinet'
                  }
                >
                  <Flex justify={'center'} align={'center'}>
                    <QRCode
                      size={256}
                      style={{
                        height: 'auto',
                        maxWidth: '50%',
                        width: '300px',
                      }}
                      value={
                        'https://play.google.com/store/apps/details?id=com.intelligentbuildings.intellinet'
                      }
                    />
                  </Flex>
                </a>
              </Col>
            </Row>
          </PageSectionBody>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default MobileApplications;
