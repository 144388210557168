import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';
import * as R from 'ramda';

import ReportContext from '@totem/components/controlSystemReport/ControlSystemReportContext';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import {
  ControlSystemCreateInput,
  ControlSystemType,
} from '@totem/types/controlSystem';
import {
  controlSystemTypeMap,
  controlSystemTypeOptions,
} from '@totem/utilities/controlSystemsUtilities';
import GenericAutoComplete from '@totem/components/common/autoCompletes/GenericAutoComplete';
import { isNotNull } from '@totem/utilities/common';
import { AutoCompleteValue } from '@totem/types/autoComplete';
import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import { CONTROL_SYSTEMS_ENDPOINT } from '@totem/utilities/endpoints';
import { getToken } from '@totem/utilities/accountUtilities';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';
import { ControlSystemDTO } from '@totem/components/controlSystemReport/dataTypes';
import { emptyId } from '@totem/utilities/userUtilities';
import UserProfileContext from '@totem/components/UserProfileContext';
import dayjs from 'dayjs';

const FormItem = Form.Item;
const { Option } = Select;

interface FormErrors {
  building?: string;
  name?: string;
  systemType?: string;
  email?: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
}

const initialState = {
  input: {
    regionId: '',
    buildingId: '',
    name: '',
    systemType: 0,
  },
};

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
    width: '100%',
  },
  checkbox: {
    fontSize: '12px',
    padding: '1rem 0',
  },
  datePicker: {
    width: '100%',
  },
};

const ControlSystemCreateModal = ({ open, onClose }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const { onAction } = useContext(ReportContext);
  const [isSending, setIsSending] = useState(false);
  const [errors, setErrors] = useState<FormErrors>({});
  const [buildingSearch, setBuildingSearch] = useState<AutoCompleteValue>({ display: '', value: EMPTY_ID  });
  const [input, setInput] = useState<ControlSystemCreateInput>(
    initialState.input,
  );

  useEffect(() => {
    if (!open) {
      setErrors({});
      setBuildingSearch({ display: '', value: EMPTY_ID  });
      setInput(initialState.input);
    }
  }, [open]);

  const sendControlSystemCreate = useCallback(async (request: ControlSystemDTO) => {
    if (!isSending) {
      setIsSending(true);
      fetch(`${CONTROL_SYSTEMS_ENDPOINT}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request),
      })
        .then(res => {
          setIsSending(false);
          CheckResponseShowError(res);
          onClose();
          onAction('refresh');
        });
    }
  }, []);

  const isValidForm = (): boolean => {
    const formErrors: FormErrors = {};

    if (R.isEmpty(input.buildingId)) {
      errors.building = 'Please select a building';
    }

    if (!input.name) {
      formErrors.name = 'Please enter a name';
    }

    if (R.isNil(input.systemType)) {
      formErrors.systemType = 'Please select a control system type';
    }

    setErrors(formErrors);
    return R.isEmpty(formErrors);
  };

  const handleChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [name]: value });
  };

  const handleTypeChange = (systemType: ControlSystemType) => {
    const type = controlSystemTypeMap[systemType];
    setInput({ ...input, name: type.text, systemType });
  };

  const handleBuildingChange = (building: AutoCompleteValue) => {
    setBuildingSearch(building);
    setInput({
      ...input,
      buildingId: building.value,
      regionId: isNotNull(building.parent) ? building.parent.value : EMPTY_ID,
    });
  };

  const handleSubmit = async () => {
    if (!isValidForm()) {
      return;
    }

    const payload: ControlSystemDTO = {
      id: emptyId,
      organizationId: userProfile.organization.id,
      regionId: isNotNull(buildingSearch) && isNotNull(buildingSearch.parent) ? buildingSearch.parent.value : emptyId,
      buildingId: isNotNull(buildingSearch) ? buildingSearch.value : emptyId,
      parentId: emptyId,
      name: input.name,
      systemType: input.systemType,
      manufacturer: input.manufacturer,
      model: input.model,
      acronisMachines: [],
      isDeleted: false,
      createdBy: userProfile.id,
      createdAt: dayjs().toISOString(),
      updatedBy: userProfile.id,
      updatedAt: dayjs().toISOString(),
      pointsOfContact: [],
    };

    sendControlSystemCreate(payload);
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={<ModalTitle>Add a Control System</ModalTitle>}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" loading={isSending} onClick={handleSubmit}>
            Add Now
          </Button>
        </ModalFooter>
      }
      confirmLoading={isSending}
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="Building"
          colon={false}
          validateStatus={R.isNil(errors.building) ? 'success' : 'error'}
          help={R.isNil(errors.building) ? null : errors.building}
          style={styles.formItem}
        >
          <GenericAutoComplete
            type={'Building'}
            label={''}
            limit={15}
            onSelect={handleBuildingChange}
            onChange={(search) => {
              setBuildingSearch({display: search, value: EMPTY_ID})
            }}
            allowClear={true}
            allowFreeform={false}
            selectedValue={isNotNull(buildingSearch) ? {display: buildingSearch.display, value: buildingSearch.value} : {display: '', value: ''}}
          />
        </FormItem>
        <FormItem
          label="System Type"
          validateStatus={!errors.systemType ? 'success' : 'error'}
          help={!errors.systemType ? null : errors.systemType}
          colon={false}
          style={styles.formItem}
        >
          <Select
            showSearch
            filterOption
            onChange={handleTypeChange}
            value={input.systemType}
            optionFilterProp="children"
            disabled={isSending}
          >
            {controlSystemTypeOptions.map((option) => {
              return (
                <Option key={option.value} value={option.value}>
                  {option.text}
                </Option>
              );
            })}
          </Select>
        </FormItem>
        <FormItem
          label="System Name"
          colon={false}
          validateStatus={!errors.name ? 'success' : 'error'}
          help={!errors.name ? null : errors.name}
          style={styles.formItem}
        >
          <Input
            disabled={isSending}
            value={input.name}
            name="name"
            onChange={handleChange}
            type="search"
          />
        </FormItem>
        <FormItem label="Manufacturer" colon={false} style={styles.formItem}>
          <Input
            disabled={isSending}
            value={input.manufacturer}
            name="manufacturer"
            onChange={handleChange}
            type="search"
          />
        </FormItem>
        <FormItem label="Model" colon={false} style={styles.formItem}>
          <Input
            disabled={isSending}
            value={input.model}
            name="model"
            onChange={handleChange}
            type="search"
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default ControlSystemCreateModal;
