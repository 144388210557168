import { MaintenanceWindow, MaintenanceWindowDetails } from '@totem/components/maintenanceWindows/types';
import dayjs from 'dayjs';

export const getStatusFromDetails = (record: MaintenanceWindowDetails) => {
  const startTime = dayjs(record.scheduledStartTime);
  const endTime = dayjs(record.scheduledEndTime);
  const currentTime = dayjs();

  if (record.status === 'OPEN') {
    if (currentTime.isBefore(startTime)) {
      return 'PENDING';
    }
    if (currentTime.isAfter(endTime)) {
      return 'CLOSED';
    }
  }

  return record.status;
};

export const getStatus = (record: MaintenanceWindow) => {
  return getStatusFromDetails(record.details);
};
