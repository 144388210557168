import React, { useContext } from 'react';
import { Row } from 'antd';

import HeaderItem from '@totem/components/HeaderItem';
import FireWallBypassApprovalCell from '@totem/components/maintenanceWindows/detail/fireWallBypassApprovalCell';
import MaintenanceWindowDetailsContext from '@totem/components/maintenanceWindows/detail/maintenanceWindowDetailContext';
import MaintenanceWindowDetails from '@totem/components/maintenanceWindows/maintenanceWindowDetails';
import { getStatus } from '@totem/components/maintenanceWindows/utilities';
import { formatDate, isNotNull } from '@totem/utilities/common';

const DetailLayout = () => {
  const { data, loading, onAction } = useContext(
    MaintenanceWindowDetailsContext,
  );

  return (
    <>
      {isNotNull(data) && (
        <>
          <Row>
            <HeaderItem
              title={'Scheduled Start Time'}
              value={formatDate(data.details.scheduledStartTime)}
              span={8}
            />
            <HeaderItem
              title={'Scheduled End Time'}
              value={formatDate(data.details.scheduledEndTime)}
              span={8}
            />
            <HeaderItem title={'Status'} value={getStatus(data)} span={8} />
          </Row>
          <Row>
            <HeaderItem
              title={'Requires Firewall Bypass'}
              value={data.details.requiresBypass ? 'Yes' : 'No'}
              span={8}
            />
            <FireWallBypassApprovalCell
              title={'Firewall Bypass Approved'}
              value={data.details.bypassApproved ? 'Yes' : 'No'}
              span={8}
            />
            <HeaderItem
              title={'Site Contact Name'}
              value={data.details.siteContactName}
              span={8}
            />
          </Row>
          <Row>
            <HeaderItem
              title={'Reason'}
              value={data.details.reason}
              span={16}
            />
            <HeaderItem
              title={'Site Contact Phone'}
              value={data.details.siteContactPhone}
              span={8}
            />
          </Row>
          <Row>
            <HeaderItem
              title={'Description'}
              value={data.details.description}
              span={16}
            />
            <HeaderItem
              title={'Site Contact Email'}
              value={data.details.siteContactEmail}
              span={8}
            />
          </Row>
          <br />
          <br />
          <MaintenanceWindowDetails
            maintenanceWindow={data}
            loading={loading}
            refetch={() => onAction('refresh', null)}
          />
        </>
      )}
    </>
  );
};

export default DetailLayout;
