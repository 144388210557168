import React, { useContext, useState } from 'react';
import { Tabs, TabsProps } from 'antd';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import AttributesTable from '@totem/components/devices/deviceDetails/AttributesTable';
import ContractLinesTable from '@totem/components/devices/deviceDetails/ContractLinesTable';
import DeviceContext from '@totem/components/devices/deviceDetails/DeviceContext';
import DeviceKeysTable from '@totem/components/devices/deviceDetails/deviceKeys/DeviceKeysTable';
import DeviceVariablesTable from '@totem/components/devices/deviceDetails/deviceVariables/DeviceVariablesTable';
import { hasVariables } from '@totem/components/devices/deviceDetails/deviceVariables/utilities';
import DriveCards from '@totem/components/devices/deviceDetails/DriveCards';
import EventsTable from '@totem/components/devices/deviceDetails/EventsTable';
import MemorySlotsTable from '@totem/components/devices/deviceDetails/MemorySlotTable';
import NetworkDetails from '@totem/components/devices/deviceDetails/NetworkDetails';
import PeripheralsTable from '@totem/components/devices/deviceDetails/PeripheralsTable';
import VulnerabilityReportTable from '@totem/components/devices/deviceDetails/softwareVulnerabilityTable';
import VideoCardsTable from '@totem/components/devices/deviceDetails/VideoCardTable';
import NetworkContainer from '@totem/components/devices/network/networkContainer';
import NetworkTable from '@totem/components/devices/network/networkTable';
import { hasNetworkInterfaces } from '@totem/components/devices/network/utilities';
import OSPatchInstallsContainer from '@totem/components/devices/os-patch-installs/osPatchInstallContainer';
import OSPatchInstallsTable from '@totem/components/devices/os-patch-installs/osPatchInstallTable';
import { hasOSPatchInstalls } from '@totem/components/devices/os-patch-installs/utilities';
import OSPatchContainer from '@totem/components/devices/os-patches/osPatchContainer';
import OSPatchTable from '@totem/components/devices/os-patches/osPatchTable';
import { hasOSPatches } from '@totem/components/devices/os-patches/utilities';
import ProcessorsContainer from '@totem/components/devices/processors/processorsContainer';
import ProcessorsTable from '@totem/components/devices/processors/processorsTable';
import { hasProcessors } from '@totem/components/devices/processors/utilities';
import ServiceContainer from '@totem/components/devices/services/serviceContainer';
import ServicesTable from '@totem/components/devices/services/servicesTable';
import { hasServices } from '@totem/components/devices/services/utilities';
import SoftwarePatchInstallsContainer from '@totem/components/devices/softrware-patch-installs/softwarePatchInstallContainer';
import SoftwarePatchInstallsTable from '@totem/components/devices/softrware-patch-installs/softwarePatchInstallsTable';
import { hasSoftwarePatchInstalls } from '@totem/components/devices/softrware-patch-installs/utilities';
import SoftwareContainer from '@totem/components/devices/software/softwareContainer';
import SoftwareTable from '@totem/components/devices/software/softwareTable';
import { hasSoftware } from '@totem/components/devices/software/utilities';
import SoftwarePatchContainer from '@totem/components/devices/software-patches/softwarePatchesContainer';
import SoftwarePatchesTable from '@totem/components/devices/software-patches/softwarePatchesTable';
import { hasSoftwarePatches } from '@totem/components/devices/software-patches/utilities';
import { hasVolumes } from '@totem/components/devices/volumes/utilities';
import VolumeCards from '@totem/components/devices/volumes/volumeCards';
import VolumesContainer from '@totem/components/devices/volumes/volumeContainer';
import VolumeTable from '@totem/components/devices/volumes/volumeTable';
import TicketList from '@totem/components/events/detailView/TicketList';
import VulnerabilityReportContext from '@totem/components/software/vulnerabilityReport/vulnerabilityReportContext';
import TabTitle from '@totem/components/TabTitle';
import HeaderLabel from '@totem/components/ticketing/details/headerLabel';
import TicketEmbeddedContainer from '@totem/components/ticketing/listContainer/TicketEmbeddedContainer';
import UserProfileContext from '@totem/components/UserProfileContext';
import authUtilities from '@totem/utilities/authUtilities';
import { isNotNull } from '@totem/utilities/common';
import { isIBUser } from '@totem/utilities/security';
import {
  getUserRole,
  hasActiveService,
  isMemberOfAny,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';
import colors from '@totem/styles/colors';
import NetworkLinksContainer from '@totem/components/devices/deviceDetails/networkLinks/networkLinksContainer';
import NetworkGraphJSX from '@totem/components/buildingDetail/network/NetworkGraphJSX';
import MaintenanceWindows from '@totem/components/devices/deviceDetails/maintenanceWindows';

const COLOR_HARDWARE = '#5188bb';
const COLOR_SOFTWARE = '#0d629a';
const COLOR_SUPPORT = '#0d3157';
const COLOR_SELECTED = colors.branding.accent;

const DeviceTabs = () => {
  const { deviceData } = useContext(DeviceContext);
  const { userProfile } = useContext(UserProfileContext);
  const { data } = useContext(VulnerabilityReportContext);

  const [activeTab, setActiveTab] = useState<string>('attributes');

  const canSeeKeys =
    userProfile.organization.role === 3 && isIBUser(userProfile);
  const hasAdvancedManagement =
    hasActiveService(userProfile, 'advanced_management') ||
    getUserRole(userProfile) === 3;

  const hasNozomiKey = canSeeKeys && isNotNull(deviceData) ? deviceData.device.keys.findIndex((chk) => chk.type === 'NOZOMI_VANTAGE_ID') >= 0 : false;

  const canSeeContracts =
    isMemberOfAny(userProfile, ['contract_viewer', 'contract_creator'], {
      regionId:
        typeof deviceData !== 'undefined' && deviceData !== null
          ? deviceData.device.regionId
          : EMPTY_ID,
      buildingId:
        typeof deviceData !== 'undefined' && deviceData !== null
          ? deviceData.device.buildingId
          : EMPTY_ID,
      controlSystemId:
        typeof deviceData !== 'undefined' && deviceData !== null
          ? deviceData.device.controlSystemId
          : EMPTY_ID,
    }) || authUtilities.isSystemsAdmin();

  const userCanSeeTicketing =
    isMemberOfAny(
      userProfile,
      [
        'ticket_viewer',
        'ticket_updater',
        'ticket_creator',
        'ticket_technician',
        'ticket_administrator',
      ],
      securityGroupCheckConstraintEmpty,
    ) || getUserRole(userProfile) === 3;

  const getTabItems = () => {
    const tabItems: TabsProps['items'] = [];

    tabItems.push({
      key: 'attributes',
      label: (
        <TabTitle>
          <span style={{color: activeTab === 'attributes' ? COLOR_SELECTED : COLOR_HARDWARE}}>Attributes</span>
        </TabTitle>
      ),
      children: <AttributesTable />,
    });

    if (hasProcessors(deviceData)) {
      tabItems.push({
        key: 'processors',
        label: (
          <TabTitle>
            <span style={{color: activeTab === 'processors' ? COLOR_SELECTED : COLOR_HARDWARE}}>Processors</span>
          </TabTitle>
        ),
        children: (
          <ProcessorsContainer id={deviceData.device.id}>
            <ProcessorsTable />
          </ProcessorsContainer>
        ),
      });
    }

    if (hasVolumes(deviceData)) {
      tabItems.push({
        key: 'drives',
        label: (
          <TabTitle>
            <span style={{color: activeTab === 'drives' ? COLOR_SELECTED : COLOR_HARDWARE}}>Volumes</span>
          </TabTitle>
        ),
        children: (
          <VolumesContainer id={deviceData.device.id}>
            <VolumeCards />
            <br />
            <VolumeTable />
          </VolumesContainer>
        ),
      });
    }

    if (
      !hasVolumes(deviceData) &&
      hasAdvancedManagement &&
      deviceData.device.drives !== null &&
      deviceData.device.drives.length > 0
    ) {
      tabItems.push({
        key: 'drives',
        label: (
          <TabTitle>
            <span style={{color: activeTab === 'drives' ? COLOR_SELECTED : COLOR_HARDWARE}}>Volumes</span>
          </TabTitle>
        ),
        children: <DriveCards />,
      });
    }

    if (hasServices(deviceData)) {
      tabItems.push({
        key: 'services',
        label: (
          <TabTitle>
            <span style={{color: activeTab === 'services' ? COLOR_SELECTED : COLOR_HARDWARE}}>Services</span>
          </TabTitle>
        ),
        children: (
          <ServiceContainer id={deviceData.device.id}>
            <ServicesTable />
          </ServiceContainer>
        ),
      });
    }

    if (
      hasAdvancedManagement &&
      deviceData.device.memorySlots !== null &&
      deviceData.device.memorySlots.length > 0
    ) {
      tabItems.push({
        key: 'memory',
        label: (
          <TabTitle>
            <span style={{color: activeTab === 'memory' ? COLOR_SELECTED : COLOR_HARDWARE}}>Memory</span>
          </TabTitle>
        ),
        children: <MemorySlotsTable />,
      });
    }

    tabItems.push({
      key: 'network',
      label: (
        <TabTitle>
          <span style={{color: activeTab === 'network' ? COLOR_SELECTED : COLOR_HARDWARE}}>Network</span>
        </TabTitle>
      ),
      children: (
        <>
          <NetworkDetails />
          {hasNetworkInterfaces(deviceData) && (
            <NetworkContainer id={deviceData.device.id}>
              <br />
              <br />
              <NetworkTable />
            </NetworkContainer>
          )}
        </>
      ),
    });

    if (hasNozomiKey) {
      tabItems.push({
        key: 'network_links',
        label: (
          <TabTitle>
            <span style={{color: activeTab === 'network_links' ? COLOR_SELECTED : COLOR_HARDWARE}}>Links</span>
          </TabTitle>
        ),
        children: (
          <>
            <NetworkLinksContainer deviceId={deviceData.device.id}>
              <NetworkGraphJSX graphType={'force-atlas2'} />
            </NetworkLinksContainer>
          </>
        ),
      });
    }

    if (
      hasAdvancedManagement &&
      deviceData.device.videoCards !== null &&
      deviceData.device.videoCards.length > 0
    ) {
      tabItems.push({
        key: 'video',
        label: (
          <TabTitle>
            <span style={{color: activeTab === 'video' ? COLOR_SELECTED : COLOR_HARDWARE}}>Video</span>
          </TabTitle>
        ),
        children: <VideoCardsTable />,
      });
    }

    if (
      hasAdvancedManagement &&
      deviceData.device.peripherals !== null &&
      deviceData.device.peripherals.length > 0
    ) {
      tabItems.push({
        key: 'peripherals',
        label: (
          <TabTitle>
            <span style={{color: activeTab === 'peripherals' ? COLOR_SELECTED : COLOR_HARDWARE}}>Peripherals</span>
          </TabTitle>
        ),
        children: <PeripheralsTable />,
      });
    }

    if (hasSoftware(deviceData)) {
      tabItems.push({
        key: 'software',
        label: (
          <TabTitle>
            <span style={{color: activeTab === 'software' ? COLOR_SELECTED : COLOR_SOFTWARE}}>Software</span>
          </TabTitle>
        ),
        children: (
          <SoftwareContainer id={deviceData.device.id}>
            <SoftwareTable />,
          </SoftwareContainer>
        ),
      });
    }

    if (hasOSPatchInstalls(deviceData)) {
      tabItems.push({
        key: 'os-patch-installs',
        label: (
          <TabTitle>
            <span style={{color: activeTab === 'os-patch-installs' ? COLOR_SELECTED : COLOR_SOFTWARE}}>OS Patch Installs</span>
          </TabTitle>
        ),
        children: (
          <OSPatchInstallsContainer id={deviceData.device.id}>
            <OSPatchInstallsTable />
          </OSPatchInstallsContainer>
        ),
      });
    }

    if (hasSoftwarePatchInstalls(deviceData)) {
      tabItems.push({
        key: 'software-patch-installs',
        label: (
          <TabTitle>
            <span style={{color: activeTab === 'software-patch-installs' ? COLOR_SELECTED : COLOR_SOFTWARE}}>Software Patch Installs</span>
          </TabTitle>
        ),
        children: (
          <SoftwarePatchInstallsContainer id={deviceData.device.id}>
            <SoftwarePatchInstallsTable />
          </SoftwarePatchInstallsContainer>
        ),
      });
    }

    if (hasOSPatches(deviceData) || hasSoftwarePatches(deviceData)) {
      tabItems.push({
        key: 'os-patches',
        label: (
          <TabTitle>
            <span style={{color: activeTab === 'os-patches' ? COLOR_SELECTED : COLOR_SOFTWARE}}>Patches</span>
          </TabTitle>
        ),
        children: (
          <>
            {hasOSPatches(deviceData) && (
              <>
                <HeaderLabel title="OS Patches" />
                <OSPatchContainer id={deviceData.device.id}>
                  <OSPatchTable />
                </OSPatchContainer>
                <br />
                <br />
              </>
            )}
            {hasSoftwarePatches(deviceData) && (
              <>
                <HeaderLabel title="Software Patches" />
                <SoftwarePatchContainer id={deviceData.device.id}>
                  <SoftwarePatchesTable />
                </SoftwarePatchContainer>
              </>
            )}
          </>
        ),
      });
    }

    if (hasSoftware(deviceData) && isNotNull(data) && isNotNull(data.data) && data.data.length > 0) {
      tabItems.push({
        key: 'software_vulnerabilities',
        label: (
          <TabTitle>
            <span style={{color: activeTab === 'software_vulnerabilities' ? COLOR_SELECTED : COLOR_SOFTWARE}}>Vulnerabilities</span>
          </TabTitle>
        ),
        children: (
          <VulnerabilityReportTable
            deviceId={deviceData.device.id}
            deviceName={deviceData.device.displayName}
          />
        ),
      });
    }

    tabItems.push({
      key: 'events',
      label: (
        <TabTitle>
          <span style={{color: activeTab === 'events' ? COLOR_SELECTED : COLOR_SUPPORT}}>Events</span>
        </TabTitle>
      ),
      children: <EventsTable />,
    });

    if (userCanSeeTicketing) {
      tabItems.push({
        key: 'tickets',
        label: (
          <TabTitle>
            <span style={{color: activeTab === 'tickets' ? COLOR_SELECTED : COLOR_SUPPORT}}>Tickets</span>
          </TabTitle>
        ),
        children: (
          <TicketEmbeddedContainer
            staticFilters={{ deviceId: [deviceData.device.id] }}
          >
            <TicketList />
          </TicketEmbeddedContainer>
        ),
      });
    }

    if (isNotNull(deviceData) && isNotNull(deviceData.device) && isNotNull(deviceData.device.maintenanceWindows) && deviceData.device.maintenanceWindows.length > 0) {
      tabItems.push({
        key: 'maintenance-windows',
        label: (
          <TabTitle>
            <span style={{color: activeTab === 'maintenance-windows' ? COLOR_SELECTED : COLOR_SUPPORT}}>Maintenance Windows</span>
          </TabTitle>
        ),
        children: (
          <MaintenanceWindows />
        ),
      });
    }

    if (
      canSeeContracts &&
      deviceData.device.contractLines !== null &&
      deviceData.device.contractLines.length > 0
    ) {
      tabItems.push({
        key: 'contract',
        label: (
          <TabTitle>
            <span style={{color: activeTab === 'contract' ? COLOR_SELECTED : COLOR_SUPPORT}}>Contract Lines</span>
          </TabTitle>
        ),
        children: <ContractLinesTable />,
      });
    }

    if (hasVariables(deviceData)) {
      tabItems.push({
        key: 'variables',
        label: (
          <TabTitle>
            <span style={{color: activeTab === 'variables' ? COLOR_SELECTED : COLOR_SUPPORT}}>Variables</span>
          </TabTitle>
        ),
        children: <DeviceVariablesTable />,
      });
    }

    if (canSeeKeys) {
      tabItems.push({
        key: 'keys',
        label: (
          <TabTitle>
            <span style={{color: activeTab === 'keys' ? COLOR_SELECTED : COLOR_SUPPORT}}>Keys</span>
          </TabTitle>
        ),
        children: <DeviceKeysTable />,
      });
    }

    return tabItems;
  };

  return (
    <>
      {typeof deviceData !== 'undefined' && deviceData !== null && (
        <Tabs defaultActiveKey="attributes" items={getTabItems()} activeKey={activeTab} onChange={setActiveTab} />
      )}
    </>
  );
};

export default DeviceTabs;
