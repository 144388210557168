import React, { useEffect, useState } from 'react';
import { AutoComplete, Input } from 'antd';

import { RemoteAccessOrgRole } from '@totem/components/remoteAccess/types';
import { usePreviousValue } from '@totem/hooks/usePreviousValue';
import colors from '@totem/styles/colors';
import fonts from '@totem/styles/fonts';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { REMOTE_ACCESS_ENDPOINT } from '@totem/utilities/endpoints';

export interface Props {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  allowClear?: boolean;
  disabled?: boolean;
  limit?: number;
}

const styles = {
  input: {
    width: '100%',
  },
  notFound: {
    color: colors.neutral.gray,
    fontSize: fonts.fontMd,
  },
};

const getData = (emails: string[]) =>
  emails.map((email) => ({
    id: email,
    value: email,
  }));

const OrgRoleAutoComplete = ({
  value,
  onChange,
  style,
  allowClear,
  disabled,
  placeholder = '',
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [roleName, setRoleName] = useState<string>(value || '');
  const [results, setResults] = useState<string[]>([]);
  const [roles, setRoles] = useState<RemoteAccessOrgRole[]>([]);
  const [availableRoles, setAvailableRoles] = useState<string[]>([]);

  const previousValue = usePreviousValue<string>(value);

  useEffect(() => {
    if (isNotNull(roles)) {
      const roleNames = roles.map((chk) => chk.name);
      setAvailableRoles(roleNames);
    }
  }, [roles]);

  useEffect(() => {
    fetch(`${REMOTE_ACCESS_ENDPOINT}/organization/roles`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: RemoteAccessOrgRole[]) => {
        setRoles(result);
        if (!value) {
          setRoleName('');
        }
      });
  }, []);

  const validateRole = (roleNameInput: string): boolean => {
    return roleNameInput !== '';
  };

  const handleSearch = (search: string) => {
    const newOptions = availableRoles.filter(
      (roleSearch: string) => roleSearch.indexOf(search) >= 0,
    );
    setResults(newOptions);
    setRoleName(search);
  };

  const handleSelect = (selection: string) => {
    onChange(selection);
    setRoleName(selection);
  };

  useEffect(() => {
    if (!previousValue && value) {
      setRoleName(value);
    }
  }, [value, previousValue]);

  const getNotFoundContent = () => {
    return roleName && validateRole(roleName) ? (
      <div
        style={styles.notFound}
        onClick={() => {
          setOpen(false);
          onChange(roleName);
        }}
      >
        Looks like this person isn't a remote access user yet.
      </div>
    ) : null;
  };

  return (
    <AutoComplete
      open={open}
      options={getData(results)}
      style={{ ...styles.input, ...style }}
      value={value || ''}
      onChange={onChange}
      onSelect={handleSelect}
      onSearch={handleSearch}
      placeholder={placeholder}
      allowClear={allowClear ?? false}
      disabled={disabled ?? false}
      notFoundContent={getNotFoundContent()}
      onDropdownVisibleChange={setOpen}
    >
      <Input.Search size="large" placeholder="input here" enterButton />
    </AutoComplete>
  );
};

export default OrgRoleAutoComplete;
