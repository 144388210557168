import React, { useCallback, useContext, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Input,
  Modal,
  Row,
} from 'antd';
import dayjs from 'dayjs';

import GenericAutoComplete from '@totem/components/common/autoCompletes/GenericAutoComplete';
import MaintenanceWindowContext from '@totem/components/maintenanceWindows/maintenanceWindowContext';
import {
  DeviceReference,
  MaintenanceWindow,
  MaintenanceWindowDetails,
} from '@totem/components/maintenanceWindows/types';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import IBDIcon from '@totem/styles/Icon';
import { AutoCompleteValue } from '@totem/types/autoComplete';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';
import { emptyId } from '@totem/utilities/userUtilities';

const styles = {
  dialog: {
    maxHeight: '80%',
    maxWidth: '850px',
  },
  label: {
    fontSize: '12px',
    fontWeight: 600,
  },
};

type Props = {
  open: boolean;
  onClose: () => void;
  maintenanceWindow: MaintenanceWindow;
};

const MaintenanceWindowEditDialog = ({
  open,
  onClose,
  maintenanceWindow,
}: Props) => {
  const { onAction } = useContext(MaintenanceWindowContext);
  const [isSending, setIsSending] = useState(false);
  const [currentWindow, setCurrentWindow] =
    useState<MaintenanceWindow>(maintenanceWindow);
  const [selectedDevice, setSelectedDevice] = useState<AutoCompleteValue>({
    value: '',
    display: '',
  });

  const sendUpdateMaintenanceWindow = useCallback(
    async (maintenanceWindowUpdated: MaintenanceWindowDetails) => {
      fetch(
        `${DEVICES_ENDPOINT}/maintenanceWindows/${maintenanceWindowUpdated.id}`,
        {
          method: 'PUT',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
          body: JSON.stringify(maintenanceWindowUpdated),
        },
      ).then((res) => {
        setIsSending(false);
        const success = CheckResponseShowError(res);
        if (success) {
          onAction('refresh', null);
          onAction('clear', null);
        }
      });
    },
    [],
  );

  const sendAddDevice = useCallback(
    async (maintenanceWindowId: string, payload: any) => {
      fetch(
        `${DEVICES_ENDPOINT}/maintenanceWindows/${maintenanceWindowId}/devices`,
        {
          method: 'PUT',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
          body: JSON.stringify(payload),
        },
      ).then((res) => {
        setIsSending(false);
        const success = CheckResponseShowError(res);
        if (success) {
          onAction('refresh', null);

          setCurrentWindow((prevState) => ({
            ...prevState,
            devices: [...prevState.devices, payload.deviceReference],
          }));
        }
      });
    },
    [],
  );

  const sendRemoveDevice = useCallback(
    async (maintenanceWindowId: string, deviceId: string) => {
      fetch(
        `${DEVICES_ENDPOINT}/maintenanceWindows/${maintenanceWindowId}/devices/${deviceId}`,
        {
          method: 'DELETE',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
        },
      ).then((res) => {
        setIsSending(false);
        const success = CheckResponseShowError(res);
        if (success) {
          onAction('refresh', null);

          setCurrentWindow((prevState) => ({
            ...prevState,
            devices: prevState.devices.filter(
              (chk) => chk.device.id !== deviceId,
            ),
          }));
        }
      });
    },
    [],
  );

  const removeDevice = (item: DeviceReference) => {
    sendRemoveDevice(maintenanceWindow.id, item.device.id);
  };

  const handleSubmit = () => {
    sendUpdateMaintenanceWindow(currentWindow.details);
  };

  const handleDeviceSelected = (selectedValue: AutoCompleteValue) => {
    if (selectedValue.value !== selectedDevice.value) {
      setSelectedDevice(selectedValue);
    }
  };

  const handleDeviceAdd = () => {
    const payload = {
      maintenanceWindowDetails: currentWindow.details,
      deviceReference: {
        device: {
          id: selectedDevice.value,
          name: selectedDevice.display,
        },
        controlSystem: {
          id: emptyId,
          name: '',
        },
        building: {
          id: emptyId,
          name: '',
        },
        region: {
          id: emptyId,
          name: '',
        },
      },
    };

    if (isNotNull(selectedDevice.parent)) {
      payload.deviceReference.controlSystem.id = selectedDevice.parent.value;
      payload.deviceReference.controlSystem.name =
        selectedDevice.parent.display;

      if (isNotNull(selectedDevice.parent.parent)) {
        payload.deviceReference.building.id =
          selectedDevice.parent.parent.value;
        payload.deviceReference.building.name =
          selectedDevice.parent.parent.display;

        if (isNotNull(selectedDevice.parent.parent.parent)) {
          payload.deviceReference.region.id =
            selectedDevice.parent.parent.parent.value;
          payload.deviceReference.region.name =
            selectedDevice.parent.parent.parent.display;
        }
      }
    }

    sendAddDevice(maintenanceWindow.id, payload);
  };

  const handleDeviceAutoCompleteChanged = () => {
    setSelectedDevice({
      value: '',
      display: '',
    });
  };

  return (
    <Modal
      open={open}
      onCancel={() => onClose()}
      title={<ModalTitle>Maintenance Window</ModalTitle>}
      confirmLoading={false}
      style={styles.dialog}
      footer={
        <ModalFooter>
          <Button onClick={() => onClose()}>Close</Button>
          <Button onClick={handleSubmit} type="primary" disabled={isSending}>
            Submit
          </Button>
        </ModalFooter>
      }
    >
      <div style={styles.label}>Start Time:</div>
      <div>
        <DatePicker
          showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
          value={dayjs(currentWindow.details.scheduledStartTime)}
          onChange={(time) =>
            setCurrentWindow((prevState) => ({
              ...prevState,
              details: {
                ...prevState.details,
                scheduledStartTime: time.toISOString(),
              },
            }))
          }
          format={'L LT'}
          style={{ width: '50%' }}
        />
      </div>
      <br />

      <div style={styles.label}>End Time:</div>
      <div>
        <DatePicker
          showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
          value={dayjs(currentWindow.details.scheduledEndTime)}
          onChange={(time) =>
            setCurrentWindow((prevState) => ({
              ...prevState,
              details: {
                ...prevState.details,
                scheduledEndTime: time.toISOString(),
              },
            }))
          }
          format={'L LT'}
          style={{ width: '50%' }}
        />
      </div>
      <br />

      <div style={styles.label}>Reason:</div>
      <div>
        <Input
          value={currentWindow.details.reason}
          onChange={(evt) =>
            setCurrentWindow((prevState) => ({
              ...prevState,
              details: {
                ...prevState.details,
                reason: evt.target.value,
              },
            }))
          }
        />
      </div>
      <br />

      <div style={styles.label}>Description:</div>
      <div>
        <Input
          value={currentWindow.details.description}
          onChange={(evt) =>
            setCurrentWindow((prevState) => ({
              ...prevState,
              details: {
                ...prevState.details,
                description: evt.target.value,
              },
            }))
          }
        />
      </div>
      <br />

      <div style={styles.label}>Site Contact:</div>
      <div style={styles.label}>Name:</div>
      <div>
        <Input
          value={currentWindow.details.siteContactName}
          onChange={(evt) =>
            setCurrentWindow((prevState) => ({
              ...prevState,
              details: {
                ...prevState.details,
                siteContactName: evt.target.value,
              },
            }))
          }
        />
      </div>
      <div style={styles.label}>Phone:</div>
      <div>
        <Input
          value={currentWindow.details.siteContactPhone}
          onChange={(evt) =>
            setCurrentWindow((prevState) => ({
              ...prevState,
              details: {
                ...prevState.details,
                siteContactPhone: evt.target.value,
              },
            }))
          }
        />
      </div>
      <div style={styles.label}>Email:</div>
      <div>
        <Input
          value={currentWindow.details.siteContactEmail}
          onChange={(evt) =>
            setCurrentWindow((prevState) => ({
              ...prevState,
              details: {
                ...prevState.details,
                siteContactEmail: evt.target.value,
              },
            }))
          }
        />
      </div>
      <br />

      <Checkbox
        checked={currentWindow.details.requiresBypass}
        onChange={(evt) =>
          setCurrentWindow((prevState) => ({
            ...prevState,
            details: {
              ...prevState.details,
              requiresBypass: evt.target.checked,
            },
          }))
        }
      >
        Maintenance Requires Firewall Bypass
      </Checkbox>
      <br />
      <br />

      <div style={styles.label}>Devices:</div>
      <Flex justify={'flex-start'} align={'center'}>
        <GenericAutoComplete
          label="Device"
          type="Device"
          style={{ width: '80%', marginRight: '16px' }}
          limit={15}
          onSelect={handleDeviceSelected}
          onChange={handleDeviceAutoCompleteChanged}
          selectedValue={selectedDevice}
        />
        <IBDIcon name={'Add'} title={'Add'} onClick={handleDeviceAdd} />
      </Flex>
      <br />
      {isNotNull(currentWindow) &&
        isNotNull(currentWindow.devices) &&
        currentWindow.devices.length > 0 &&
        currentWindow.devices.map((device) => (
          <Row key={device.device.id}>
            <Col span={12}>{device.device.name}</Col>
            <Col span={11}>{device.building.name}</Col>
            <Col span={1}>
              <IBDIcon
                name={'Delete'}
                title={'Remove'}
                onClick={() => removeDevice(device)}
              />
            </Col>
          </Row>
        ))}
    </Modal>
  );
};

export default MaintenanceWindowEditDialog;
