import ContentLayout from '@totem/components/ContentLayout';
import BasePane from '@totem/components/BasePane';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import CardBody from '@totem/components/CardBody';
import React from 'react';
import { useParams } from 'react-router-dom';
import MaintenanceWindowDetailsContainer
  from '@totem/components/maintenanceWindows/detail/maintenanceWindowDetailsContainer';
import DetailLayout from '@totem/components/maintenanceWindows/detail/detailLayout';

const MaintenanceDetailsPage = () => {
  const { id } = useParams();

  return (
    <MaintenanceWindowDetailsContainer id={id}>
      <ContentLayout>
        <BasePane>
          <PrimaryContentPane>
            <CardBody>
              <DetailLayout />
            </CardBody>
          </PrimaryContentPane>
        </BasePane>
      </ContentLayout>
    </MaintenanceWindowDetailsContainer>
  );
};

export default MaintenanceDetailsPage;
