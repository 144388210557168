import React, { ReactNode, useCallback, useContext, useState } from 'react';
import { DislikeOutlined, LikeOutlined } from '@ant-design/icons';
import { Button, Col, Flex } from 'antd';

import MaintenanceWindowDetailsContext from '@totem/components/maintenanceWindows/detail/maintenanceWindowDetailContext';
import UserProfileContext from '@totem/components/UserProfileContext';
import colors from '@totem/styles/colors';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

const styles = {
  cell: {
    backgroundColor: '#F2F2F2',
    borderColor: '#FFFFFF',
    borderStyle: 'solid',
    borderWidth: 4,
  },
  title: {
    color: colors.branding.primaryLight,
    fontSize: '16px',
    fontWeight: 600,
    marginTop: '2rem',
    marginBottom: '1rem',
  },
  value: {
    fontSize: '14px',
    marginTop: '1rem',
    marginBottom: '2rem',
  },
};

type Props = {
  title: ReactNode;
  value: ReactNode;
  span: number;
};

const FireWallBypassApprovalCell = ({ span, title, value }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const { data, onAction } = useContext(MaintenanceWindowDetailsContext);
  const [isSending, setIsSending] = useState(false);

  const requiresApproval =
    isNotNull(data) && isNotNull(data.details) && data.details.requiresBypass;
  const beenApproved = requiresApproval && data.details.bypassApproved;
  const isApprover =
    requiresApproval &&
    data.approvals.findIndex(
      (chk) => chk.approver.toLowerCase() === userProfile.email.toLowerCase(),
    ) >= 0;

  const sendUpdateMaintenanceWindowApproval = useCallback(
    async (maintenanceWindowId: string, approvalType: string) => {
      if (!isSending) {
        setIsSending(true);
        fetch(
          `${DEVICES_ENDPOINT}/maintenanceWindows/${maintenanceWindowId}/${approvalType}`,
          {
            method: 'POST',
            headers: new Headers({
              Authorization: `Bearer ${getToken()}`,
            }),
          },
        ).then((res) => {
          setIsSending(false);
          const success = CheckResponseShowError(res);
          if (success) {
            onAction('refresh', null);
          }
        });
      }
    },
    [],
  );

  const handleApproval = (type: string) => {
    sendUpdateMaintenanceWindowApproval(data.id, type);
  };

  return (
    <Col span={span} style={styles.cell}>
      <Flex justify={'center'} align={'center'}>
        <div>
          <div style={styles.title}>
            <Flex justify={'center'}>{title}</Flex>
          </div>
          <div style={styles.value}>
            <Flex justify={'center'}>{value}</Flex>
          </div>
        </div>
        {!beenApproved && isApprover && (
          <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
            <Button
              icon={<LikeOutlined style={{ color: colors.utility.positive }} />}
              onClick={() => handleApproval('approve')}
              style={{
                width: '14rem',
                paddingLeft: '16px',
                paddingRight: '16px',
                marginBottom: '2px',
              }}
            >
              Approve
            </Button>
            <br />
            <Button
              icon={
                <DislikeOutlined style={{ color: colors.utility.negative }} />
              }
              onClick={() => handleApproval('reject')}
              style={{
                width: '14rem',
                paddingLeft: '16px',
                paddingRight: '16px',
                marginTop: '2px',
              }}
            >
              Reject
            </Button>
          </div>
        )}
      </Flex>
    </Col>
  );
};

export default FireWallBypassApprovalCell;
