/* eslint-disable id-length */
import React, { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Modal, Spin } from 'antd';

import CreateOrganization from '@totem/components/login/CreateOrganization';
import NotificationContext from '@totem/components/notification/NotificationContext';
import UserProfileContext from '@totem/components/UserProfileContext';
import colors from '@totem/styles/colors';
import { LoginProfile } from '@totem/types/user';
import accountUtilities, { getToken } from '@totem/utilities/accountUtilities';
import { LOGIN_PROFILE_ENDPOINT } from '@totem/utilities/endpoints';

import './registration.css';

const styles = {
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '2rem',
  },
  icon: {
    marginRight: '1.5rem',
    fontSize: '24px',
    color: colors.opacity.black0_4,
  },
};

const SYSTEMS_ADMINS_ROLE = 3;

const SwitchOrganizationsDialog = () => {
  const navigate = useNavigate();
  const { notify } = useContext(NotificationContext);

  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [loadingToken, setLoadingToken] = useState<boolean>(false);

  const { userProfile, loading, setProfile, refetch } =
    useContext(UserProfileContext);
  const { organizations } = userProfile;

  const sendOrganizationSwitch = useCallback(
    async (userId: string, newOrganizationId: string) => {
      if (!loadingToken) {
        try {
          setLoadingToken(true);
          fetch(`${LOGIN_PROFILE_ENDPOINT}/${userId}/${newOrganizationId}`, {
            method: 'GET',
            headers: new Headers({
              Authorization: `Bearer ${getToken()}`,
            }),
          })
            .then((res) => {
              if (res.status >= 400) {
                console.log(res.statusText);
              }
              return res.json();
            })
            .then((result: LoginProfile) => {
              if (result.token !== '') {
                accountUtilities.setToken(result.token);
              }
              setProfile(result.profile);
              navigate('/');
            })
            .then(() => {
              setLoadingToken(false);
            });
        } catch (error) {
          notify(`Could not switch to the organization`, true);
        }
      }
    },
    [],
  );

  const navigateToOrganization = async (id: string) => {
    sendOrganizationSwitch(userProfile.id, id);
  };

  const handlePostOrganizationCreate = () => {
    refetch();
    setShowCreateDialog(false);
  };

  const isSystemsAdmin = userProfile.organizations.some(
    (org) => org.role === SYSTEMS_ADMINS_ROLE && org.isActive,
  );

  return (
    <div>
      <Modal
        open
        style={{ top: '20vh', border: 'none' }}
        styles={{ body: { border: 'none' } }}
        title={
          <div styleName="organization-switcher-modal-title">
            Select an Account
          </div>
        }
        closable={false}
      >
        <Spin spinning={loading}>
          <div styleName="organization-switcher-modal-content">
            <div styleName="organization-switcher-content-heading">
              Select an organization you would like to log into.
            </div>
            <div styleName="organization-switcher-list-container">
              {isSystemsAdmin && (
                <div
                  styleName="organization-selection-container"
                  onClick={() => setShowCreateDialog(true)}
                >
                  <PlusCircleOutlined style={styles.icon} />
                  <div styleName="organization-selection-name">
                    Create a new account
                  </div>
                </div>
              )}
              {organizations
                .filter((org) => org.isActive)
                .map((org) => (
                  <div
                    styleName="organization-selection-container"
                    onClick={() => navigateToOrganization(org.id)}
                    key={org.id}
                  >
                    <div styleName="organization-selection-name">
                      {org.name}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Spin>
      </Modal>
      {showCreateDialog && (
        <CreateOrganization
          open={showCreateDialog}
          onCancel={() => setShowCreateDialog(false)}
          onComplete={handlePostOrganizationCreate}
        />
      )}
    </div>
  );
};

export default SwitchOrganizationsDialog;
