import * as R from 'ramda';

import colors from '@totem/styles/colors';
import { OrganizationType } from '@totem/types/organization';
import {
  Assignee,
  DataAccessGroup,
  DataAccessItem,
  Parent,
  SecurityGroupCheckConstraints,
  User,
  UserAccess,
  UserProfile,
} from '@totem/types/user';

export function getUserName(user: User): string {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }

  if (user.email) {
    return user.email;
  }

  return '';
}

// returns role for a given user in the context of the organization
// that the authenticated user is signed in to
export const getUserRole = ({ organization }: User) =>
  organization ? organization.role : 0;

export const emptyId: string = '000000000000000000000000';
export const securityGroupCheckConstraintEmpty: SecurityGroupCheckConstraints =
  {
    regionId: emptyId,
    buildingId: emptyId,
    controlSystemId: emptyId,
  };

export function normalizeId(idIn: string): string {
  if (typeof idIn !== 'undefined') {
    if (idIn !== null) {
      if (idIn.length === emptyId.length) {
        return idIn;
      }
    }
  }
  return emptyId;
}

export function hasAnyActiveService(
  profile: UserProfile,
  services: string[],
): boolean {
  if (services !== null && services.length > 0) {
    if (typeof profile !== 'undefined') {
      if (profile !== null) {
        // eslint-disable-next-line max-depth
        if (profile.organization !== null) {
          // eslint-disable-next-line max-depth
          if (profile.organization.services !== null) {
            // eslint-disable-next-line max-depth
            for (let idx = 0; idx < services.length; idx++) {
              const service = services[idx];
              const foundServices = profile.organization.services.filter(
                (svc) =>
                  svc.name === service && svc.status.toLowerCase() === 'active',
              );
              // eslint-disable-next-line max-depth
              if (typeof foundServices !== 'undefined') {
                // eslint-disable-next-line max-depth
                if (foundServices.length > 0) {
                  return true;
                }
              }
            }
          }
        }
      }
    }
  }
  return false;
}

export function hasActiveService(
  profile: UserProfile,
  service: string,
): boolean {
  return hasAnyActiveService(profile, [service]);
}

export function isMemberOfAny(
  profile: UserProfile,
  groups: string[],
  constraints: SecurityGroupCheckConstraints,
): boolean {
  if (groups === null || groups.length === 0) {
    return true;
  }
  if (typeof profile !== 'undefined') {
    if (profile !== null) {
      if (profile.organization !== null) {
        // eslint-disable-next-line max-depth
        if (profile.organization.securityGroups !== null) {
          // eslint-disable-next-line max-depth
          for (let idx = 0; idx < groups.length; idx++) {
            const group = groups[idx];
            const foundGroups = profile.organization.securityGroups.filter(
              (grp) => grp.name === group,
            );
            // eslint-disable-next-line max-depth
            for (let fgIdx = 0; fgIdx < foundGroups.length; fgIdx++) {
              const secGrp = foundGroups[fgIdx];
              // eslint-disable-next-line max-depth
              if (
                (secGrp.regionId === constraints.regionId ||
                  secGrp.regionId === emptyId) &&
                (secGrp.buildingId === constraints.buildingId ||
                  secGrp.buildingId === emptyId) &&
                (secGrp.controlSystemId === constraints.controlSystemId ||
                  secGrp.controlSystemId === emptyId)
              ) {
                return true;
              }
            }
          }
        }
      }
    }
  }
  return false;
}

export function isMemberOfAnySkipFilter(
  profile: UserProfile,
  groups: string[],
): boolean {
  if (groups === null || groups.length === 0) {
    return true;
  }
  if (typeof profile !== 'undefined') {
    if (profile !== null) {
      if (profile.organization !== null) {
        // eslint-disable-next-line max-depth
        if (profile.organization.securityGroups !== null) {
          // eslint-disable-next-line max-depth
          for (let idx = 0; idx < groups.length; idx++) {
            const group = groups[idx];
            const foundGroups = profile.organization.securityGroups.filter(
              (grp) => grp.name === group,
            );

            if (foundGroups.length > 0) {
              return true;
            }
          }
        }
      }
    }
  }
  return false;
}

export function hasDataAccessPermissionsTo(
  dataAccessGroups: DataAccessGroup[],
  constraints: SecurityGroupCheckConstraints,
): boolean {
  if (dataAccessGroups !== null && dataAccessGroups.length > 0) {
    for (let dagIdx = 0; dagIdx < dataAccessGroups.length; dagIdx++) {
      const regionGroup = dataAccessGroups[dagIdx];
      // eslint-disable-next-line default-case
      switch (regionGroup.accessType) {
        case 'All':
          return true;
        case 'Partial':
          // eslint-disable-next-line max-depth
          for (
            let regionIdx = 0;
            regionIdx < regionGroup.restrictedTo.length;
            regionIdx++
          ) {
            const currentRegion = regionGroup.restrictedTo[regionIdx];
            //console.log(`COMPARING REGION ${currentRegion.id} to ${constraints.regionId}`);
            // eslint-disable-next-line max-depth
            if (currentRegion.id === constraints.regionId) {
              // eslint-disable-next-line max-depth
              for (
                let bldGrpIdx = 0;
                bldGrpIdx < currentRegion.children.length;
                bldGrpIdx++
              ) {
                const buildingGroup = currentRegion.children[bldGrpIdx];
                // eslint-disable-next-line default-case,max-depth
                switch (buildingGroup.accessType) {
                  case 'All':
                    return true;
                  case 'Partial':
                    // eslint-disable-next-line max-depth
                    for (
                      let buildingIdx = 0;
                      buildingIdx < buildingGroup.restrictedTo.length;
                      buildingIdx++
                    ) {
                      const currentBuilding =
                        buildingGroup.restrictedTo[buildingIdx];
                      // eslint-disable-next-line max-depth
                      if (currentBuilding.id === constraints.buildingId) {
                        // eslint-disable-next-line max-depth
                        for (
                          let ctrlSysGrpIdx = 0;
                          ctrlSysGrpIdx < currentBuilding.children.length;
                          ctrlSysGrpIdx++
                        ) {
                          const controlSystemGroup =
                            currentBuilding.children[ctrlSysGrpIdx];
                          // eslint-disable-next-line default-case,max-depth
                          switch (controlSystemGroup.accessType) {
                            case 'All':
                              return true;
                            case 'Partial':
                              // eslint-disable-next-line max-depth
                              for (
                                let controlSystemIdx = 0;
                                controlSystemIdx <
                                controlSystemGroup.restrictedTo.length;
                                controlSystemIdx++
                              ) {
                                const currentControlSystem =
                                  controlSystemGroup.restrictedTo[
                                    controlSystemIdx
                                  ];
                                // eslint-disable-next-line max-depth
                                if (
                                  currentControlSystem.id ===
                                  constraints.controlSystemId
                                ) {
                                  return true;
                                }
                              }
                          }
                        }
                      }
                    }
                }
              }
            }
          }
      }
    }
  } else {
    return true;
  }

  return false;
}

export function isMemberOf(
  profile: UserProfile,
  group: string,
  constraints: SecurityGroupCheckConstraints,
): boolean {
  return isMemberOfAny(profile, [group], constraints);
}

export const hasEventManagement = (userProfile: UserProfile): boolean => {
  const userCanSeeEvents =
    hasAnyActiveService(userProfile, ['threat_management']) &&
    (getUserRole(userProfile) === 3 ||
      isMemberOfAny(
        userProfile,
        ['event_viewer', 'event_manager'],
        securityGroupCheckConstraintEmpty,
      ));

  return userCanSeeEvents;
};

export const getUserActiveStatus = ({ organization }: User) => {
  if (!organization) {
    return 'Unknown';
  }

  return organization.isActive ? 'Active' : 'Inactive';
};

export const getUserAccess = (user: User): UserAccess => {
  const { organization } = user;

  if (!R.isNil(organization?.deactivatedAt)) {
    return UserAccess['No Access (Removed)'];
  }

  if (!organization?.isActive) {
    return UserAccess['No Access'];
  }

  return R.isNil(user.lastLoginDate)
    ? UserAccess['Access Granted']
    : UserAccess['Has Access'];
};

export const isAdminOrg = (profile: UserProfile) => {
  if (
    profile.organization.id === '5b0cf2f679e1b20fd9bb4993' ||
    profile.organization.id === '5b2f1be53911bd000175043a'
  ) {
    return true;
  }
  return false;
};

export const isUserDeactivated = (user: Assignee | User) =>
  !R.isNil(user?.organization?.deactivatedAt);

export const userRoleStyle = (role: number): Object => {
  switch (role) {
    case 0:
      return {
        fontWeight: 700,
        color: colors.neutral.dark,
      };
    case 1:
      return {
        fontWeight: 700,
        color: colors.brand.navy,
      };
    case 2:
      return {
        fontWeight: 700,
        color: colors.brand.green,
      };
    case 3:
      return {
        fontWeight: 700,
        color: colors.brand.red,
      };
    default:
      return {
        fontWeight: 700,
        color: colors.neutral.dark,
      };
  }
};

export const userRoleString = (role: number): string => {
  switch (role) {
    case -2:
      return 'Tenant';
    case -1:
      return 'Vendor';
    case 0:
      return 'Collaborator';
    case 1:
      return 'Team Member';
    case 2:
      return 'Admin';
    case 3:
      return 'Systems Administrator';
    default:
      return 'Collaborator';
  }
};

export const userOrganizationIsVendorOrg = ({ organization }: User) =>
  organization?.type === OrganizationType.Vendor;

export const cloneDataAccessItem = (orig: DataAccessItem): DataAccessItem => {
  return {
    id: orig.id,
    name: orig.name,
    // eslint-disable-next-line no-use-before-define
    children: cloneDataAccessGroups(orig.children),
  };
};

export const cloneDataAccessItems = (
  orig: DataAccessItem[],
): DataAccessItem[] => {
  return orig.map((item) => {
    return cloneDataAccessItem(item);
  });
};

export const cloneDataAccessGroup = (
  orig: DataAccessGroup,
): DataAccessGroup => {
  return {
    name: orig.name,
    accessType: orig.accessType,
    restrictedTo: cloneDataAccessItems(orig.restrictedTo),
  };
};

export const cloneDataAccessGroups = (
  orig: DataAccessGroup[],
): DataAccessGroup[] => {
  return orig.map((group) => {
    return cloneDataAccessGroup(group);
  });
};

const updateDataPermissions = (
  currentPermissions: DataAccessGroup[],
  parents: Parent[],
  newPermissionsLevel: string,
  newPermissionsItem: DataAccessItem,
) => {
  const currentParent = parents.pop();
  if (parents.length === 0) {
    if (currentParent.name === 'Organization') {
      const foundGroup = currentPermissions.find(
        (group) => group.name === newPermissionsLevel,
      );
      if (typeof foundGroup !== 'undefined') {
        foundGroup.restrictedTo.push(newPermissionsItem);
      } else {
        const newGroup = {
          name: newPermissionsLevel,
          accessType: 'Partial',
          restrictedTo: [newPermissionsItem],
        };
        currentPermissions.push(newGroup);
      }
    } else {
      const foundGroup = currentPermissions.find(
        (group) => group.name === currentParent.name,
      );
      if (typeof foundGroup !== 'undefined') {
        const foundItem = foundGroup.restrictedTo.find(
          (item) => item.id === currentParent.id,
        );
        // eslint-disable-next-line max-depth
        if (typeof foundItem !== 'undefined') {
          const foundItemGroup = foundItem.children.find(
            (group) => group.name === newPermissionsLevel,
          );
          // eslint-disable-next-line max-depth
          if (typeof foundItemGroup !== 'undefined') {
            foundItemGroup.restrictedTo.push(newPermissionsItem);
          } else {
            const newGroup = {
              name: newPermissionsLevel,
              accessType: 'Partial',
              restrictedTo: [newPermissionsItem],
            };
            foundItem.children.push(newGroup);
          }
        }
      }
    }
  } else {
    if (currentParent.name === 'Organization') {
      updateDataPermissions(
        currentPermissions,
        parents,
        newPermissionsLevel,
        newPermissionsItem,
      );
    }
    const foundGroup = currentPermissions.find(
      (group) => group.name === currentParent.name,
    );
    if (typeof foundGroup !== 'undefined') {
      const foundItem = foundGroup.restrictedTo.find(
        (item) => item.id === currentParent.id,
      );
      if (typeof foundItem !== 'undefined') {
        updateDataPermissions(
          foundItem.children,
          parents,
          newPermissionsLevel,
          newPermissionsItem,
        );
      }
    }
  }
};

export const buildUpdatedDataPermissions = (
  currentPermissions: DataAccessGroup[],
  parents: Parent[],
  newPermissionsLevel: string,
  newPermissionsItem: DataAccessItem,
): DataAccessGroup[] => {
  const newPermissions = cloneDataAccessGroups(currentPermissions);
  const revParents = parents.reverse();

  updateDataPermissions(
    newPermissions,
    revParents,
    newPermissionsLevel,
    newPermissionsItem,
  );

  return newPermissions;
};

interface deleteParams {
  dataAccessGroups: DataAccessGroup[];
  parents: Parent[];
}

const deleteDataPermissions = (params: deleteParams) => {
  const currentParent = params.parents.pop();

  if (currentParent.name === 'Organization') {
    deleteDataPermissions({
      dataAccessGroups: params.dataAccessGroups,
      parents: params.parents,
    });
  } else if (params.parents.length > 0) {
    const foundParent = params.dataAccessGroups
      .find((gr) => gr.name === currentParent.name)
      .restrictedTo.find((dp) => dp.id === currentParent.id);
    deleteDataPermissions({
      dataAccessGroups: foundParent.children,
      parents: params.parents,
    });
  } else {
    const group = params.dataAccessGroups.find(
      (gr) => gr.name === currentParent.name,
    );
    group.restrictedTo = group.restrictedTo.filter(
      (dp) => dp.id !== currentParent.id,
    );
    if (group.restrictedTo.length === 0) {
      params.dataAccessGroups = params.dataAccessGroups.filter(
        (gr) => gr.name !== group.name,
      );
    }
  }
};

export const DeleteDataPermission = (
  currentPermissions: DataAccessGroup[],
  permissionsLevel: string,
  deletePath: Parent[],
): DataAccessGroup[] => {
  const newPermissions = cloneDataAccessGroups(currentPermissions);

  const revParents = deletePath.reverse();

  const deleteParams = {
    dataAccessGroups: newPermissions,
    parents: revParents,
  };

  deleteDataPermissions(deleteParams);

  return newPermissions.filter(
    (grp) => grp.accessType !== '' || grp.restrictedTo.length > 0,
  );
};

export const GetPermissionsGroup = (groupName: string) => {
  switch (groupName) {
    case 'Organization':
    case 'O':
      return {
        key: 'Organization',
        name: 'Organization',
        plural: 'Organizations',
        prefix: 'O',
      };
    case 'Region':
    case 'R':
      return {
        key: 'Region',
        name: 'Region',
        plural: 'Regions',
        prefix: 'R',
      };
    case 'Building':
    case 'B':
      return {
        key: 'Building',
        name: 'Building',
        plural: 'Buildings',
        prefix: 'B',
      };
    case 'ControlSystem':
    case 'C':
      return {
        key: 'ControlSystem',
        name: 'Control System',
        plural: 'Control Systems',
        prefix: 'C',
      };
    default:
      return {
        key: '',
        name: '',
        plural: '',
        prefix: '',
      };
  }
};

export const GetNextPermissionsGroup = (prevGroup: string) => {
  switch (prevGroup) {
    case 'Organization':
      return GetPermissionsGroup('Region');
    case 'Region':
      return GetPermissionsGroup('Building');
    case 'Building':
      return GetPermissionsGroup('ControlSystem');
    default:
      return GetPermissionsGroup('');
  }
};

const parseKey = (key: string) => {
  const components = key.split(':');
  switch (components.length) {
    case 2:
      return {
        isPermissionsItem: true,
        group: GetPermissionsGroup(components[0]).key,
        key: components[1],
        subGroup: '',
        subGroupAccessType: '',
      };
    case 4:
      return {
        isPermissionsItem: false,
        group: GetPermissionsGroup(components[0]).key,
        key: components[1],
        subGroup: GetPermissionsGroup(components[2]).key,
        subGroupAccessType: components[3],
      };
    default:
      return {
        isPermissionsItem: false,
        group: '',
        key: '',
        subGroup: '',
        subGroupAccessType: '',
      };
  }
};

const GetDataAccessGroup = (
  currentAccessGroup: DataAccessGroup,
  keys: string[],
) => {
  const currentKeyRaw = keys.pop();
  const currentKey = parseKey(currentKeyRaw);
  if (!currentKey.isPermissionsItem) {
    if (currentKey.group === 'Organization') {
      return {
        group: currentAccessGroup,
        parentItem: null,
      };
    }
    const foundItem = currentAccessGroup.restrictedTo.find(
      (di) => di.id === currentKey.key,
    );
    if (typeof foundItem !== 'undefined') {
      const foundGroup = foundItem.children.find(
        (grp) => grp.name === currentKey.subGroup,
      );
      // eslint-disable-next-line max-depth
      if (typeof foundGroup !== 'undefined') {
        // eslint-disable-next-line max-depth
        if (keys.length > 0) {
          return GetDataAccessGroup(foundGroup, keys);
        }
        return {
          group: foundGroup,
          parentItem: foundItem,
        };
      }
      const newGroup = {
        name: currentKey.subGroup,
        accessType: currentKey.subGroupAccessType,
        restrictedTo: [],
      };

      foundItem.children.push(newGroup);

      return {
        group: newGroup,
        parentItem: foundItem,
      };
    }
  } else {
    if (keys.length === 0) {
      return {
        group: currentAccessGroup,
        parentItem: null,
      };
    }

    if (currentKey.group === 'Organization') {
      return GetDataAccessGroup(currentAccessGroup, keys);
    }
    const nextKey = parseKey(keys[keys.length - 1]);
    const foundItem = currentAccessGroup.restrictedTo.find(
      (di) => di.id === currentKey.key,
    );
    if (typeof foundItem !== 'undefined') {
      const foundGroup = foundItem.children.find(
        (grp) => grp.name === nextKey.group,
      );
      if (typeof foundGroup !== 'undefined') {
        return GetDataAccessGroup(foundGroup, keys);
      }
    }
  }

  return {
    group: currentAccessGroup,
    parentItem: null,
  };
};

export const BulidUpdatedPermissionsFromTreeEvent = (
  currentPermissions: DataAccessGroup[],
  isAdd: boolean,
  key: string,
  name: string,
) => {
  const newPermissions = cloneDataAccessGroups(currentPermissions);

  const keys = key.split('-');
  const lastKey = keys[keys.length - 1];

  let foundGroup = newPermissions.find((grp) => grp.name === 'Region');
  if (typeof foundGroup === 'undefined') {
    foundGroup = {
      name: 'Region',
      accessType: 'All',
      restrictedTo: [],
    };
    newPermissions.push(foundGroup);
  }

  const results = GetDataAccessGroup(foundGroup, keys.reverse());

  const lastKeyInfo = parseKey(lastKey);
  if (lastKeyInfo.isPermissionsItem) {
    /* Item was clicked */
    if (isAdd) {
      const children = [];
      const childGroup = GetNextPermissionsGroup(lastKeyInfo.group);
      if (childGroup.name !== '') {
        children.push({
          name: childGroup.key,
          accessType: 'All',
          restrictedTo: [],
        });
      }

      const alreadyExists = results.group.restrictedTo.find(
        (pi) => pi.id === lastKeyInfo.key,
      );

      if (typeof alreadyExists === 'undefined') {
        results.group.restrictedTo.push({
          id: lastKeyInfo.key,
          name,
          children,
        });
      }
    } else {
      results.group.restrictedTo = results.group.restrictedTo.filter(
        (di) => di.id !== lastKeyInfo.key,
      );
    }
  } else {
    /* Group was clicked */
    results.group.accessType = lastKeyInfo.subGroupAccessType;
    if (
      lastKeyInfo.subGroupAccessType === 'None' ||
      lastKeyInfo.subGroupAccessType === 'All'
    ) {
      results.group.restrictedTo = [];
    }
  }

  //console.log('Faound Group: ', JSON.stringify(results));

  return newPermissions;
};
