import React, { ReactNode, useEffect, useState } from 'react';

import PolicyAuditReportContext from '@totem/components/surveyV2/policyAuditReport/policyAuditReportContext';
import {
  PolicyAuditFilters,
  PolicyAuditReportInput,
  PolicyAuditReportRecord,
  PolicyAuditReportResult,
} from '@totem/components/surveyV2/policyAuditReport/types';
import { buildParameters } from '@totem/components/surveyV2/policyAuditReport/utilities';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

type Props = {
  defaultFilters?: PolicyAuditFilters;
  staticFilters?: PolicyAuditFilters;
  children?: ReactNode;
};
const PolicyAuditReportContainer = ({
  children,
  defaultFilters,
  staticFilters,
}: Props) => {
  const [input, updateInput] = useState<PolicyAuditReportInput>({
    page: 1,
    pageSize: 10,
    sortField: 'policyAudit.startDate',
    sortDirection: '-1',
    regionId:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.regionId !== 'undefined' &&
      defaultFilters.regionId !== null
        ? defaultFilters.regionId
        : [],
    buildingId:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.buildingId !== 'undefined' &&
      defaultFilters.buildingId !== null
        ? defaultFilters.buildingId
        : [],
    controlSystemId:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.controlSystemId !== 'undefined' &&
      defaultFilters.controlSystemId !== null
        ? defaultFilters.controlSystemId
        : [],
  });
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<PolicyAuditReportResult>(null);
  const [action, setAction] = useState<string>('');
  const [actionItem, setActionItem] = useState<PolicyAuditReportRecord>(null);

  const setInput = (updated: Partial<PolicyAuditReportInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
    setRefreshData(true);
  };

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      const uri = `${V2_SURVEY_ENDPOINT}/policyAuditReport`;

      const payload = buildParameters(input, staticFilters);

      fetch(uri, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((result: PolicyAuditReportResult) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  const handleAction = (
    newAction: string,
    newActionItem: PolicyAuditReportRecord,
  ) => {
    switch (newAction) {
      case 'refresh':
        setRefreshData(true);
        setAction('');
        break;
      default:
        setAction(newAction);
        setActionItem(newActionItem);
    }
  };

  return (
    <PolicyAuditReportContext.Provider
      value={{
        loading: isLoading,
        input,
        setInput,
        data,
        action,
        actionItem,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
    </PolicyAuditReportContext.Provider>
  );
};

export default PolicyAuditReportContainer;
