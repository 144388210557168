import React, { ReactNode } from 'react';
import { Col, Flex } from 'antd';
import colors from '@totem/styles/colors';

const styles = {
  cell: {
    backgroundColor: '#F2F2F2',
    borderColor: '#FFFFFF',
    borderStyle: 'solid',
    borderWidth: 4,
  },
  title: {
    color: colors.branding.primaryLight,
    fontSize: '16px',
    fontWeight: 600,
    marginTop: '2rem',
    marginBottom: '1rem',
  },
  value: {
    fontSize: '14px',
    marginTop: '1rem',
    marginBottom: '2rem',
  },
};

type Props = {
  title: ReactNode;
  value: ReactNode;
  span: number;
}

const HeaderItem = ({ span, title, value }: Props) => {
  return (
    <Col span={span} style={styles.cell}>
      <div style={styles.title}>
        <Flex justify={'center'}>
          {title}
        </Flex>
      </div>
      <div style={styles.value}>
        <Flex justify={'center'}>
          {value}
        </Flex>
      </div>
    </Col>
  );
};

export default HeaderItem;
