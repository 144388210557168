import React, { useState } from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import {
  QuestionOption,
  QuestionValue,
  SurveyQuestion,
} from '@totem/components/surveyV2/types';

type Props = {
  question: SurveyQuestion;
  disabled: boolean;
  onChange: (value: QuestionValue) => void;
};

const EnumMultipleQuestion = ({ question, disabled, onChange }: Props) => {
  const [value, setValue] = useState<QuestionValue>(question.value);
  const { options } = question;

  const isSelected = (option: QuestionOption): boolean => {
    const found = value?.valueArray?.find(
      (chk) => chk.value === option.score.value,
    );
    if (typeof found !== 'undefined') {
      return true;
    }
    return false;
  };
  const handleChange = (checked: boolean, selectedOption: QuestionOption) => {
    const add = checked && !isSelected(selectedOption);
    const removed = !checked && isSelected(selectedOption);

    if (add || removed) {
      // Add to selected options
      const newStateValue: QuestionValue = {
        value: value.value,
        valueInt: value.valueInt,
        valueFloat: value.valueFloat,
        valueArray: [...value.valueArray],
      };

      if (add) {
        // Add to selection option to Array
        newStateValue.valueArray = [...value.valueArray, selectedOption.score];
      } else if (removed) {
        // Remove selected option from Array
        newStateValue.valueArray = newStateValue.valueArray.filter(
          (chk) => chk.value !== selectedOption.score.value,
        );
      }

      // Now that array has been updated,  let's recalculate the overall values

      // Update display value
      newStateValue.value = newStateValue.valueArray
        .map((qv) => qv.value)
        .join(', ');

      // Sum integer values
      newStateValue.valueInt = newStateValue.valueArray.reduce(
        (sum, current) => sum + current.valueInt,
        0,
      );

      // Sum float values
      newStateValue.valueFloat = newStateValue.valueArray.reduce(
        (sum, current) => sum + current.valueFloat,
        0,
      );

      setValue(newStateValue);
      onChange(newStateValue);
    }
  };

  return (
    <div>
      {options.map((option) => (
        <span key={option.label}>
          <Checkbox
            checked={isSelected(option)}
            onChange={(evt: CheckboxChangeEvent) =>
              handleChange(evt.target.checked, option)
            }
            disabled={disabled}
          >
            {option.label}
          </Checkbox>
          <br />
        </span>
      ))}
    </div>
  );
};

export default EnumMultipleQuestion;
