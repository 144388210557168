import React, { useCallback, useContext } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { extname } from 'path';

import PolicyAuditContext from '@totem/components/policyAuditDetail/PolicyAuditContext';
import { Question } from '@totem/types/policyAudit';
import { QuestionValue } from '@totem/types/survey';
import accountUtilities from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { FILES_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  onChange: (key: string, value: QuestionValue) => void;
  question: Question;
  disabled: boolean;
};

const UploadQuestion = ({ question, disabled }: Props) => {
  const { mediaData } = useContext(PolicyAuditContext);

  const media = isNotNull(mediaData)
    ? mediaData.filter((chk) => chk.subjectId === question.key)
    : [];

  const handleUpload = () => {};

  const mapFileList = useCallback(() => {
    return media.map((item) => ({
      uid: item.id,
      name: item.originalFileName,
      url: `${FILES_ENDPOINT}/${item.name}?originalFileName=${item.originalFileName}&token=${accountUtilities.getToken()}`,
      size: 0,
      type: extname(item.originalFileName),
    }));
  }, [media]);

  return (
    <Upload
      beforeUpload={handleUpload}
      fileList={mapFileList()}
      disabled
      showUploadList={{
        showRemoveIcon: false,
      }}
    >
      <Button icon={<UploadOutlined />} disabled={disabled}>
        Upload
      </Button>
    </Upload>
  );
};

export default UploadQuestion;
