import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spin, Tabs, TabsProps } from 'antd';

import BasePane from '@totem/components/BasePane';
import CardBody from '@totem/components/CardBody';
import CardHeader from '@totem/components/CardHeader';
import ContentLayout from '@totem/components/ContentLayout';
import EventEmbeddedFilterContainer from '@totem/components/events/eventFilters/EventEmbeddedFilterContainer';
import EventContainerTable from '@totem/components/events/eventsContainer/EventContainerTable';
import EventEmbeddedContainer from '@totem/components/events/eventsContainer/EventEmbeddedContainer';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import TabTitle from '@totem/components/TabTitle';
import TicketEvent from '@totem/components/ticketing/additional/event';
import Activities from '@totem/components/ticketing/details/activities';
import PartiesInvolved from '@totem/components/ticketing/details/partiesInvolved';
import QueueAssignments from '@totem/components/ticketing/details/queueAssignments';
import RelatedTicketsTable from '@totem/components/ticketing/details/relatedTicketsTable';
import TicketHeader from '@totem/components/ticketing/details/ticketHeader';
import { Breadcrumb } from '@totem/types/breadcrumb';
import { EventFilters } from '@totem/types/events';
import { TicketQueryResult } from '@totem/types/ticketing';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { TICKET_ENDPOINT } from '@totem/utilities/endpoints';
const TicketDetails = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ticketData, setTicketData] = useState<TicketQueryResult>(null);
  const [refresh, setRefresh] = useState<boolean>(true);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      fetch(`${TICKET_ENDPOINT}/${id}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: TicketQueryResult) => {
          setTicketData(result);
        })
        .then(() => setIsLoading(false));
    }
  }, [id, refresh]);

  const getBreadcrumbLabel = () => {
    if (ticketData !== null) {
      if (ticketData.ticket !== null) {
        return ticketData.ticket.number;
      }
    }
    return '';
  };

  const getBreadcrumbs = (breadcrumbsIn: Breadcrumb) => {
    const breadcrumbs = [breadcrumbsIn];

    breadcrumbs.push({
      label: 'Tickets',
      link: '/dashboard/ticketing',
    });

    breadcrumbs.push({
      label: `${getBreadcrumbLabel()}`,
    });

    return breadcrumbs;
  };

  const getEventCount = () => {
    let count = 0;
    if (
      ticketData !== null &&
      ticketData.ticket !== null &&
      ticketData.ticket.keys !== null
    ) {
      for (let idx = 0; idx < ticketData.ticket.keys.length; idx++) {
        if (
          ticketData.ticket.keys[idx].system === 'Event Management' &&
          ticketData.ticket.keys[idx].instance === 'IBD'
        ) {
          count = count + 1;
        }
      }
    }
    return count;
  };

  const getEventId = () => {
    if (
      ticketData !== null &&
      ticketData.ticket !== null &&
      ticketData.ticket.keys !== null
    ) {
      for (let idx = 0; idx < ticketData.ticket.keys.length; idx++) {
        if (
          ticketData.ticket.keys[idx].system === 'Event Management' &&
          ticketData.ticket.keys[idx].instance === 'IBD'
        ) {
          return ticketData.ticket.keys[idx].key;
        }
      }
    }
    return null;
  };

  const handleTicketUpdated = (updatedTicket: TicketQueryResult) => {
    setTicketData(updatedTicket);
    setRefresh(true);
  };

  const getEventFilter = () => {
    const filters: EventFilters = {
      reference: [`Ticketing:IBD:${ticketData.ticket.id}`],
    };
    return filters;
  };

  const getTabItems = () => {
    const tabItems: TabsProps['items'] = [
      {
        key: 'Activity',
        label: <TabTitle>Activity</TabTitle>,
        children: (
          <Activities
            ticket={ticketData}
            onTicketUpdated={handleTicketUpdated}
          />
        ),
      },
      {
        key: 'PartiesInvolved',
        label: <TabTitle>Parties Involved</TabTitle>,
        children: (
          <PartiesInvolved
            ticket={ticketData}
            onTicketUpdated={handleTicketUpdated}
          />
        ),
      },
      {
        key: 'QueueAssignments',
        label: <TabTitle>Queue Assignments</TabTitle>,
        children: (
          <QueueAssignments
            ticket={ticketData}
            onTicketUpdated={handleTicketUpdated}
          />
        ),
      },
    ];

    if (
      isNotNull(ticketData) &&
      isNotNull(ticketData.relatedTickets) &&
      ticketData.relatedTickets.length > 0
    ) {
      tabItems.push({
        key: 'RelatedTickets',
        label: <TabTitle>Related Tickets</TabTitle>,
        children: (
          <RelatedTicketsTable
            ticket={ticketData}
            refetch={() => setRefresh(true)}
          />
        ),
      });
    }

    if (getEventCount() === 1) {
      tabItems.push({
        key: 'Event',
        label: <TabTitle>Event</TabTitle>,
        children: <TicketEvent eventId={getEventId()} />,
      });
    }

    if (getEventCount() > 1) {
      tabItems.push({
        key: 'Events',
        label: <TabTitle>Events</TabTitle>,
        children: (
          <EventEmbeddedFilterContainer defaultFilters={getEventFilter()}>
            <EventEmbeddedContainer>
              <CardBody>
                <EventContainerTable />
              </CardBody>
            </EventEmbeddedContainer>
          </EventEmbeddedFilterContainer>
        ),
      });
    }

    return tabItems;
  };

  return (
    <ContentLayout
      breadcrumbs={(breadcrumbs) => getBreadcrumbs(breadcrumbs[0])}
    >
      <BasePane>
        <PrimaryContentPane>
          {ticketData !== null && (
            <CardHeader>
              <div>{ticketData.ticket.number}</div>
            </CardHeader>
          )}
          <CardBody>
            {ticketData !== null && (
              <>
                <TicketHeader ticket={ticketData} />
                <br />
                <Tabs defaultActiveKey="Activity" items={getTabItems()} />
              </>
            )}
            {ticketData === null && isLoading && <Spin />}
          </CardBody>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default TicketDetails;
